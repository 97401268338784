<div class="l-main-view__content-panel__container l-container-fluid">
    <span class="btn--unfocused btn--unfocused--small m-icon--doughnut " tabindex="-1">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 400" style="enable-background:new 0 0 400 400;" xml:space="preserve">
            <circle class="btn--circle__statcircle" cx="200" cy="200" r="177.7" />
            <path class="btn--circle__statcircle statcircle__animated" style="stroke:#FCD653" d="M200.6,22.3c97.9,0.3,177.1,79.7,177.1,177.7c0,98.1-79.5,177.7-177.7,177.7S22.3,298.1,22.3,200c0-97.8,79-177.2,176.7-177.7" />
        </svg>
        <svg class="m-ic m-ic--md">
            <use xlink:href="#ic-claim-details" href="#ic-claim-details"></use>
        </svg>
    </span>
    <div class="l-main-view__content-panel__container l-container-fluid">
        <div class="l-main-view__content-panel__container-max-width">
            <div class="row ">
                <div class="col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
                    <h1 class="heading h-text--center">Claim details</h1>
                    <ng-container *ngIf="case; else noCase">
                        <div class="m-tabs">
                            <nav class="m-tabs__nav">
                                <ul class="m-tabs__nav-menu">
                                    <li class="m-tabs__nav-item" *ngFor="let item of tabs" [ngClass]="{'m-tabs__nav-item--active': item.type == active || item.hover}" (mouseenter)="item.hover = true" (mouseleave)="item.hover = false">
                                        <a href="javascript:undefined" class="h-focus-element" (click)="toggle(item)" [attr.id]="item.id">
                                            <div class="h-focus__content" tabindex="-1">
                                                <svg class="m-ic m-ic--sm m-ic--horizontal-center"><use [attr.xlink:href]="item.icon" [attr.href]="item.icon"></use></svg>
                                                <span class="subheading subheading--uppercase">{{item.title}}</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <div class="m-tabs__page">
                                <personal-details *ngIf="active == TabType.Personal" [case]="case"></personal-details>
                                <accident-details *ngIf="active == TabType.Accident" [case]="case"></accident-details>
                                <third-party-details *ngIf="active == TabType.ThirdParty" [case]="case"></third-party-details>
                                <injury-details *ngIf="active == TabType.Injury" [case]="case"></injury-details>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noCase>
                        <br />
                        <p>An error occurred while retrieving your claim details. Please try again later.</p>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
