import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum MenuItemType {
    Home = 'Home',
    Welcome = 'Welcome',
    Document = 'Document',
    FAQ = 'FAQ',
    Claim = 'Claim',
    Contact = 'Contact',
    General = 'General',
    Notification = 'Notification'
}

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    toggleMenuSubject: Subject<boolean> = new Subject<boolean>();

    toggleMenuItemSubject: Subject<MenuItemType | undefined> = new Subject<MenuItemType | undefined>();

    toggleSlidingPanelSubject: Subject<boolean> = new Subject<boolean>();

}
