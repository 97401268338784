<div class="c-pagination" *ngIf="totalPages > 1">
    <a disableTabOnModal class="c-pagination-back" (click)="back()">
        <span class="u-hide-content">Back</span>
        <svg class="c-ic c-ic--arrow c-ic--arrow-left c-ic-white"> <use xlink:href="#ic-arrow-down" href="#ic-arrow-down"></use></svg>
    </a>
    <div class="c-pagination-pages">{{page}} of {{totalPages}}</div>
    <a disableTabOnModal class="c-pagination-next" (click)="next()">
        <span class="u-hide-content">Next</span>
        <svg class="c-ic c-ic--arrow c-ic--arrow-right c-ic-white"> <use xlink:href="#ic-arrow-down" href="#ic-arrow-down"></use></svg>
    </a>
</div>
