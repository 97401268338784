import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

export class AppUtilities {
    static getRouteData(route: ActivatedRoute | ActivatedRouteSnapshot, key: string): any {
        let data;
        if (route instanceof ActivatedRoute) {
            data = route.snapshot.data[key];
        }

        if (route instanceof ActivatedRouteSnapshot) {
            data = route.data[key];
        }

        if (data != undefined) { return data; }
        if (!route.parent) { return undefined; }
        return this.getRouteData(route.parent, key);
    }

    static getRouteParam(route: ActivatedRoute | ActivatedRouteSnapshot, key: string): string {
        let data;
        if (route instanceof ActivatedRoute) {
            data = route.snapshot.params[key];
        }

        if (route instanceof ActivatedRouteSnapshot) {
            data = route.params[key];
        }

        if (data != undefined) { return data; }
        if (!route.parent) { return undefined; }
        return this.getRouteParam(route.parent, key);
    }

    static objectValuesToString(object: any, separator: string = ','): string {
        if (!object) { return; }

        let str = '';

        for (const key in object) {
            const value = object[key];
            if (!value) { continue; }
            if (str) { str = str.concat(separator + ' ' + value); } else { str = value; }
        }

        return str;
    }

    static isTypeOf(type1: any, type2: any): boolean {
        const x = Object.keys(type1);
        const y = Object.keys(type2);
        return this.isArrayEqual(x, y);
    }

    static isTypeOfEnum(type1: any, type2: any): boolean {

        const arr1 = Object.keys(type2);
        for (let i = 0; i < arr1.length; i++) {
                if (arr1[i].toString() == type1) {
                    return true;
                }
        }
        return false;
    }

    static isArrayEqual(arr1: any[], arr2: any[]): boolean {
        // if the other array is a falsy value, return
        if (!arr2) { return false; }

        // compare lengths - can save a lot of time
        if (arr1.length != arr2.length) {
            return false;
        }

        for (let i = 0, l = arr1.length; i < l; i++) {
            // Check if we have nested arrays
            if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
                // recurse into the nested arrays
                if (!arr1[i].equals(arr2[i])) {
                    return false;
                }
            } else if (arr1[i] != arr2[i]) {
                // Warning - two different object instances will never be equal: {x:20} != {x:20}
                return false;
            }
        }
        return true;
    }

    static toUTF8Array(encodedString: string): Uint8Array {
        const byteString = window.atob(encodedString);
        const buffer = new ArrayBuffer(byteString.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < byteString.length; i++) {
            view[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        return view;
    }

    static saveToFile(content: Blob, filename: string) {
        filename = filename.replace(/ /g, '_');

        console.log(`Downloading file ${filename}`);

        const nav = (window.navigator as any);
        if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(content, filename);
            return;
        }

        // create hidden dom element (so it works in all browsers)
        // create file, attach to hidden element and open hidden element
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.href = window.URL.createObjectURL(content);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
    }
}
