<div class="u-mtop-sm">
    <h2 class="c-heading c-heading-uppercase">My Negotiations</h2>
    <p class="c-text-width">Here, you can find helpful resources to guide you through the negotiation stage of your claim.</p>
</div>
<div class="c-panel">
    <ul class="c-panel-list">
        <li minster-expandable-panel [heading]="'Preparation Guide'" id="preparation-details" icon="video-camera" [expanded]="true" aria-label="Accident details">
            <div class="video_wrapper">
                <div style="padding:56.25% 0 0 0;position:relative;">
                    <iframe src="https://player.vimeo.com/video/953471727?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Understanding negotiations"></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
        </li>
        <li minster-expandable-panel [heading]="'More Information'" id="medical-agency-details" icon="info-circle" [expanded]="true" aria-label="Personal details">
            <p>To learn more about negotiations and the next steps in your claim journey visit our <a disableTabOnModal routerLink="../../faqs" [queryParams]="{q: 'negotiations'}" class="c-text-link">FAQs centre</a>.</p>
            <p>The preparation guide is also available as a PDF download</p>
            <button type="button" class="u-mtop-ism c-button--link c-button is-primary" (click)="downloadNotificationsGuide()">Download Preparation Guide</button>
        </li>
    </ul>
</div>
