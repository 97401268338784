import { Directive, OnChanges, OnDestroy, AfterContentInit, ContentChildren, QueryList, Input, Optional, Renderer2, ElementRef, SimpleChanges } from '@angular/core';
import { RouterLink, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[routerLinkAriaActive]'
})
export class RouterLinkAriaActiveDirective implements OnChanges, OnDestroy, AfterContentInit {

    @ContentChildren(RouterLink, { descendants: true }) links!: QueryList<RouterLink>;

    // TODO(issue/24571): remove '!'.
    @ContentChildren(RouterLink, { descendants: true })
    linksWithHrefs!: QueryList<RouterLink>;

    private classes: string[] = [];
    private subscription: Subscription;
    public readonly isActive: boolean = false;

    constructor(
        private router: Router, private element: ElementRef, private renderer: Renderer2,
        @Optional() private link?: RouterLink,
        @Optional() private linkWithHref?: RouterLink) {
        this.subscription = router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.update();
            }
        });
    }


    ngAfterContentInit(): void {
        this.links.changes.subscribe(_ => this.update());
        this.linksWithHrefs.changes.subscribe(_ => this.update());
        this.update();
    }

    @Input()
    set routerLinkActive(data: string[] | string) {}

    ngOnChanges(): void {
        this.update();
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private update(): void {
        if (!this.links || !this.linksWithHrefs || !this.router.navigated) { return; }
        Promise.resolve().then(() => {
            const hasActiveLinks = this.hasActiveLinks();
            if (this.isActive !== hasActiveLinks) {
                (this as any).isActive = hasActiveLinks;
                if (hasActiveLinks) {
                    this.renderer.setAttribute(this.element.nativeElement, 'aria-selected', 'true');
                } else {
                    this.renderer.setAttribute(this.element.nativeElement, 'aria-selected', 'false');
                }
            }
        });
    }

    private isLinkActive(router: Router): (link: (RouterLink | RouterLink)) => boolean {
        return (link: RouterLink | RouterLink) =>
            router.isActive(link.urlTree, false);
    }

    private hasActiveLinks(): boolean {
        const isActiveCheckFn = this.isLinkActive(this.router);
        return this.link && isActiveCheckFn(this.link) ||
            this.linkWithHref && isActiveCheckFn(this.linkWithHref) ||
            this.links.some(isActiveCheckFn) || this.linksWithHrefs.some(isActiveCheckFn);
    }
}
