<h3 class="c-heading c-heading-purple">Customer details</h3>
<dl class="c-details-table">
    <div class="c-details-table__item">
        <dt class="c-details-table__title">Name</dt>
        <dd class="c-details-table__details data-hj-suppress" id="personal-customer-name">{{case.client.forename || case.client.surname ? case.client.forename + ' ' + case.client.surname : 'n/a'}}</dd>
    </div>

    <div class="c-details-table__item">
        <dt class="c-details-table__title">Date of birth</dt>
        <dd class="c-details-table__details data-hj-suppress" id="personal-customer-dob">{{case.client.dateOfBirth && case.client.dateOfBirth.toString() !== '0001-01-01T00:00:00Z' ? (case.client.dateOfBirth | date:'d MMMM yyyy') : 'n/a'}}</dd>
    </div>

    <div class="c-details-table__item">
        <dt class="c-details-table__title">Email</dt>
        <dd class="c-details-table__details data-hj-suppress" id="personal-customer-email">{{case.client.email ? case.client.email : 'n/a'}}</dd>
    </div>

    <div class="c-details-table__item">
        <dt class="c-details-table__title">Telephone</dt>
        <dd class="c-details-table__details data-hj-suppress" id="personal-customer-tel">{{case.client.mobileTel ? case.client.mobileTel : 'n/a'}}</dd>
    </div>

    <div class="c-details-table__item">
        <dt class="c-details-table__title">Address</dt>
        <dd class="c-details-table__details data-hj-suppress" id="personal-customer-address">{{case.client.address &&  toString(case.client.address) ? toString(case.client.address) : 'n/a'}}</dd>
    </div>
</dl>
<h3 class="c-heading c-heading-purple">Vehicle details</h3>
<dl class="c-details-table">
    <div class="c-details-table__item">
        <dt class="c-details-table__title">Registration</dt>
        <dd class="c-details-table__details data-hj-suppress" id="personal-vehicle-registration">{{case.client.vehicle ? case.client.vehicle.registration : 'n/a'}}</dd>
    </div>

    <div class="c-details-table__item">
        <dt class="c-details-table__title">Make</dt>
        <dd class="c-details-table__details data-hj-suppress" id="personal-vehicle-make">{{case.client.vehicle ? case.client.vehicle.make : 'n/a'}}</dd>
    </div>

    <div class="c-details-table__item">
        <dt class="c-details-table__title">Model</dt>
        <dd class="c-details-table__details data-hj-suppress" id="personal-vehicle-model">{{case.client.vehicle ? case.client.vehicle.model : 'n/a'}}</dd>
    </div>

    <div class="c-details-table__item">
        <dt class="c-details-table__title">Damage information</dt>
        <dd class="c-details-table__details data-hj-suppress" id="personal-vehicle-damage">{{case.client.vehicle ? case.client.vehicle.damage : 'n/a'}}</dd>
    </div>
</dl>
<ng-container *ngIf="hasLitigationFriend">
    <h3 class="c-heading c-heading-purple">Customer's representatives</h3>
    <dl class="c-details-table">
        <div class="c-details-table__item">
            <dt class="c-details-table__title">Name</dt>
            <dd class="c-details-table__details data-hj-suppress" id="personal-lit-friend-name">{{case.litigationFriend.forename || case.litigationFriend.surname ? case.litigationFriend.forename + ' ' + case.litigationFriend.surname : 'n/a'}}</dd>
        </div>
        <div class="c-details-table__item">
            <dt class="c-details-table__title">Address</dt>
            <dd class="c-details-table__details data-hj-suppress" id="personal-lit-friend-address">{{toString(case.litigationFriend.address) ? toString(case.litigationFriend.address) : 'n/a'}}</dd>
        </div>
        <div class="c-details-table__item">
            <dt class="c-details-table__title">Email</dt>
            <dd class="c-details-table__details data-hj-suppress" id="personal-lit-friend-email">{{case.litigationFriend.emailAddress ? case.litigationFriend.emailAddress : 'n/a'}}</dd>
        </div>
        <div class="c-details-table__item">
            <dt class="c-details-table__title">Telephone</dt>
            <dd class="c-details-table__details data-hj-suppress" id="personal-lit-friend-telephone">{{case.litigationFriend.mobilePhone ? case.litigationFriend.mobilePhone : 'n/a'}}</dd>
        </div>
        <div class="c-details-table__item">
            <dt class="c-details-table__title">Date of birth</dt>
            <dd class="c-details-table__details data-hj-suppress" id="personal-lit-friend-dob">{{case.litigationFriend.dateOfBirth && case.litigationFriend.dateOfBirth.toString() !== '0001-01-01T00:00:00Z' ? (case.litigationFriend.dateOfBirth | date:'d MMMM yyyy') : 'n/a'}}</dd>
        </div>
        <div class="c-details-table__item">
            <dt class="c-details-table__title">Relationship</dt>
            <dd class="c-details-table__details data-hj-suppress" id="personal-lit-friend-relationship">{{case.litigationFriend.relationshipToClient ? case.litigationFriend.relationshipToClient : 'n/a'}}</dd>
        </div>
    </dl>
</ng-container>
