import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
    selector: '[unauthenticatedImageContainer]',
    templateUrl: './unauthenticated-image-container.component.html',
    styleUrls: ['./unauthenticated-image-container.component.scss']
})
export class UnauthenticatedImageContainerComponent implements OnInit {

    @HostBinding('class.c-image-container') isContainer: boolean = true;

    constructor() { }

    ngOnInit(): void {
    }

}
