export class MilestoneModel {
    id: number;
    caseReference: string;
    createdDate: Date;
    modifiedDate: Date;
    name: string;
    shortDescription: string;
    description: string;
    externalLinks: string[] = [];
    internalLinks: LinkModel[] = [];
    completed: boolean;
    type: number;
    links: LinkModel[] = [];
}

export class LinkModel {   
    linkText: string;
    url: string;
    type: number;
    internalLinkType: number;
}

export enum MilestonesType {
    ClaimRegistered = 'Claim registered',
    LiabilityDecision = 'Liability decision',
    MedicalAppointment = 'Medical appointment',
    MedicalReport = 'Medical report',
    InjuryValuation = 'Injury valuation',
    YourTreatment = 'Your treatment',
    PreparingSubmission = 'Preparing submission',
    ClaimSubmitted = 'Claim submitted',
    SettlementNegotiations = 'Settlement negotiations',
    CourtProceedings = 'Court proceedings',
    SettlementAgreed = 'Settlement agreed',
    PaymentIssued = 'Payment issued'
}

export enum InternalLinkType {
    None = 'None',
    ViewMedicalAppointment = 'View medical appointment',
    ViewMedicalReport = 'View medical report',
    ReadWelcomePack = 'Read welcome pack',
    VisitFaq = 'Visit faq',
    AddALoss = 'Add a loss',
    ViewLosses = 'View losses'
}

export enum LinkType {
    Internal = 'Internal',
    External = 'External'
}
