import { Injectable } from '@angular/core';
import { DocumentService, DocumentName } from './document.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class WelcomePackAuditService {
    constructor(private documents: DocumentService, private storage: StorageService) { }

    recordDocumentViewed(caseId: string, type: DocumentName) {
        const docs = this.getViewedDocuments(caseId);
        docs.push(type);
        this.saveViewedDocuments(caseId, docs);
    }

    hasViewedDocument(caseId: string, type: DocumentName): boolean {
        const docs = this.getViewedDocuments(caseId);
        return docs.indexOf(type) > -1;
    }

    hasViewedAnyDocument(caseId: string): boolean {
        return this.getViewedDocuments(caseId).length > 0;
    }

    private getViewedDocuments(caseId: string): string[] {
        return JSON.parse(this.storage.getItem(this.getDocumentsStorageKey(caseId)) || '[]');
    }

    private saveViewedDocuments(caseId: string, documents: string[]) {
        const docsToSave = [...new Set(documents)];
        this.storage.setItem(this.getDocumentsStorageKey(caseId), JSON.stringify(docsToSave));
    }

    private getDocumentsStorageKey(caseId: string) {
        return `WELCOME-PACK-DOCUMENTS-${caseId}`;
    }
}
