<main class="c-login-page">
    <div class="c-login">
        <div unauthenticatedHeader class="c-main-nav-wrapper c-main-nav-wrapper--login"></div>
        <ng-container *ngIf="reRegisterMessage">
            <h1 class="c-heading u-mtop-mobile-md">Link expired</h1>
            <p class="c-text-width">Sorry your link to register has expired.  Please click below and we will send you a new link to the email address you provided.</p>
            <minster-button buttonType="submit" [disabled]="submitted" (click)="reRegister()" text="Resend Invite" isPrimary="true"></minster-button>
        </ng-container>
        <ng-container *ngIf="postReRegisterMessage">
            <h1 class="c-heading u-mtop-mobile-md">Check your inbox</h1>
            <p class="c-text-width">We have sent an email to {{userEmail}}. Please go to your inbox and follow the instructions to Register for the Customer Portal.</p>
            <p class="c-text-width">You should receive your email within 10 minutes. If you don't:</p>
            <ul class="c-text-width">
                <li>Check your junk email folder.</li>
                <li>Please contact our technical support line on <br class="mobile-break"><a class="mobileLinkColour" href="tel:01904 663238">01904 663238</a> for further assistance.</li>
            </ul>
        </ng-container>
    </div>
    <div unauthenticatedImageContainer></div>
</main>
<footer class="c-footer c-footer--login">
    <ul class="c-footer__content">
        <li class="c-footer__items"><a class="c-text-link c-text-link--white" href="https://www.minsterlaw.co.uk/privacy-notice/" target="_blank">Privacy Notice</a></li>
        <li class="c-footer__items"><a class="c-text-link c-text-link--white" href="https://www.minsterlaw.co.uk/cookie-policy/" target="_blank">Cookie Policy</a></li>
    </ul>
</footer>
