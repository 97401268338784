import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MedicalAppointmentModel, Appointment } from '../../models/medical-appointment.model';
import { ActivatedRoute } from '@angular/router';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { AppInsightsService } from '../../services/appinsights.service';
import { DocumentModel } from '../../models/document.model';
import * as moment from 'moment';
import { MedicalReportService, MedicalReportRequest } from '../../services/medical-report.service';
import { DocumentService } from '../../services/document.service';
import { AppUtilities } from '../../app.utility';
import { CaseModel } from '../../models/case.model';
import { MinsterExpandablePanelComponent } from '../minster-expandable-panel/minster-expandable-panel.component';
import { KeyedCollection } from '../../data/keyedcollection';
import { CalendarFormat } from '../../data/calendar-format';
import { ModalA11yService } from '../../services/modal-a11y.service';
import medicalApproval from '../../data/medical-approval-checks.json';
import mime from 'mime-types';
import { FileDownloadService, PdfFilesType } from 'src/app/services/file-download.service';

@Component({
    selector: 'app-medical',
    templateUrl: './medical.component.html',
    styleUrls: ['./medical.component.scss']
})
export class MedicalComponent implements OnInit, OnDestroy {

    case: CaseModel;
    medical: MedicalAppointmentModel;
    appointments: Appointment[] = [];
    selectedAppointment: Appointment;
    mapStatus = 'closed';
    markers: MapMarker[] = [];
    showPreparation = false;
    rescheduleState = 'closed';
    documents: DocumentModel[];
    subscriptions = [];
    downloadingDocs = new KeyedCollection<boolean>();
    preparationChecks = medicalApproval.preparationChecks;
    isCovid = false;

    @ViewChild('mapcontainer') mapContainer: ElementRef;
    @ViewChild('map') map: GoogleMap;
    @ViewChild('reportsexpandablepanel') reportsExpandablePanel: MinsterExpandablePanelComponent;
    @ViewChild('appointmentScroller') appointmentScroller: ElementRef;

    constructor(
        private route: ActivatedRoute,
        private medicalReportService: MedicalReportService,
        private appInsights: AppInsightsService,
        private documentService: DocumentService,
        private fileDownloadService: FileDownloadService,
        private modalA11y: ModalA11yService) { }

    get medicalsToApprove(): DocumentModel[] {
        const medicalReports = (this.documents || []).filter(d => d.group.toLowerCase() === 'medical');
        const medicalsToApprove = medicalReports
            .filter(d => d.status.toLowerCase() === 'new')
            .sort((a, b) => a.createdDate < b.createdDate ? 1 : -1);
        return medicalsToApprove;
    }

    get medicals(): DocumentModel[] {
        return (this.documents || [])
            .filter(d => d.group.toLowerCase() === 'medical' && d.state.toLowerCase() === 'received')
            .sort((a, b) => {
                if (a.status.toLowerCase() === 'new') {
                    if (b.status.toLowerCase() === 'new') {
                        return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
                    } else {
                        return -1;
                    }
                } else if (b.status.toLowerCase() === 'new') {
                    return 1;
                }

                return new Date(a.statusChangedDate) < new Date(b.statusChangedDate) ? 1 : -1;
            });
    }

    getFriendlyUpdateDate(document: DocumentModel) {
        return moment(document.statusChangedDate).calendar(null, CalendarFormat);
    }

    getFriendlyCreatedDate(document: DocumentModel) {
        return moment(document.createdDate).calendar(null, CalendarFormat);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    ngOnInit(): void {
        this.case = this.route.parent.parent.snapshot.data.case;
        this.medical = this.route.parent.parent.snapshot.data.medicalAppointment;
        this.documents = this.route.parent.parent.snapshot.data.documents;

        if (this.medical) { this.appointments = this.medical.appointment.sort((a, b) => new Date(a.appointmentDateTime) > new Date(b.appointmentDateTime) ? -1 : 1); }


        this.subscriptions.push(this.modalA11y.closed.subscribe(() => {
            if (this.mapContainer) {
                const elements = (this.mapContainer.nativeElement as HTMLElement).querySelectorAll('a, button');
                for (let i = 0; i < elements.length; i++) {
                    elements[i].removeAttribute('tabindex');
                }
            }
        }));

        this.subscriptions.push(this.modalA11y.opened.subscribe(() => {
            if (this.mapContainer) {
                const elements = (this.mapContainer.nativeElement as HTMLElement).querySelectorAll('a, button');
                for (let i = 0; i < elements.length; i++) {
                    elements[i].setAttribute('tabindex', '-1');
                }
            }
        }));

        this.subscriptions.push(this.medicalReportService.approvalCommitted.subscribe((request: MedicalReportRequest) => {
            const matchingDocuments = this.documents.filter(d => d.id === request.document.id);
            matchingDocuments.forEach(d => {
                this.documents.splice(this.documents.indexOf(d), 1);
            });

            this.documents.push(request.document);
        }));

        this.subscriptions.push(this.medicalReportService.rejectionCommitted.subscribe((request: MedicalReportRequest) => {
            const matchingDocuments = this.documents.filter(d => d.id === request.document.id);
            matchingDocuments.forEach(d => {
                this.documents.splice(this.documents.indexOf(d), 1);
            });

            this.documents.push(request.document);
        }));
    }

    isDownloadingDoc(doc: DocumentModel) {
        return this.downloadingDocs.ContainsKey(doc.id);
    }

    download(medical: DocumentModel) {

        this.appInsights.logComplexEvent('downloadMedical', { loss: medical.id });

        this.downloadingDocs.Add(medical.id, true);

        this.documentService.content(medical.contentUrl).subscribe(
            result => {
                const bytes = AppUtilities.toUTF8Array(result.data);
                const content = new Blob([bytes], { type: mime.lookup(medical.fileExtension) || 'application/pdf' });
                const filename = `${medical.name}.${medical.fileExtension}`;
                console.log(`Downloading file ${filename}`);
                AppUtilities.saveToFile(content, filename);
                this.downloadingDocs.Remove(medical.id);
            }, () => {
                this.downloadingDocs.Remove(medical.id);
            });
    }

    accept(medical: DocumentModel) {
        this.medicalReportService.startApproval(medical, this.case);
    }

    reject(medical: DocumentModel) {
        this.medicalReportService.startRejection(medical, this.case);
    }

    downloadMedicalPreparationGuide(): void {
        this.fileDownloadService.download(PdfFilesType.MedicalPreparationGuide).subscribe(data =>
             {
                const content = new Blob([data], { type: 'application/pdf' });
                const filename = 'MinsterMedicalPreparationGuide.pdf';
                AppUtilities.saveToFile(content, filename);
             }
        )
    }

    /*onChangeMedicalAgency(): void {
        if(this._agencyId == 10){
            this._agencyId = 1;
        } else {
            this._agencyId += 1;
        }
    }*/
}
