import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AppInsightsService } from '../../services/appinsights.service';
import { ModalA11yService } from '../../services/modal-a11y.service';

@Component({
    selector: 'minster-alert',
    templateUrl: './minster-alert.component.html',
    styleUrls: ['./minster-alert.component.scss']
})
export class MinsterAlertComponent implements OnInit {

    @Input() title: string;
    @Input() body: string;
    @Input() okButtonTitle = 'Ok';
    @Input() cancelButtonTitle: string;

    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

    isOpen = true;

    constructor(
        private appInsights: AppInsightsService,
        private modalA11y: ModalA11yService
    ) { }

    ngOnInit(): void {
    }

    buttonClicked(positive: boolean): void {
        this.close.emit(positive);
        this.isOpen = false;
        this.appInsights.logCustomEvent('alertDismissed', positive ? 'TRUE' : 'FALSE', null);
        this.modalA11y.close(this);
    }

    open(): void {
        this.isOpen = true;

        this.appInsights.logCustomEvent('alertShown', this.title, null);
        this.modalA11y.open(this);
    }

    hide(): void {
        this.isOpen = false;
    }
}
