import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MeService } from 'src/app/services/me.service';


@Component({
  selector: 'app-register-expiry-page',
  templateUrl: './register-expiry-page.component.html',
  styleUrls: ['./register-expiry-page.component.scss']
})
export class RegisterExpiryPageComponent implements OnInit {

    reRegisterMessage: boolean = true;
    postReRegisterMessage: boolean = false;
    userEmail: string;

    guid: string;
    reRegisteredSetUp: boolean = false;
    submitted: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private meService: MeService
    ) { }

    ngOnInit() {
        this.guid = this.route.snapshot.params.guid;
        this.reRegisterMessage = true;
        this.postReRegisterMessage = false;
    }

    reRegister() {
        this.submitted = true;
        this.meService.registerExpiryResubmit(this.guid).subscribe(
            result => {
                this.reRegisteredSetUp = result.ReRegisteredSetUp;
                if (this.reRegisteredSetUp) {
                    this.userEmail = result.Email;
                    this.reRegisterMessage = false;
                    this.postReRegisterMessage = true;
                } else {
                    this.router.navigate(['/bad-link']);
                }
            },
            error => { this.router.navigate(['/bad-link']); }
        );
    }

}
