import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OffLineService {

  constructor(private http: HttpClient,  @Inject('API_BASE_URL') private baseUrl: string) {   }

  getSiteStatus(): Observable<any> {
        const url = this.baseUrl + 'alerts/GetSiteStatus/';
        return this.http.get<any>(url);
    }
}
