
<div *ngIf="password">
    <p class="u-spacing--medium"><strong>Password must meet the following requirements:</strong></p>
    <ul>
        <li *ngIf="containAtLeastEightChars"><strong><span class="has-text-success"><i class="fas fa-check-circle"></i></span> Between 8 and 20 characters in length</strong>  </li>
        <li *ngIf="!containAtLeastEightChars"><strong><span class="has-text-danger"><i class="fas fa-times-circle"></i></span> Between 8 and 20 characters in length</strong>  </li>

        <li *ngIf="containAtLeastOneLowerCaseChar"><strong><span class="has-text-success"><i class="fas fa-check-circle"></i></span> At least one lower case character</strong>  </li>
        <li *ngIf="!containAtLeastOneLowerCaseChar"><strong><span class="has-text-danger"><i class="fas fa-times-circle"></i></span> At least one lower case character</strong>  </li>

        <li *ngIf="containAtLeastOneUpperCaseChar"><strong><span class="has-text-success"><i class="fas fa-check-circle"></i></span> At least one upper case character</strong>  </li>
        <li *ngIf="!containAtLeastOneUpperCaseChar"><strong><span class="has-text-danger"><i class="fas fa-times-circle"></i></span> At least one upper case character</strong>  </li>

        <li *ngIf="containAtLeastOneNumberChar"><strong><span class="has-text-success"><i class="fas fa-check-circle"></i></span> At least one number</strong>  </li>
        <li *ngIf="!containAtLeastOneNumberChar"><strong><span class="has-text-danger"><i class="fas fa-times-circle"></i></span> At least one number</strong>  </li>

        <li *ngIf="containAtLeastOneSpecialChar"><strong><span class="has-text-success"><i class="fas fa-check-circle"></i></span> At least one special character: !"#$%&'()*+,-./:;<>?@[\]^_`</strong>  </li>
        <li *ngIf="!containAtLeastOneSpecialChar"><strong><span class="has-text-danger"><i class="fas fa-times-circle"></i></span> At least one special character: !"#$%&'()*+,-./:;<>?@[\]^_`</strong>  </li>
    </ul>

</div>
