import { Component, OnInit } from '@angular/core';
import { ModalA11yService } from '../../services/modal-a11y.service';
import { ConfigurationService } from '../../services/configuration.service';
import { InterruptLoginService, LoginInterruptDetail, LoginInterruptType } from '../../services/interrupt-login.service';

@Component({
  selector: 'interrupt-login',
  templateUrl: './interrupt-login.component.html',
  styleUrls: ['./interrupt-login.component.scss']
})
export class InterruptLoginComponent implements OnInit {

    interruptToShow: LoginInterruptDetail;
    portalName: string;

    constructor(
        private interruptLoginService: InterruptLoginService,
        private modalA11y: ModalA11yService,
        private config: ConfigurationService
    ) { }

    ngOnInit(): void {
        this.interruptToShow = this.interruptLoginService.getLoginNextInterrupt();

        if (this.interruptToShow.type !== LoginInterruptType.None) {
            this.interruptLoginService.recordInterruptViewed(this.interruptToShow);
            this.modalA11y.open(this);
        }

        this.portalName = this.config.portalName;
    }

    get showInterrupt(): boolean {
        return this.interruptToShow.type !== LoginInterruptType.None;
    }

    get showNewPortal(): boolean {
        return this.interruptToShow.type === LoginInterruptType.NewPortal;
    }

    close() {
        this.interruptToShow.type = LoginInterruptType.None;
        this.modalA11y.close(this);
    }
}
