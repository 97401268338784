import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: '[unauthenticatedHeader]',
  templateUrl: './unauthenticated-header.component.html',
  styleUrls: ['./unauthenticated-header.component.scss']
})
export class UnauthenticatedHeaderComponent implements OnInit {

    @HostBinding('class.c-main-nav-wrapper') isNavWrapper: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
