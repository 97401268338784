import {Injectable, Output, EventEmitter, Inject} from '@angular/core';
import { CaseModel } from '../models/case.model';
import { DocumentModel, DocumentUploadRequestModel } from '../models/document.model';
import { DocumentService } from './document.service';
import { AppInsightsService } from './appinsights.service';
import { from, Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot} from '@angular/router';
import {EvidenceUploadModel, LossUploadModel} from '../models/loss.model';

export interface AddLossRequest {
    case: CaseModel;
}

@Injectable({
    providedIn: 'root'
})
export class LossService {

    @Output() addLossStarted: EventEmitter<AddLossRequest> = new EventEmitter<AddLossRequest>();
    @Output() documentsUploaded: EventEmitter<string> = new EventEmitter<string>();
    @Output() addLossCancelled: EventEmitter<AddLossRequest> = new EventEmitter<AddLossRequest>();
    @Output() addLossCommitted: EventEmitter<AddLossRequest> = new EventEmitter<AddLossRequest>();

    constructor(private documentService: DocumentService, private appInsights: AppInsightsService, private http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) { }

    startAddLoss(_case: CaseModel) {
        this.appInsights.logComplexEvent('Add loss started', { case: _case.reference });
        this.addLossStarted.emit({ case: _case});
    }

    resolve(route: ActivatedRouteSnapshot): LossUploadModel[] | Observable<LossUploadModel[]> | Promise<LossUploadModel[]> {
        return this.getLosses(route.params.reference);
    }

    createLoss(lossUploadModel: LossUploadModel) {

        const promise = new Promise<boolean>((resolve, reject) => {
            const url = this.baseUrl + 'loss';

            this.http.post(url, lossUploadModel
            ).subscribe(() => {
                resolve(true);
                this.addLossCommitted.emit();
            }, error => {
                reject(error);
            });
        });

        return from(promise);
    }

    getLoss(id: string, caseReference: string) {
        const  url = this.baseUrl + 'loss/' + caseReference + '/' + id;

        return this.http.get<LossUploadModel>(url, {});

    }

    getLosses(caseReference: string): Observable<any> {
        const  url = this.baseUrl + 'loss/' + caseReference;

        return this.http.get<LossUploadModel[]>(url, {});
    }

    getEvidence(caseReference: string, id: string, fileName: string) {
        const  url = this.baseUrl + 'loss/' + caseReference + '/' + id + '/' + fileName;

        return this.http.get<EvidenceUploadModel>(url, {});
    }


    cancel(_case: CaseModel): void {
        this.addLossCancelled.emit({ case: _case });
    }
}
