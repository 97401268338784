<ng-container *ngIf="!isMobile">
    <img class="c-main-nav__logo recite-ignore-styles-background" alt="Minster law logo" src="/images/logo2.svg">
    <img class="recite-link" alt="accessibility tools" (click)="loadRecite()" src="/images/Standard _ Small _ Squared _ Red _ Option 3.svg">
    <ul class="c-main-nav c-main-nav__main-layout">
        <li class="c-main-nav__item"><a disableTabOnModal id="menu-home-button" routerLinkActive="c-main-nav__link--active" routerLink="./case" class="c-main-nav__link">Home</a></li>
        <li class="c-main-nav__item" *ngIf="!case.readOnly"><a disableTabOnModal id="menu-add-loss-button" class="c-main-nav__link" [routerLink]="" (click)="openAddLossModal()">Add a loss</a></li>
        <li class="c-main-nav__item"><a disableTabOnModal id="menu-documents-button" routerLinkActive="c-main-nav__link--active" routerLink="./documents" class="c-main-nav__link">Documents</a></li>
        <li class="c-main-nav__item"><a disableTabOnModal id="menu-notifications-button" routerLink="./notifications" routerLinkActive="c-main-nav__link--active" class="c-main-nav__link c-main-nav__link--notifications">Notification<span class="c-main-nav__link-notification-bubble" *ngIf="notifications.length > 0">{{notifications.length}}</span></a></li>
        <li class="c-main-nav__item"><a disableTabOnModal id="menu-faqs-button" routerLink="./faqs" routerLinkActive="c-main-nav__link--active" class="c-main-nav__link">FAQs</a></li>
    </ul>
    <ul class="c-main-nav__layout" *ngIf="!isMobile">
        <li class="c-main-nav__item"> <a disableTabOnModal id="menu-contact-button" routerLink="./contact" routerLinkActive="c-main-nav__link--active" class="c-main-nav__link">Contact</a></li>
        <li class="c-main-nav__item"> <a disableTabOnModal id="menu-logout-button" routerLink="/auth/logout" routerLinkActive="c-main-nav__link--active" class="c-main-nav__link">Logout</a></li>
    </ul>
</ng-container>


<div class="c-footer-nav-wrapper" *ngIf="isMobileFooter" [ngClass]="{'c-is-open': isOpen}">
    <ul class="c-footer-nav-mobile">
        <li class="c-footer-nav-mobile__item c-footer-nav-mobile__item--home">
            <a routerLink="./case" class="c-footer-nav-mobile__link" aria-label="Home">
                <svg class="c-ic c-ic--footer">
                    <use xlink:href="#ic-home" href="#ic-home"></use>
                </svg>
                <span class="c-footer-nav-mobile__link-text">Home</span>
            </a>
        </li>
        <li class="c-footer-nav-mobile__item c-footer-nav-mobile__item--notifications">
            <a routerLink="./notifications" class="c-footer-nav-mobile__link" aria-label="Notifications">
                <svg class="c-ic c-ic--footer">
                    <use xlink:href="#ic-notifications" href="#ic-notifications"></use>
                </svg>
                <span class="c-footer-nav-mobile__link-notification-bubble" *ngIf="notifications.length > 0">{{notifications.length}}</span>
                <span class="c-footer-nav-mobile__link-text">Notifications</span>
            </a>
        </li>
        <li class="c-footer-nav-mobile__item c-footer-nav-mobile__item--loss"  *ngIf="!case.readOnly">
            <a [routerLink]="" (click)="openAddLossModal()" class="c-footer-nav-mobile__link" aria-label="Add a Loss">
                <svg class="c-ic c-ic--footer">
                    <use xlink:href="#ic-add-loss" href="#ic-add-loss"></use>
                </svg>
                <span class="c-footer-nav-mobile__link-text">Add a loss</span>
            </a>
        </li>
        <li class="c-footer-nav-mobile__item c-footer-nav-mobile__item--faq">
            <a routerLink="./faqs" class="c-footer-nav-mobile__link" aria-label="FAQs">
                <svg class="c-ic c-ic--footer">
                    <use xlink:href="#ic-help" href="#ic-help"></use>
                </svg>
                <span class="c-footer-nav-mobile__link-text">FAQs</span>
            </a>
        </li>
        <li class="c-footer-nav-mobile__item c-footer-nav-mobile__item--documents">
            <a routerLink="./documents" class="c-footer-nav-mobile__link" aria-label="Documents">
                <svg class="c-ic c-ic--footer">
                    <use xlink:href="#ic-documents" href="#ic-documents"></use>
                </svg>
                <span class="c-footer-nav-mobile__link-text">Documents</span>
            </a>
        </li>
        <li class="c-footer-nav-mobile__item c-footer-nav-mobile__item--contact">
            <a routerLink="./contact" class="c-footer-nav-mobile__link" aria-label="FAQs">
                <svg class="c-ic c-ic--footer">
                    <use xlink:href="#ic-mail" href="#ic-mail"></use>
                </svg>
                <span class="c-footer-nav-mobile__link-text">Contact</span>
            </a>
        </li>
        <li class="c-footer-nav-mobile__item c-footer-nav-mobile__item--logout">
            <a routerLink="/auth/logout" class="c-footer-nav-mobile__link" aria-label="Log out">
                <svg class="c-ic c-ic--footer">
                    <use xlink:href="#ic-logout" href="#ic-logout"></use>
                </svg>
                <span class="c-footer-nav-mobile__link-text">Log out</span>
            </a>
        </li>
        <li class="c-footer-nav-mobile__item c-footer-nav-mobile__item--more">
            <a (click)="isOpen = !isOpen" class="c-footer-nav-mobile__link" aria-label="More">
                <svg class="c-ic c-ic--footer">
                    <use xlink:href="#ic-more" href="#ic-more"></use>
                </svg>
                <span class="c-footer-nav-mobile__link-text">More</span>
            </a>
        </li>
        <li class="c-footer-nav-mobile__item c-footer-nav-mobile__item--close">
            <a (click)="isOpen = !isOpen" class="c-footer-nav-mobile__link" aria-label="Close menu">
                <svg class="c-ic c-ic--sm">
                    <use xlink:href="#ic-close" href="#ic-close"></use>
                </svg>
                <span class="c-footer-nav-mobile__link-text">Close</span>
            </a>
        </li>
    </ul>
</div>
