import {Component, Input, OnInit} from '@angular/core';
import { CaseModel } from 'src/app/models/case.model';

@Component({
  selector: 'injury-details',
  templateUrl: './injury-details.component.html',
  styleUrls: ['./injury-details.component.scss']
})
export class InjuryDetailsComponent implements OnInit {

    @Input() case: CaseModel;

    ngOnInit() {
        window.scrollTo(0, 0);
    }
}
