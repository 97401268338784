
<ng-container *ngIf="!completed; else isCompleted">
    <h1 class="c-heading u-mtop-mobile-md">Reset your password</h1>
    <p class="c-text-width">Now please enter your access code (you should have received this via SMS) and choose a password.</p>
    <form [formGroup]="form" (submit)="submit(form)" (input)="clear()" autocomplete="off" novalidate>
        <div class="c-text-input u-mtop-ism">
            <label for="accesscode" class="u-text-bold c-text-white">Access code</label>
            <input id="accesscode" placeholder="Access code" class="c-text-input-style" type="tel" [formControl]="form.controls['accesscode']" [textMask]="{mask: accesscodeTextMask}" autofocus [ngClass]="{'is-danger' : submitted && form.controls.accesscode.errors}">
            <ng-container *ngIf="submitted">
                <minster-validation-message *ngIf="form.controls.accesscode.hasError('required')" message="Please enter your access code"></minster-validation-message>
                <minster-validation-message *ngIf="form.controls.accesscode.hasError('minlength') || form.controls.accesscode.hasError('maxlength')" message="Please enter a valid access code"></minster-validation-message>
            </ng-container>
        </div>

        <div class="c-text-input u-mtop-ism">
            <div class="c-text-input__top-text">
                <label for="password" class="u-text-bold c-text-white">Password</label>
                <show-hide-password-button [target]="password"></show-hide-password-button>
            </div>
            <input #password id="password" class="c-text-input-style" placeholder="Password" type="password" [formControl]="form.controls['password']" autocomplete="off" [ngClass]="{'is-danger' : submitted && form.controls.password.hasError('required')}">
            <ng-container *ngIf="submitted">
                <minster-validation-message *ngIf="form.controls.password.hasError('required')" message="Please enter your password"></minster-validation-message>
                <minster-validation-message *ngIf="!form.controls.password.hasError('required') && form.controls.password.hasError('password')" message="Please enter a strong password. Your password should be 8 to 20 characters long. It should contain an uppercase and lowercase letter, a number and a special character."></minster-validation-message>
            </ng-container>
        </div>

        <password-strength-validator [password]="form.value.password"></password-strength-validator>

        <div class="c-text-input u-mtop-ism">
            <div class="c-text-input__top-text">
                <label class="u-text-bold c-text-white" for="passwordagain">Password again</label>
                <show-hide-password-button [target]="passwordAgain"></show-hide-password-button>
            </div>
            <input #passwordAgain id="passwordagain" class="c-text-input-style" placeholder="Password again" type="password" [formControl]="form.controls['passwordagain']" autocomplete="off" [ngClass]="{'is-danger' : submitted && form.controls.passwordagain.hasError('required')}">
            <ng-container *ngIf="submitted">
                <minster-validation-message *ngIf="form.hasError('passwordmatch')" message="The passwords you have entered do not match. Please try again."></minster-validation-message>
            </ng-container>

        </div>

        <minster-validation level="info" [messages]="['Please contact Minster Law if you are having difficulties, or request a new access code']" *ngIf="attempts <= 0"></minster-validation>
        <minster-validation [messages]="errors"></minster-validation>
        <minster-button id="submit-registration" buttonType="submit" [disabled]="submitting" text="Next" [isLoading]="submitting ? true : false" isPrimary="true"></minster-button>
        <div class="u-mtop-ism">
            <minster-link id="request-new-access-code" [disabled]="submitting || requesting" [text]="requesting ? 'Requesting a new access code...' : 'Request a new access code'" (click)="requestAccessCode()"></minster-link>
        </div>
    </form>
</ng-container>
<ng-template #isCompleted>
    <h1 class="c-heading u-mtop-mobile-md">Password changed</h1>
    <p class="c-text-width">Your password has been reset. Please use the link below to log in to your case.</p>
    <div class="u-mtop-ism">
        <minster-link [mlRouterLink]="['/auth/logout']" [disabled]="submitting" text="Back to login"></minster-link>
    </div>
</ng-template>


<div class="c-modal" role="dialog" style="display: block;" *ngIf="pinSentDialogShown">
    <div class="c-modal__content">
        <div class="c-modal-nav">
            <div class="c-modal-nav__item--back u-is-hidden">
                <button class="c-ic-button c-modal-nav__btn-back" role="button" aria-label="back">
                    <svg class="c-ic c-ic--back-modal">
                        <use xlink:href="#ic-back-modal" href="#ic-back-modal"></use>
                    </svg>
                </button>
            </div>
            <div class="c-modal-nav__item--logo">
                <a class="c-modal-nav__link">
                    <svg class="c-ic-purple c-modal-nav__logo-svg">
                        <use xlink:href="#ic-logo-2" href="#ic-logo-2"></use>
                    </svg>
                </a>
            </div>
            <div class="c-modal-nav__item--close" (click)="pinSentDialogShown = false">
                <button class="c-ic-button c-modal-nav__btn-close" role="button" aria-label="close">
                    <svg class="c-ic c-ic--sm">
                        <use xlink:href="#ic-close" href="#ic-close"></use>
                    </svg>
                </button>
            </div>
        </div>
        <div class="c-modal__content__body">
            <h2 class="c-heading c-heading-purple">New access code</h2>
            <p class="c-text-width c-text-dark">A new access code has been sent to your mobile number and is valid for 30 minutes from the time you requested the new code.</p>
            <button class="c-button c-button--full" id="button-access-code-modal-dismiss" (click)="pinSentDialogShown = false">Ok</button>
        </div>
    </div>
</div>
