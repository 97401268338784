import { Component, OnInit } from '@angular/core';
import { CaseModel } from '../../models/case.model';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-claim',
    templateUrl: './claim.component.html',
    styleUrls: ['./claim.component.scss']
})
export class ClaimComponent implements OnInit {

    case: CaseModel;

    constructor(private route: ActivatedRoute) { }

    ngOnInit(): void {

        this.case = this.route.parent.parent.snapshot.data.case;

    }

}
