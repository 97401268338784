<div class="u-mtop-xs">
    <h1 class="c-heading c-heading-uppercase">Contact us</h1>
    <p class="c-text-width">If you can’t find the answer in our <a disableTabOnModal routerLink="../faqs" class="c-text-link c-text-link--white underline">FAQs centre</a> you can get in touch with a member of the team here at Minster Law.</p>
    <p class="c-text-width">Our offices are open Monday to Friday 9am-5pm. We are not open on bank holidays (England and Wales only).</p>
</div>
<div class="u-mtop-ism u-show-desktop">
    <ul class="c-text-list-style-none u-margin-none c-text-white">
        <li class="c-text-bold">
            Email: <a disableTabOnModal id="file-handler-email-address" href="mailto:{{email}}" class="c-text-link">{{email}}</a>
        </li>
        <li class="c-text-bold">
            Telephone: <a disableTabOnModal id="file-handler-telephone" href="tel:{{trimmedPhoneNumber}}" class="c-text-link">{{trimmedPhoneNumber}}</a>
        </li>
        <li class="c-text-bold">
            Address: <span class="c-text-normal">Minster Law, Kingfisher House, Peel Avenue, Wakefield WF2 7UA</span>
        </li>
        <!-- This is the enquiries code
            <li class="u-mtop-ism">
                <span class="c-text-bold">Have a question?</span>
            </li>
            <li>
                <minster-button buttonClass="is-fullwidth" id="enquiry-button" isPrimary="true" text="Send us a message" (click)="onShowEnquiries()"></minster-button>
            </li>
        -->
    </ul>
</div>
<div class="u-mtop-ism u-show-mobile">
    <p class="c-text-width">Minster Law, Kingfisher House, Peel Avenue, Wakefield WF2 7UA</p>
    <a disableTabOnModal href="mailto:{{email}}" class="c-button c-button--full u-show-mobile">Email {{email}}</a>
    <a disableTabOnModal href="tel:{{trimmedPhoneNumber}}" class="c-button c-button--full">Call {{trimmedPhoneNumber}}</a>
    <!--<a disableTabOnModal class="c-button c-button--full" (click)="onShowEnquiries()">Send us a message</a>-->
</div>
<!--<div class="u-mtop-ism">
    <minster-button text="Review us on Trustpilot" (click)="openTrustPilot()" ariaLabel="review-on-trustpilot" isFull="true"></minster-button>
</div>-->
<minster-modal [show]="isEnquiriesShown" (closed)="isEnquiriesShown = false; onCloseEnquiries()" [isMiaModal]="formTab == FormTab.Confirmation">
    <ng-container *ngIf="formTab == FormTab.EnquiryType">
        <h2 class="c-heading c-heading-purple">Have a question?</h2>
        <p class="c-text-width c-text-dark">
            If you have a query that isn’t covered in our FAQs, you can let us know here and we can get back to you with an answer.
        </p>
        <form [formGroup]="form" (ngSubmit)="onSubmitEnquiryType(form)" class="u-mtop-ism">
            <div class="c-dropdown u-mtop-ism">
                <label for="enquiry-type" class="u-text-bold">Select question topic*</label>
                <select class="c-dropdown__select" id="enquiry-type" [formControl]="form.controls['enquiryType']" (change)="onEnquiryTypeChange()">
                    <option disabled="" value="null">Please select</option>
                    <option *ngFor="let item of enquiryTypes" [value]="item.key">{{item.value}}</option>
                </select>
            </div>
            <ng-container *ngIf="form.value.enquiryType">
                <div class=" c-text-input u-mtop-ism">
                    <label for="" class="u-text-bold">Comments*</label>
                    <textarea id="enquiry-notes" [formControl]="form.controls['notes']" class="c-text-area" cols="10" rows="30"></textarea>
                    <div *ngIf="(form.get('notes').touched || submitted) && !form.get('notes').valid"
                         class="c-text-input___validation">
                        Please add notes here
                    </div>
                </div>
                <!-- FAQ -->
                <ul class="c-panel-list u-mtop-sm" *ngIf="faqResult && faqResult.found">
                    <div class="u-text-bold">Have you tried these FAQs</div>
                    <li class="c-panels__item" minster-expandable-panel [heading]="item.questions[0]" [isSimple]="true" *ngFor="let item of faqResult.answers; let i = index;">
                        <div class="c-text-dark" [innerHTML]="item.answer"></div>
                    </li>
                </ul>
                <!-- NAVIGATION -->
                <div class=" u-show-mobile">
                    <div class="u-flex-center u-mtop-ism">
                        <minster-button [isLong]="true" buttonClass="c-button c-button--grey" text="Cancel" (click)="onCloseEnquiries()"></minster-button>
                    </div>
                    <div class="u-flex-center">
                        <minster-button [isLong]="true" buttonType="submit" [buttonClass]="!form.valid ? 'c-button c-button--disabled' : 'c-button'" text="Next"></minster-button>
                    </div>
                </div>
                <div class="u-show-desktop">
                    <div class="u-flex-center u-mtop-ism">
                        <minster-button buttonClass="c-button c-button--grey" text="Cancel" (click)="onCloseEnquiries()"></minster-button>
                        <minster-button buttonType="submit" id="enquiry-next-button" [buttonClass]="!form.valid ? 'c-button c-button--disabled' : 'c-button'" text="Next"></minster-button>
                    </div>
                </div>
            </ng-container>
        </form>
    </ng-container>
    <ng-container *ngIf="formTab == FormTab.Contact">
        <form [formGroup]="formContact" (ngSubmit)="onSubmitContact(formContact)" class="u-mtop-ism">
            <div class="u-mtop-ism">
                <div class="u-text-bold">Contact preference*</div>
                <div class="c-radio-btn-wrapper">
                    <div *ngFor="let contactType of contactTypes">
                        <div class="c-radio-btn">
                            <input type="radio" name="contact" [formControl]="formContact.controls['contact']" [value]="contactType" [id]="contactType" (change)="onContactChange(contactType)">
                            <label [for]="contactType">{{contactType}}</label>
                        </div>
                    </div>
                </div>
                <p *ngIf="formContact.value.contact === 'Email'" class="c-text-width c-text-dark u-mtop-ism">
                    We will send our response to your email
                    <a id="" href="#" class="c-text-link ">{{email}}</a>
                </p>
                <ng-container *ngIf="formContact.value.contact === 'Phone'">
                    <div class="u-text-bold u-mtop-ism" *ngIf="phoneNumbers().length == 0">
                        Sorry, we don't have a contact number for you
                    </div>
                    <ng-container *ngIf="phoneNumbers().length === 1">
                        <p class="c-text-width c-text-dark u-mtop-ism">
                            We will contact you on
                            <a id="" href="#" class="c-text-link ">{{phoneNumbers()}}</a>
                        </p>
                    </ng-container>
                    <ng-container *ngIf="phoneNumbers().length > 1">
                        <div class="u-text-bold u-mtop-ism">Choose your preferred contact number</div>
                        <div class="c-radio-btn-wrapper">
                            <div *ngFor="let number of phoneNumbers()">
                                <div class="c-radio-btn">
                                    <input type="radio" name="contact-details" [formControl]="formContact.controls['contactDetails']"
                                           [value]="number"
                                           [id]="number">
                                    <label [for]="number">{{number}}</label>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isTimeSlotUnavailable; else isTimeSlotAvailable">
                        <p class="c-text-width c-text-dark u-mtop-ism">Thanks for contacting us. We will do our best to call you back by the end of next working day.</p>
                    </ng-container>
                    <ng-template #isTimeSlotAvailable>
                        <div class="c-text-input u-mtop-ism">
                            <label for="date" class="u-text-bold">Select call back date*</label>
                            <mat-form-field class="example-full-width c-table" appearance="fill">
                                <input [formControl]="formContact.controls['date']" [max]="maxDate" matInput [matDatepickerFilter]="datePickerFilter" [matDatepicker]="picker" (dateChange)="onDateChange()">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="!submitting && formContact.get('date').touched && !formContact.get('date').valid" class="c-text-input___validation">
                                Please add a date
                            </div>
                        </div>
                        <ng-container *ngIf="formContact.value.date">
                            <div class="c-dropdown u-mtop-ism">
                                <label for="time" class="u-text-bold">Select call back time*</label>
                                <select class="c-dropdown__select" id="time" [formControl]="formContact.controls['time']">
                                    <option disabled="" value="null">Please select</option>
                                    <option *ngFor="let option of timeOptions" [disabled]="option.disabled" value="{{option.time}}">{{option.time}}</option>
                                </select>
                            </div>
                            <p class="c-text-width c-text-dark u-mtop-ism">We will always try our best to call you in your preferred time.</p>
                        </ng-container>
                    </ng-template>
                </ng-container>
                <!-- NAVIGATION -->
                <div class=" u-show-mobile">
                    <div class="u-flex-center u-mtop-ism">
                        <minster-button [isLong]="true" buttonClass="c-button c-button--grey" text="Back" (click)="onBackEnquiries()"></minster-button>
                    </div>
                    <div class="u-flex-center">
                        <minster-button [isLong]="true" buttonType="submit" [buttonClass]="!formContact.valid || submitting ? 'c-button c-button--disabled' : 'c-button'" text="{{submitting ? 'Submitting..' : 'Confirm'}}"></minster-button>
                    </div>
                </div>
                <div class="u-show-desktop">
                    <div class="u-flex-center u-mtop-ism">
                        <minster-button buttonClass="c-button c-button--grey" text="Back" (click)="onBackEnquiries()"></minster-button>
                        <minster-button id="enquiry-confirm-button" buttonType="submit" [buttonClass]="!formContact.valid || submitting ? 'c-button c-button--disabled' : 'c-button'" text="{{submitting ? 'Submitting..' : 'Confirm'}}"></minster-button>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="formTab == FormTab.Confirmation">
        <h2 id="enquiry-confirmation" class="c-heading c-heading-purple">Thank you for your enquiry</h2>
        <p class="c-text-width c-text-dark">
            Your message is making its way to our team now.
        </p>
        <!-- NAVIGATION -->
        <div class=" u-show-mobile">
            <div class="u-flex-center u-mtop-ism">
                <minster-button [isLong]="true" buttonClass="c-button" text="Return to home" [routerLink]="['../case/details']"></minster-button>
            </div>
        </div>
        <div class="u-show-desktop">
            <div class="u-flex-center u-mtop-ism">
                <minster-button buttonClass="c-button" text="Return to home" [routerLink]="['../case/details']"></minster-button>
            </div>
        </div>
    </ng-container>
</minster-modal>
