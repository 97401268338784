import {Component, Input, OnInit} from '@angular/core';
import { CaseModel } from 'src/app/models/case.model';
import { AppUtilities } from 'src/app/app.utility';

@Component({
  selector: 'accident-details',
  templateUrl: './accident-details.component.html',
  styleUrls: ['./accident-details.component.scss']
})
export class AccidentDetailsComponent implements OnInit {
    @Input() case: CaseModel;

    policeStationNameAddress?: string;

    ngOnInit() {
        window.scrollTo(0, 0);

        const policeStationName = this.case.accident.policeStationName;
        const name = policeStationName ? policeStationName + ', ' : '';

        const policeStationAddress = AppUtilities.objectValuesToString(this.case.accident.policeStationAddress);
        const address = policeStationAddress ? policeStationAddress : '';

        this.policeStationNameAddress = name + address;
    }
}
