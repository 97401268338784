import { Component, HostListener, OnDestroy, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { AppUtilities } from '../../app.utility';
import { NotificationModel } from '../../models/notification.model';
import { CaseModel } from '../../models/case.model';
import { LossLegacyService } from 'src/app/services/loss-legacy.service';
import { StateService } from 'src/app/services/state.service';

@Component({
    selector: 'minster-menu,[minster-menu]',
    templateUrl: './minster-menu.component.html',
    styleUrls: ['./minster-menu.component.scss']
})
export class MinsterMenuComponent implements OnInit, OnDestroy {

    isOpen = false;
    subscriptions: Subscription[] = [];
    reference: string;
    notifications: NotificationModel[];
    @Input('case') case: CaseModel;
    @Input('isMobile') isMobile: boolean;
    isMobileFooter: boolean;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private notificationService: NotificationService,
        private lossService: LossLegacyService,
        private stateService: StateService
    ) { }

    ngOnInit() {

        this.reference = AppUtilities.getRouteParam(this.route, 'reference');

        this.isMobileFooter = this.isMobile;
        this.onResize();

        this.notifications = [];

        // Get new notifications regularly.GE -  this is causing too many requests - Removed - 14571
        /*this.subscriptions.push(timer(0, SUBSCRIPTION_TIMER).subscribe(() => {
            this.notificationService.get(this.reference).subscribe(n => this.notifications = n.filter(n => !n.isRead));
        }));*/

        this.notificationService.get(this.reference).subscribe(n => this.notifications = n.filter(x => !x.isRead));

        // Update notifications counter when notifications are updated.
        this.subscriptions.push(this.notificationService.updated.subscribe(() => {
            this.notificationService.get(this.reference).subscribe(n => this.notifications = n.filter(n => !n.isRead));
        }));


        // Close the menu on navigation start.
        this.subscriptions.push(this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.isOpen = false;
            }
        }));
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isMobileFooter = window.innerWidth < 781;
        if (!this.isMobileFooter) {
            // this.isOpen = true;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    openAddLossModal(): void {
        this.lossService.startAddLoss(this.case);
    }

    loadRecite(): void {
        this.stateService.showRecite();
    }
}
