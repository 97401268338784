<div class="c-modal" role="dialog" style="display: block;" *ngIf="showInterrupt">
    <div class="c-modal__content">

        <div class="c-modal-top-nav">
            <div class="c-modal-top-nav__logo">
                <svg class="c-ic-purple c-modal-top-nav__logo-img">
                    <use xlink:href="#ic-logo-2" href="#ic-logo-2"></use>
                </svg>
            </div>
            <button class="c-ic-button c-modal-top-nav__close" role="button" aria-label="close" id="interrupt-dismiss-button" (click)="close()">
                <svg class="c-ic c-ic--sm">
                    <use xlink:href="#ic-close" href="#ic-close"></use>
                </svg>
            </button>
        </div>

        <div class="c-mia-modal">
            <div class="mia-modal-img">
                <img class="c-banner__mia c-banner__mia--modal c-mia-img" alt="Minster law logo" src="/images/Mia.svg">
            </div>
        </div>

        <div class="c-modal__content__body c-mia-modal-body">
            <div *ngIf="showWelcomePackInterrupt" id="interrupt-welcome-pack">
                <h2 class="c-heading c-heading-purple">Hello, {{case.client.forename}} {{case.client.surname}}</h2>
                <p class="c-text-width c-text-dark">Your welcome pack is ready to view.</p>
                <p class="c-text-width c-text-dark">There is important information contained within your welcome pack which we advise you to read.</p>
                <div class="u-flex-center  u-mtop-sm">
                    <button aria-label="View important documents" id="interrupt-show-docs-button" class="c-button c-button--full" isPrimary="true" (clicked)="showDocuments()">View important documents</button>
                </div>
            </div>
            <div *ngIf="showReadOnly" id="interrupt-read-only">
                <h2 class="c-heading c-heading-purple">Hello, {{case.client.forename}} {{case.client.surname}}</h2>
                <p class="c-text-width c-text-dark">Your account is now read only.</p>
                <p class="c-text-width c-text-dark">This means that you can still view and download any documents already on your portal, but you are unable to upload any new documents to us, and we won't upload any more to you.  Your file handler will personally send anything to you.  If you have outstanding documents to approve, please contact your handler with feedback.</p>
                <div class="u-flex-center  u-mtop-sm">
                    <button aria-label="Acknowledge" id="interrupt-read-only-button" class="c-button c-button--full" isPrimary="true" (click)="interruptToShow.type = 0">Ok</button>
                </div>
            </div>
            <div *ngIf="showMedicalReportReview" id="interrupt-medical-report-review">
                <h2 class="c-heading c-heading-purple">Welcome back, {{case.client.forename}} {{case.client.surname}}</h2>
                <p class="c-text-width c-text-dark">Your medical report has been returned and we have uploaded it to your account.</p>
                <p class="c-text-width c-text-dark">We now need you to review your report and confirm you are happy with the contents so we can progress your claim.</p>
                <div class="u-flex-center  u-mtop-sm">
                    <button aria-label="View medical report" id="interrupt-show-medical-button" class="c-button c-button--full" isPrimary="true" (click)="showMedicalReport(document)">View medical report</button>
                </div>
            </div>
            <div *ngIf="showAppointmentReminder" id="interrupt-medical-appointment-reminder">
                <h2 class="c-heading c-heading-purple">Welcome back, {{case.client.forename}} {{case.client.surname}}</h2>
                <p class="c-text-width c-text-dark">
                    This is just a reminder that you have a medical appointment on {{friendlyAppointmentDate}},
                    you can get more information on how to prepare for your appointment and directions by visiting the medical section of your account.
                </p>
                <div class="u-flex-center  u-mtop-sm">
                    <button aria-label="View medical appointments" id="interrupt-show-appointment-button" class="c-button c-button--full" isPrimary="true" (click)="showMedicalAppointment(appointment)">View medical appointments</button>
                </div>
            </div>
            <div *ngIf="showLossReminder" id="interrupt-medical-no-losses-reminder">
                <h2 class="c-heading c-heading-purple">Hello, {{case.client.forename}} {{case.client.surname}}</h2>
                <p class="c-text-width c-text-dark">By this stage in the claim you may have incurred financial losses such as travel costs or loss of earnings, would you like to add any losses now?</p>
                <p class="c-text-width c-text-dark">To view further information on losses go here to our <a (click)="closeAndGoToFaq('What is a loss?')" disableTabOnModal routerLink="../../faqs" [queryParams]="{q: 'What is a loss?'}" class="c-text-link underline">FAQs centre</a>.</p>
                <div class="u-flex-center  u-mtop-sm">
                    <button aria-label="Add losses" id="interrupt-add-loss-button" class="c-button c-button--full" isPrimary="true" (click)="addLosses()">Add losses now</button>
                </div>
            </div>
            <div *ngIf="showRecentLossReminder" id="interrupt-no-recent-losses-reminder">
                <h2 class="c-heading c-heading-purple">Hello, {{case.client.forename}} {{case.client.surname}}</h2>
                <p class="c-text-width c-text-dark">We noticed that you haven’t logged any losses for a while, are there any new losses you need to add?</p>
                <div class="u-flex-center  u-mtop-sm">
                    <button aria-label="Add losses" id="interrupt-add-loss-button" class="c-button c-button--full" isPrimary="true" (click)="addLosses()">Upload losses</button>
                </div>
            </div>
            <div *ngIf="showRegistrationLossReminder" id="interrupt-registration-losses-reminder">
                <h2 class="c-heading c-heading-purple">Hello, {{case.client.forename}} {{case.client.surname}}</h2>
                <p class="c-text-width c-text-dark">As a result of your accident you may find that you incur financial losses.</p>
                <p class="c-text-width c-text-dark">You can upload your losses right here on {{portalName}}, would you like to add a loss now?</p>
                <p class="c-text-width c-text-dark">To view further information on losses go here to our <a (click)="closeAndGoToFaq('What is a loss?')" disableTabOnModal routerLink="../../faqs" [queryParams]="{q: 'What is a loss?'}" class="c-text-link underline">FAQs centre</a>.</p>
                <div class="u-flex-center  u-mtop-sm">

                    <button aria-label="Upload losses" id="interrupt-add-loss-button" class="c-button c-button--full" isPrimary="true" (click)="addLosses()">Upload losses</button>
                </div>
            </div>
            <div *ngIf="showElapsedMedicalReportReview" id="interrupt-medical-report-reminder">
                <h2 class="c-heading c-heading-purple">Hello, {{case.client.forename}} {{case.client.surname}}</h2>
                <p class="c-text-width c-text-dark">We noticed you have not confirmed your medical report, this is an important piece of evidence and helps to value your claim.</p>
                <p class="c-text-width c-text-dark">Without confirmation of your acceptance this may cause delays to your claim.</p>
                <p class="c-text-width c-text-dark">To view further information on medical reports go here to our <a (click)="closeAndGoToFaq('My medical reports')" disableTabOnModal routerLink="../../faqs" [queryParams]="{q: 'Medical reports'}" class="c-text-link underline">FAQs centre</a>.</p>
                <div class="u-flex-center  u-mtop-sm">
                    <button aria-label="View medical report" id="interrupt-view-medical-report-button" class="c-button c-button--full" isPrimary="true" (click)="showMedicalReport()">View medical report</button>
                </div>
            </div>
            <div *ngIf="showMilestones">
                <h2 class="c-heading c-heading-purple">Hello, {{case.client.forename}} {{case.client.surname}}</h2>
                <p class="c-text-width c-text-dark">New milestones feature in INK!</p>
                <p class="c-text-width c-text-dark">Check out our newest feature in your account. The progress bar presents all the major milestones in your claims journey, keeping you up to date on your claim and any actions you may need to complete. Skip ahead in the timeline to understand upcoming stages or click the information icon to access descriptions and FAQs to learn about medicals, settlement and more.</p>
                <p>Milestones</p>
            </div>
            <div class="u-mtop-md" *ngIf="!showMedicalReportReview && !showElapsedMedicalReportReview  && !showReadOnly && !showNewPortal && !showMilestones">
                <input class="c-styled-checkbox" id="checkbox-show-message" type="checkbox" [checked]="showMessage" (change)="toggleShowMessage()">
                <label for="checkbox-show-message">Don’t show this message again</label>
            </div>

        </div>
    </div>
</div>
