import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    private useLocalStorage = false;

    constructor(private cookie: CookieService, @Inject(DOCUMENT) private document: any) {
        this.useLocalStorage = this.hasLocalStorage();
    }

    clear(): void {
        if (this.useLocalStorage) {
            localStorage.clear();
        } else {
            this.cookie.deleteAll('/', 'localhost');
        }
    }

    getItem(key: string): string {
        if (this.useLocalStorage) {
            return localStorage.getItem(key);
        }
        return this.cookie.get(key);
    }

    setItem(key: string, value: string) {
        if (this.useLocalStorage) {
            localStorage.setItem(key, value);
        } else {
            this.cookie.set(key, value);
        }
    }

    removeItem(key: string) {
        if (this.useLocalStorage) {
            localStorage.removeItem(key);
        } else {
            this.cookie.delete(key);
        }
    }

    deleteAllCookies(): void {
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
            var d = window.location.hostname.split(".");
            while (d.length > 0) {
                var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
                var p = location.pathname.split('/');
                document.cookie = cookieBase + '/';
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join('/');
                    p.pop();
                };
                d.shift();
            }
        }
    }

    deleteCooky(name: string): void {
        this.cookie.delete(name, '/', null, false, 'Lax');
    }

    private hasLocalStorage() {
        const test = 'test';
        try {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        } catch (e) {
            return false;
        }
    }
}
