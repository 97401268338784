<ng-container (click)="select()">
    <div class="c-table-list__title" (click)="select()">
        {{notification.portalName}}
        <span>{{friendlyNotificationDate}}</span>
    </div>

    <div class="c-table-list__body" *ngIf="notification.notificationObject === 'EndOfClaim'" (click)="select()">
        <div class="u-mtop-ism">
            <p>{{notification.name}}</p>
            <p>We wish to inform you that your claim has been settled.</p>
            <p >Your compensation has been processed and should be with you within the next 7 working days.</p>
            <!--<p >We’re constantly reviewing feedback from clients, learning how we can further improve and recognising staff who have provided a good service.</p>
            <p > We’re able to provide this high level of client service because we invest in our people and processes so that they can focus on one thing only – your satisfaction.</p>
            <div class="u-mtop-ism">
                <minster-button text="Review us on Trustpilot" (click)="openTrustPilotClicked($event)"></minster-button>
            </div>-->
        </div>
    </div>
    <div class="c-table-list__body" *ngIf="isMedical" (click)="select()">
        <p>{{notification.name}}</p>
        <p *ngIf="isMedicalRequiringApproval">Results of your medical</p>
        <p *ngIf="IsMedicalApprovedOrRejeceted">You {{document.status.toLowerCase()}} this medical report on {{friendlyDocumentDate}}.</p>
        <p *ngIf="isMedicalRequiringApproval">It is important you review your medical report as soon as possible to avoid delays in your claim.</p>
        <p *ngIf="isMedicalRequiringApproval">Once you've reviewed your report please click <a (click)="approveClicked($event)">approve</a>. If you feel the report is incorrect then please click  <a (click)="rejectClicked($event)">reject</a>.</p>
        <div class="u-mtop-ism" *ngIf="isMedicalRequiringApproval">
            <minster-button buttonClass="u-mright-sm" ariaLabel="Review document"  [disabled]="isDownloading" [isLoading]="isDownloading" text="Download" isPrimary="true" (click)="openDocumentClicked($event)"></minster-button>
            <minster-button buttonClass="u-mright-sm" ariaLabel="Approve medical" *ngIf="document.requiresApproval" text="Approve" isWarning="true" (click)="approveClicked($event)"></minster-button>
            <minster-button ariaLabel="Reject medical" *ngIf="document.requiresApproval" text="Reject" isDanger="true" (click)="rejectClicked($event)"></minster-button>
        </div>
    </div>
    <div class="c-table-list__body" *ngIf="!isMedical && !isNonMedicalDocument && notification.notificationObject !== 'EndOfClaim'" (click)="select()">
        <p>{{notification.name}}</p>
    </div>
    <div class="c-table-list__body" *ngIf="isNonMedicalDocument" (click)="select()">
        <p>{{notification.name}}</p>
        <div class="u-mtop-ism">
            <minster-button ariaLabel="Open document" [disabled]="isDownloading" [isLoading]="isDownloading" text="Download" isPrimary="true" (click)="openDocumentClicked($event)"></minster-button>
        </div>
    </div>
    <div class="c-table-list__action" (click)="select()">
        <span class="c-notification" *ngIf="!notification.isRead && !isReadInThisSession"></span>
        <button disableTabOnModal aria-label="Delete notification" class="c-ic-button" (click)="deleteClicked($event)"> <svg class="c-ic c-ic--xs c-ic-vertical c-ic-white"> <use xlink:href="#ic-close" href="#ic-close"></use></svg></button>
    </div>
</ng-container>
