import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppValidators } from 'src/app/app.validator';
import { VerifyModel } from 'src/app/models/me.model';
import { MeService } from 'src/app/services/me.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { AppInsightsService } from 'src/app/services/appinsights.service';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    host: { 'style': 'width: 100%', 'display': 'block' }
})
export class ResetPasswordComponent implements OnInit {

    form: UntypedFormGroup;
    submitted: boolean = false;
    submitting: boolean = false;
    requesting: boolean = false;
    errors: string[] = [];
    completed: boolean = false;
    attempts: number = 3;
    guid: string = '';
    username: string;
    pinSentDialogShown = false;

    accesscodeTextMask = createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        integerLimit: 4,
        allowLeadingZeroes: true
    });

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private meService: MeService,
        private appInsightsService: AppInsightsService,
        private storage: StorageService
    ) {
        this.form = fb.group({
            'accesscode': [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4)])],
            'password': [null, Validators.compose([Validators.required, AppValidators.password])],
            'passwordagain': [null, null]
        }, { validator: AppValidators.passwordmatch });
    }

    ngOnInit() {
        this.guid = this.route.snapshot.params.guid;

        const accessCodeSent = JSON.parse(this.storage.getItem('accesscode_password'));
        if (!accessCodeSent) {
            this.requestAccessCode();
            this.requesting = false;
            this.storage.setItem('accesscode_password', 'true');
        }
    }

    requestAccessCode() {
        if (this.requesting) { return; }

        this.requesting = true;
        setTimeout(() => {
            this.requesting = false;
        }, 3000);

        this.meService.forgottenPassword(this.guid).subscribe({
            next: (result) => {
                this.appInsightsService.logCustomEvent(`RequestAccessCodePassword ${this.guid}`, 'Succeeded', result.username);
                this.username = result.username;
                this.pinSentDialogShown = true;
            },
            error: () => {
                this.appInsightsService.logCustomEvent(`RequestAccessCodePassword ${this.guid}`, 'Failed', '');
            }
        });
    }

    clear() {
        this.errors = [];
    }

    submit(form: UntypedFormGroup) {
        this.submitted = true;
        if (form.invalid) { return; }
        form.disable();
        this.submitting = true;

        const model: VerifyModel = {
            code: form.value.accesscode,
            password: form.value.password,
            confirmPassword: form.value.passwordagain,
            registrationCode: this.guid,
            transactionId: ''
        };

        this.meService.forgottenPasswordVerify(model).subscribe(
            result => {
                this.submitting = false;
                this.completed = true;
                this.appInsightsService.logCustomEvent('Use access code ', 'Succeeded', this.username);
            },
            error => {
                form.enable();
                this.submitting = false;
                this.attempts -= 1;
                const response = error ? error.error : undefined;
                if (response && response.Expired) { this.attempts = 0; }
                this.errors = ['Please enter a valid access code'];
                this.appInsightsService.logCustomEvent('Use access code ', 'Failed', this.username);
            }
        );
    }
}
