import { ValidationErrors, AbstractControl, UntypedFormGroup } from '@angular/forms';

export class AppValidators {
    static postcode(control: AbstractControl): ValidationErrors | null {
        const regexp = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/i;
        const test = regexp.test(control.value);
        return test ? null : { 'postcode': { value: control.value } };
    }

    static password(control: AbstractControl): ValidationErrors | null {
        const regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z])\S{8,20}$/g;
        const test = regexp.test(control.value);
        return test ? null : { 'password': { value: control.value } };
    }

    static email(control: AbstractControl): ValidationErrors | null {
        const regexp = /^[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}$/g;
        const test = regexp.test(control.value);
        return test ? null : { 'email': { value: control.value } };
    }

    static text(control: AbstractControl): ValidationErrors | null {
        const regexp = /^[A-Za-z\s]*$/g;
        const test = regexp.test(control.value);
        return test ? null : { 'text': { value: control.value } };
    }

    static alphanumeric(control: AbstractControl): ValidationErrors | null {
        const regexp = /^[A-Za-z\d\s]*$/g;
        const test = regexp.test(control.value);
        return test ? null : { 'alphanumeric': { value: control.value } };
    }

    static whitespace(control: AbstractControl): ValidationErrors | null {
        const test = (control.value || '').trim().length !== 0;
        return test ? null : { 'whitespace': { value: control.value } };
    }

    static passwordmatch(group: UntypedFormGroup) {
        const password = group.controls.password.value;
        const passwordagain = group.controls.passwordagain.value;
        return password === passwordagain ? null : { passwordmatch: true };
    }

    static emailsMatch(group: UntypedFormGroup) {
        const email = group.controls.email.value;
        const emailAgain = group.controls.emailAgain.value;
        return email === emailAgain ? null : { emailsMatch: true };
    }
}
