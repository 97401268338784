import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

interface BankHoliday {
  'england-and-wales': EnglandWales;
  scotland: EnglandWales;
  'northern-ireland': EnglandWales;
}

interface EnglandWales {
    division: string;
    events: Event[];
}

interface Event {
    title: string;
    date: string;
    notes: string;
    bunting: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GovService {

    constructor(
        private http: HttpClient,
        @Inject('API_BASE_URL') private baseUrl: string
    ) { }

    bankHolidays(): Observable<BankHoliday> {
        const url = this.baseUrl + 'gov/bankholidays';
        return this.http.get(url) as Observable<BankHoliday>;
    }
}
