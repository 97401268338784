import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AppInsightsService } from 'src/app/services/appinsights.service';
import { AuthService } from 'src/app/services/auth.service';
import { MeService } from 'src/app/services/me.service';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    host: { 'style': 'width: 100%', 'display': 'block' }
})
export class LoginComponent implements OnInit {

    form: UntypedFormGroup;
    submitted: boolean = false;
    submitting: boolean = false;
    errors: string[] = [];
    countFail: number = 0;
    returnUrl: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private appInsightsService: AppInsightsService,
        private meService: MeService,
        private storage: StorageService,
        private swUpdate: SwUpdate,
    ) {
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

        this.form = fb.group({
            'username': [null, [Validators.required, Validators.email]],
            'password': [null, Validators.required]
        });

        /*if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                alert('A new version of INK is available. Please click OK to refresh');
                window.location.reload();
            });
        }*/
    }

    clear() {
        this.errors = [];
    }

    submit(form: UntypedFormGroup) {
        this.submitted = true;
        if (form.invalid) {
            return;
        }
        form.disable();
        this.submitting = true;
        this.meService.checkStatusOfUser(form.value.username).subscribe(
            (result) => {
                if (result.IsBlocked) {
                    this.router.navigate(['/auth/login-fail']);
                } else if (result.IsDisabled) {
                    this.router.navigate(['/auth/login-disabled']);
                } else {
                    this.authService.authenticate(form.value.username, form.value.password).subscribe(
                        result2 => {
                            this.storage.setItem('token', JSON.stringify(result2));
                            this.storage.setItem('username', form.value.username);
                            this.router.navigate([this.returnUrl]);
                            this.authService.isLoggedIn = true;
                            this.appInsightsService.setLoginEmail(form.value.username);
                            this.appInsightsService.logCustomEvent('Portal login', 'success', null);
                        },
                        error => {
                            form.enable();
                            this.countFail += 1;
                            console.log(this.countFail);
                            this.submitting = false;
                            this.errors = ['Invalid username or password'];
                            this.appInsightsService.setLoginEmail(null);
                            this.appInsightsService.logCustomEvent('Portal login', 'failed', form.value.username);
                            if (this.countFail === 3) {
                                // Block Account
                                this.meService.blockedAccount(form.value.username).subscribe();
                                this.router.navigate(['/auth/login-fail']);
                            }
                        }
                    );
                }
            },
            error => {
                if (error.status === 400) {
                    // The email does not exist in the Portal. Login would fail anyway
                    this.errors = ['Invalid username or password'];
                    form.enable();
                    this.countFail += 1;
                    this.submitting = false;
                    this.appInsightsService.setLoginEmail(null);
                    this.appInsightsService.logCustomEvent('Portal login', 'failed', form.value.username);

                    if (this.countFail === 3) {
                        // van't block Account, doesn't exist
                        this.router.navigate(['/auth/login-fail']);
                    }
                }
            }
        );
    }

    ngOnInit() {
        // We don't want to clear storage.
        this.storage.removeItem('user');
        this.storage.removeItem('username');
        this.storage.removeItem('isendofclaim');
        this.storage.removeItem('token');
    }
}
