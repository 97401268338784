<div class="c-modal" role="dialog" aria-labelledby="step-heading" aria-describedby="step-instructions" aria-modal="true" style="display: block;" *ngIf="state !== 0">
    <div class="c-modal__content">
        <div class="c-modal-top-nav">
            <button class="c-ic-button" role="button" aria-label="back" [tabindex]="state === 1 || state === 4 ? '-1' : ''" (click)="back()" [ngClass]="{'u-is-hidden': state === 1 || state === 4}">
                <svg class="c-ic c-ic--sm">
                    <use xlink:href="#ic-back-modal" href="#ic-back-modal"></use>
                </svg>
            </button>
            <div class="c-modal-top-nav__logo">
                <svg class="c-ic-purple c-modal-top-nav__logo-img">
                    <use xlink:href="#ic-logo-2" href="#ic-logo-2"></use>
                </svg>
            </div>
            <button class="c-ic-button c-modal-top-nav__close" role="button" id="dismiss-upload-confirmation-button" aria-label="close" (click)="close()">
                <svg class="c-ic c-ic--sm">
                    <use xlink:href="#ic-close" href="#ic-close"></use>
                </svg>
            </button>
        </div>
        <div class="c-modal__content__body">
            <ng-container *ngIf="state === 1">
                <form [formGroup]="typeForm" action="" (submit)="submitTypeSelection(typeForm)">
                    <div id="add-document-type-selection-panel">
                        <h2 id="step-heading" class="c-heading c-heading-purple">Choose your document type</h2>
                        <p id="step-instructions" class="c-text-width c-text-dark">
                            Before uploading your file choose its closest category from the list below.
                        </p>
                        <div class="c-dropdown u-mtop-ism">
                            <label for="documentType" class="u-text-bold">Document type</label>
                            <select class="c-dropdown__select" id="documentType" [formControl]="typeForm.controls['type']">
                                <option value="" disabled>Please choose</option>
                                <option value="medical">Medical</option>
                                <option value="court">Court</option>
                                <option value="settlement">Settlement</option>
                                <!-- important liabilityEvidence must be camel cased -->
                                <option value="liabilityEvidence">Liability evidence</option>
                            </select>
                        </div>
                        <div class="u-flex-center  u-mtop-sm">
                            <minster-button isLong="true" id="documentTypeNext" buttonClass="is-fullwidth" isSuccess="true" buttonType="submit" [disabled]="submitting" text="Next" [isLoading]="isLoading ? true : false" ariaLabel="document-chose-next"></minster-button>
                        </div>
                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf="state === 2">
                <div id="add-document-upload-files-panel">
                    <h2 id="step-heading" class="c-heading c-heading-purple">Upload files</h2>
                    <p id="step-instructions" class="c-text-width c-text-dark">
                        We can accept a range of files including images, PDFs, Word and Excel.
                        <ng-container *ngIf="canUploadInfinite">You can provide as many files as you like.</ng-container>
                        <ng-container *ngIf="!canUploadInfinite">You can choose a maximum of {{maxFileCount}} at a time to upload.</ng-container>
                        The size of each file must not exceed 10mb and the total file upload must not exceed {{uploadLimit}}mb. When uploading large files we recommend using a Wi-Fi connection.
                    </p>
                    <h2 class="c-heading c-heading-purple" *ngIf="docsToUpload && docsToUpload.length > 0">Selected files:</h2>
                    <div class="c-uploaded-doc-wrapper u-mtop-ism" *ngIf="docsToUpload && docsToUpload.length > 0">
                        <ul class="c-uploaded-doc-wrapper">
                            <li class="c-uploaded-doc-item" *ngFor="let doc of docsToUpload; let i = index;">
                                <div class="c-uploaded-doc">
                                    <div>
                                        <svg class=" c-ic c-ic--md c-ic-purple"> <use xlink:href="#ic-image" href="#ic-image"></use></svg>
                                    </div>
                                    <span class="c-uploaded-doc__text" [id]="'add-document-filename-' + i">{{doc.newFileName}}</span>
                                    <button class="c-ic-button" *ngIf="canRemoveDocuments" [id]="'add-document-removefile-' + i" (click)="removeFile(doc)">
                                        <svg class=" c-ic c-ic--sm c-ic-blue"> <use xlink:href="#ic-delete" href="#ic-delete"></use></svg>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <p style="padding: 5px;" *ngIf="isLoading">
                        <ngx-loading-bar [fixed]="false"></ngx-loading-bar>
                    </p>
                    <div class="message is-danger" *ngIf="fileErrors && fileErrors.length > 0">
                        <ul id="add-documents-error-list">
                            <li *ngFor="let fileError of fileErrors; let i = index;" [id]="'add-document-fileerror-' + i">{{fileError}}</li>
                        </ul>
                    </div>
                </div>
                <form action="" (submit)="submitDocumentSelection(fileInput)">
                    <div class="u-flex-center u-mtop-sm">
                        <input type="file" #fileInput (change)="evidenceFilesChanged($event)" placeholder="Upload file" multiple accept=".bmp,.doc,.docx,.gif,.jpg,.jpeg,.pdf,.png,.tif,.tiff,.txt,.xls,.xlsx" style="display: none">
                        <minster-button isFull="true" id="fileUploadSubmit" [disabled]="!canRemoveDocuments" buttonType="submit" aria-label="Add files" text="Add file(s)" ariaLabel="document-select-file"></minster-button>
                    </div>
                </form>
                <form [formGroup]="fileNameForm" action="" (submit)="uploadSubmit(fileNameForm)">
                    <div class="c-text-input u-mtop-ism" *ngIf="docsToUpload.length > 0">
                        <label for="renameFiles" class="u-text-bold">Rename files (optional)</label>
                        <input class="c-text-input-style" type="text" [formControl]="fileNameForm.controls['fileName']" id="renameFiles" name="Rename File(s)" [disabled]="this.documentGroupId > 0">
                        <ng-container *ngIf="submittedFileName">
                            <minster-validation-message *ngIf="fileNameForm.controls.fileName.hasError('maxlength')" message="Your new file name cannot exceed 50 characters."></minster-validation-message>
                        </ng-container>
                    </div>
                    <div class="u-flex-center">
                        <minster-button id="upload-file-next-button" buttonType="submit" isFull="true" [disabled]="uploadNextButtonDisabled" [isLoading]="isLoading" text="Next" submittingText="Submitting..." ariaLabel="document-upload-file"></minster-button>
                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf="state === 3">
                <div id="add-document-describe-panel">
                    <h2 id="step-heading" class="c-heading c-heading-purple">Document details</h2>
                    <p id="step-instructions" class="c-text-width c-text-dark">Provide more details about your document(s) if you wish</p>
                    <form [formGroup]="nameForm" action="" (submit)="submit(nameForm)">

                        <div class="c-text-input u-mtop-ism">
                            <label class="u-text-bold" for="notes">Notes</label>
                            <textarea class="c-text-area" [formControl]="nameForm.controls['notes']" id="add-document-document-notes"></textarea>
                        </div>
                        <ng-container *ngIf="submittedNameSelection">
                            <minster-validation-message *ngIf="nameForm.controls.notes.hasError('maxlength')" message="Your notes cannot exceed 500 characters."></minster-validation-message>
                        </ng-container>
                        <div class="u-flex-center  u-mtop-ism">
                            <minster-button buttonClass="m-r-20 u-mright-sm" buttonType="submit" [disabled]="isLoading" id="add-document-submit-button" text="Add document" isPrimary="true" [isLoading]="isLoading ? true : false" ariaLabel="document-details-add"></minster-button>
                            <minster-button buttonType="button" (click)="close()" [disabled]="isLoading" id="add-document-cancel-button" text="Cancel" isInfo="true" ariaLabel="document-details-cancel"></minster-button>
                        </div>
                    </form>
                    <div class="message is-danger" *ngIf="fileErrors && fileErrors.length > 0">
                        <ul>
                            <li *ngFor="let fileError of fileErrors">{{fileError}}</li>
                        </ul>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="state === 4">
                <div id="add-document-complete-panel">
                    <h2 id="step-heading" class="c-heading c-heading-purple">Upload successful</h2>
                    <p id="step-instructions" class="c-text-width c-text-dark">
                        Thank you, your file has been added to your account.
                    </p>
                </div>
                <div class="u-flex-center  u-mtop-sm">
                    <minster-button [isLong]="true" (click)="addAnotherDocument()" id="add-another-document-button" text="Add another document" ariaLabel="document-add-another-document"></minster-button>
                </div>
            </ng-container>
        </div>
    </div>
</div>
