import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { VerifyModel, VerifyResultModel, PinCodeResetResultModel, GetUserResultModel, StatusUserModel, AccountReadOnlyUserModel,
    ReRegistered,
    UpdateFirstTimeNotification,
    VerifyChangeEmailModel
} from '../models/me.model';

@Injectable({
    providedIn: 'root'
})
export class MeService {
    constructor(
        private http: HttpClient,
        @Inject('API_BASE_URL') private baseUrl: string
    ) { }

    pinCodeReset(guid: string): Observable<PinCodeResetResultModel> {
        const url = this.baseUrl + 'me/register/' + guid;
        return this.http.post(url, {}) as Observable<PinCodeResetResultModel>;
    }

    verify(model: VerifyModel): Observable<VerifyResultModel> {
        const url = this.baseUrl + 'me/verify';
        return this.http.post(url, model) as Observable<VerifyResultModel>;
    }

    forgottenPasswordSendEmail(username: string): Observable<any> {
        const url = this.baseUrl + 'me/forgottenpassword/sendemail/' + username;
        return this.http.post(url, {});
    }

    forgottenPassword(guid: string): Observable<any> {
        const url = this.baseUrl + 'me/forgottenpassword/' + guid;
        return this.http.post(url, {});
    }

    forgottenPasswordVerify(model: VerifyModel): Observable<VerifyResultModel> {
        const url = this.baseUrl + 'me/forgottenpassword/verify';
        return this.http.post(url, model) as Observable<VerifyResultModel>;
    }

    getUser(username: string): Observable<GetUserResultModel> {
        const url = this.baseUrl + 'me/getuser/' + username;
        return this.http.get(url) as Observable<GetUserResultModel>;
    }

    checkCode(guid: string): Observable<AccountReadOnlyUserModel> {
        const url = this.baseUrl + 'me/checkcode/' + guid;
        return this.http.post(url, {}) as Observable<AccountReadOnlyUserModel>;
    }

    checkStatusOfUser(username: string): Observable<StatusUserModel> {
        const url = this.baseUrl + 'me/checkstatusaccount/' + username;
        return this.http.post(url, {}) as Observable<StatusUserModel>;
    }

    blockedAccount(username: string) {
        const url = this.baseUrl + 'me/blockaccount/' + username;
        return this.http.post(url, {});
    }

    registerExpiryResubmit(guid: string) {
        const url = this.baseUrl + 'me/registerexpiryresubmit/' + guid;
        return this.http.post(url, {}) as Observable<ReRegistered>;
    }

    updateFirstTimeNotification(model: UpdateFirstTimeNotification): Observable<any> {
        const url = this.baseUrl + 'me/updatefirsttimenotification';
        return this.http.post(url, model) as Observable<any>;
    }

    changeEmail(model: VerifyChangeEmailModel): Observable<VerifyResultModel> {
        return this.http.post<any>(`${this.baseUrl}me/ChangeEmail`, model);
    }

    checkChangeEmailCode(guid: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}me/CheckChangeEmailCodeValid/${guid}`);
    }

    SendEmailLinkEmail(username: string): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}me/sendChangeEmailEmail/${username}`, {});
    }
}
