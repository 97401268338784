import { Component } from '@angular/core';
import { AppUtilities } from 'src/app/app.utility';
import { FileDownloadService, PdfFilesType } from 'src/app/services/file-download.service';

@Component({
  selector: 'app-negotiations',
  templateUrl: './negotiations.component.html',
  styleUrls: ['./negotiations.component.scss']
})
export class NegotiationsComponent {

    constructor(private fileDownloadService: FileDownloadService) { }

    downloadNotificationsGuide(): void {
        this.fileDownloadService.download(PdfFilesType.NegotiationsGuide).subscribe(data =>
             {
                const content = new Blob([data], { type: 'application/pdf' });
                const filename = 'MinsterLawOffersGuide.pdf';
                AppUtilities.saveToFile(content, filename);
             }
        )
    }
}
