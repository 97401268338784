<footer class="footer">
    <hr />
    <p>
        We use our own and third-party cookies to offer you a better experience. By continuing to use {{portal}}, you’re accepting our <a disableTabOnModal class="c-text-link c-text-link--white" href="https://www.minsterlaw.co.uk/cookie-policy/" target="_blank">Cookies Policy</a>.
        For more information, please refer to our <a disableTabOnModal class="c-text-link c-text-link--white" href="https://www.minsterlaw.co.uk/privacy-policy/" target="_blank">Privacy Notice</a> and our <a disableTabOnModal class="c-text-link c-text-link--white" href="https://www.minsterlaw.co.uk/cookie-policy/" target="_blank">Cookie Policy</a>.
    </p>
    <p>
        Minster Law Ltd is authorised and regulated by the <a disableTabOnModal class="c-text-link c-text-link--white" href="https://www.sra.org.uk/" target="_blank">Solicitors Regulation Authority</a> under No. 383018. VAT number: 466 9293 40.
        A list of Directors is available from the registered office: Minster Law, Kingfisher House, Peel Avenue, Wakefield, WF2 7UA. Please note that Minster Law does not accept faxes, by way of service or otherwise. Registered in England No. 4659625.
    </p>
    <div style="max-width:6rem;max-height:163px;" class="u-mbot-sm">
        <div style="position: relative;padding-bottom: 59.1%;height: auto;overflow: hidden;">
            <iframe frameborder="0" scrolling="no" allowTransparency="true" src="https://cdn.yoshki.com/iframe/55845r.html" style="border:0px; margin:0px; padding:0px; backgroundColor:transparent; top:0px; left:0px; width:100%; height:100%; position: absolute;"></iframe>
        </div>
    </div>
</footer>
