export interface NotificationModel {
    id: string;
    name: string;
    caseReference: string;
    dateCreated: Date;
    isRead: boolean;
    addToDailyEmail: boolean;
    notificationObject: string;
    notificationObjectId: string;
    lastUpdatedDate: Date;
    portalName: string;
    typeName: string;
}

export class NotificationSaveModel {
    caseReference: string;
    type: string;
    name: string;
    isRead: boolean;
    addToDailyEmail: boolean;
    dateCreated: Date;
    notificationObject: string;
    notificationObjectId: string;
    transactionId: string;
    id: string;
}

export class NotificationDeleteModel {
    notificationObject: string;
    notificationObjectId: string;
    caseReference: string;    
}
