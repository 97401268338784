import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { TokenModel } from '../models/auth.model';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

    token: TokenModel = new TokenModel;

    constructor(
        @Inject('BASE_URL') private baseUrl: string,
        private authService: AuthService,
        private router: Router,
        private storage: StorageService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // handle requests without required authorization headers
        const proxyUrl = this.baseUrl.toLowerCase();
        const proxyUrlAuth = this.baseUrl.toLowerCase() + 'api/auth';
        if (!req.url.toLowerCase().startsWith(proxyUrl) ||
            req.url.toLowerCase().startsWith(proxyUrlAuth)) {
            return next.handle(req);
        }

        const token = this.storage.getItem('token');

        // try request without authorization header
        if (!token) { return next.handle(req); }

        this.token = JSON.parse(token) as TokenModel;
        const request = req.clone({
            setHeaders: { 'Authorization': 'Bearer ' + this.token.access_token }
        });

        return next.handle(request).pipe(
            catchError(error => {
                if (error.status === 401) {
                    // attempting to refresh token
                    return this.authService.refresh(this.token.refresh_token)
                        .pipe(
                            flatMap(
                                result => {
                                    this.storage.setItem('token', JSON.stringify(result));
                                    const modified = req.clone({
                                        setHeaders: { 'Authorization': 'Bearer ' + result.access_token }
                                    });
                                    return next.handle(modified);
                                }
                            )
                        )
                        .pipe(
                            catchError(
                                error => {
                                    this.router.navigate(['/auth/logout']);
                                    return throwError(error);
                                }
                            )
                        );
                }
                return throwError(error);
            })
        );
    }
}
