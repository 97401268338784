<div class="l-main-view__content-panel__container l-container-fluid">
    <span class="btn--unfocused btn--unfocused--small m-icon--doughnut " tabindex="-1">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 400" style="enable-background:new 0 0 400 400;" xml:space="preserve">
            <circle class="btn--circle__statcircle" cx="200" cy="200" r="177.7" />
            <path class="btn--circle__statcircle statcircle__animated" style="stroke:#A0CF67" d="M200.6,22.3c97.9,0.3,177.1,79.7,177.1,177.7c0,98.1-79.5,177.7-177.7,177.7S22.3,298.1,22.3,200c0-97.8,79-177.2,176.7-177.7" />
		</svg>
        <svg class="m-ic m-ic--md">
            <use xlink:href="#ic-handshake" href="#ic-handshake"></use>
        </svg>
    </span>
    <div class="row l-main-view__content-panel__container-max-width--modified">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="h-text--center h-margin-bottom">
                <h1 class="heading">Thank you</h1>
                <p>Your payment has now been processed and will be with you within the next 7 working days.</p>
                <p>The sum represents the full and final settlement of your claim. Your claim has now come to a close.</p>
            </div>
        </div>
    </div>
</div>
