import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CaseModel } from '../models/case.model';
import { StorageService } from '../services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class CaseGuard implements CanActivate {

    case: CaseModel;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storage: StorageService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const isEndOfClaim = JSON.parse(this.storage.getItem('isendofclaim'));

        if (!isEndOfClaim) {
            this.router.navigate(['/portal']);
            return false;
        }

        return true;
    }
}
