import { Component, OnInit, SecurityContext, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CaseModel } from '../../models/case.model';
import { EnquiryModel, EnquiryTypeModel } from '../../models/enquiry.model';
import { FaqResponseModel } from '../../models/faq.model';
import { AppInsightsService } from '../../services/appinsights.service';
import { ConfigurationService } from '../../services/configuration.service';
import { EnquiryService } from '../../services/enquiry.service';
import { FaqService } from '../../services/faq.service';
import { StringUtilities } from '../../utilities/string.utilities';
import { GovService } from '../../services/gov.service';
import {NgxHotjarService} from 'ngx-hotjar';

enum FormTab {
    EnquiryType,
    Contact,
    Confirmation
}

enum ContactType {
    Email = 'Email',
    Phone = 'Phone'
}

const morning: string = "9:00"
const afternoon: string = "12:00"
const evening: string = "17:00"

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    contactTypes: ContactType[] = [ContactType.Email, ContactType.Phone];
    defaultEmail: string;
    defaultPhoneNumber: string;
    case: CaseModel;
    isEnquiriesShown: boolean;
    isFAQsShown = true;
    isTimeSlotUnavailable : boolean;
    maxDate: Date = new Date();

    FormTab = FormTab;
    formTab: FormTab = FormTab.EnquiryType;
    form: UntypedFormGroup;
    formContact: UntypedFormGroup;
    submitted: boolean;
    submitting: boolean;
    equiryModel: EnquiryModel;
    bankHolidays: any[] = [];

    phoneNumberArray: string[];
    faqResult: FaqResponseModel;
    enquiryTypes: EnquiryTypeModel[] = [
        {value:'Claim status', key: 64},
        {value:'Court', key: 8},
        {value:'General enquiry', key: 128},
        {value:'INK enquiry', key: 32},
        {value:'Losses', key: 1},
        {value:'Medical appointment', key: 1},
        {value:'Medical report', key: 2},
        {value:'Medical treatment', key: 16},
        {value:'Settlement', key: 4},
    ];



    timeOptions: {
        time: string;
        disabled?: boolean;
    }[] = [
            { time: 'AM' },
            { time: 'PM' },
            { time: 'Evening' }
        ];

    constructor(
        private route: ActivatedRoute,
        private configurationService: ConfigurationService,
        private faqService: FaqService,
        private appInsights: AppInsightsService,
        private sanitizer: DomSanitizer,
        private fb: UntypedFormBuilder,
        private enquiryService: EnquiryService,
        private cdRef: ChangeDetectorRef,
        private govService: GovService,
        public hjService: NgxHotjarService
    ) { }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    get email(): string {
        if (!this.case || !this.case.client || !this.case.client.email) {
            return this.defaultEmail;
        }

        if (this.case.fileHandler.department === 'MOJ') {
            return 'claims@minsterlaw.co.uk';
        }

        return this.case.fileHandler.emailAddress;
    }

    get phoneNumber(): string {
        if (!this.case || !this.case.fileHandler || !this.case.fileHandler.phoneNumber) {
            return this.defaultPhoneNumber;
        }

        if (this.case.fileHandler.department === 'MOJ') {
            return '01924 582772';
        }

        return this.case.fileHandler.phoneNumber;
    }

    get clientMobileNumber(): string {
        if (!this.case || !this.case.fileHandler || !this.case.fileHandler.phoneNumber) {
            return this.defaultPhoneNumber;
        }
        return this.case.client.mobileTel;
    }

     phoneNumbers(): string[] {
        if (!this.case || !this.case.fileHandler || !this.case.fileHandler.phoneNumber) {
            return null;
        }
        const phoneNumberArray = [this.case.client.homeTel, this.case.client.workTel, this.case.client.mobileTel,
            this.case.litigationFriend.homeTel, this.case.litigationFriend.workTel, this.case.litigationFriend.mobilePhone];

        const filteredPhoneNumberArray = phoneNumberArray.filter(x => x != null && x != "") as string[];

        return filteredPhoneNumberArray;
    }

    get cleanedPhoneNumberLink(): string {
        return this.sanitizer.sanitize(SecurityContext.URL, `call:${this.trimmedPhoneNumber}`);
    }

    get trimmedPhoneNumber() {
        let phoneNumber = this.formattedPhoneNumber;
        while (phoneNumber.indexOf(' ') > -1) {
            phoneNumber = phoneNumber.replace(' ', '');
        }
        return phoneNumber;
    }

    get formattedPhoneNumber(): string {
        return StringUtilities.formatPhoneNumber(this.phoneNumber);
    }

    ngOnInit(): void {
        this.defaultEmail = this.configurationService.email;
        this.defaultPhoneNumber = this.configurationService.phoneNumber;
        this.case = this.route.parent.snapshot.data.case;
        this.setForm();
        this.formContact.controls.contact.setValue(ContactType.Email);
        if (this.route.snapshot.queryParams.mode === 'faq') {
            this.isEnquiriesShown = true;
            this.isFAQsShown = false;
        }

        this.govService.bankHolidays().subscribe(result => {
            this.bankHolidays = result['england-and-wales'].events.map(x => x.date);
        });

        this.isTimeSlotUnavailable = !this.case.version || this.case.version === 0 ? true : false;

        this.maxDate.setDate(new Date().getDate() + 7);
    }

    setForm() {
        this.phoneNumberArray = this.phoneNumbers();

        this.form = this.fb.group({
            'enquiryType': [null, Validators.required],
            'notes': [null, Validators.required]
        });

        this.formContact = this.fb.group({
            'date': [null, null],
            'time': [null, null],
            'contact': ['Email', Validators.required],
            'contactDetails': [this.email, Validators.required]
        });
    }

    openTrustPilot() {
        this.appInsights.logComplexEvent('Trustpilot review started', {
            'case': this.case.id
        });
        window.open('https://uk.trustpilot.com/evaluate/www.minsterlaw.co.uk', '_blank');
    }

    onShowEnquiries() {
        this.formTab = FormTab.EnquiryType;
        this.isEnquiriesShown = true;
        this.faqResult = null;
    }

    onCloseEnquiries() {
        this.isEnquiriesShown = false;
        this.form.reset();
        this.setForm();
        this.formContact.controls.contact.setValue(ContactType.Email);
        this.submitted = false;
        this.submitting = false;
    }

    onBackEnquiries() {
        this.formTab = FormTab.EnquiryType;
    }

    onSubmitEnquiryType(form: UntypedFormGroup) {
        this.submitted = true;
        if (!form.valid) { return; }

        this.equiryModel = new EnquiryModel();
        this.equiryModel.type = this.form.get('enquiryType').value;
        this.equiryModel.notes = this.form.get('notes').value;
        this.equiryModel.caseReference = this.case.reference;

        this.formTab = FormTab.Contact;
        this.submitted = false;
        this.cdRef.detectChanges();
    }

    onSubmitContact(form: UntypedFormGroup) {
        this.submitted = true;
        if (!form.valid) { return; }

        const contact = form.value.contact;

        if (contact === ContactType.Email) {
            this.equiryModel.email = form.value.contactDetails;
            this.equiryModel.phone = undefined;
            this.equiryModel.contactDate = undefined;
        } else if (contact === ContactType.Phone) {
            this.equiryModel.phone = form.value.contactDetails;
            const contactDate = new Date(form.value.date);
            const time: any = form.value.time === 'AM' ? morning : form.value.time === 'PM' ? afternoon : evening;
            contactDate.setHours(time.split(':')[0]);
            contactDate.setMinutes(time.split(':')[1]);
            this.equiryModel.contactDate = contactDate;
            this.equiryModel.email = undefined;
        }

        this.submitting = true;
        this.formContact.disable();
        this.enquiryService.createEnquiry(this.equiryModel).subscribe(
            result => {
                this.formTab = FormTab.Confirmation;
            },
            error => {
                this.formContact.enable();
                this.submitting = false;
            }
        );
    }

    onContactChange() {
        const contactType = this.formContact.value.contact;
        const clearTimeDateValidators = () => {
            this.formContact.controls.date.clearValidators();
            this.formContact.controls.time.clearValidators();
            this.formContact.controls.date.setValue(null);
            this.formContact.controls.time.setValue(null);
        };

        if (contactType === ContactType.Email) {
            this.formContact.controls.contactDetails.setValue(this.email);
            clearTimeDateValidators();
        } else if (contactType === ContactType.Phone) {
            this.formContact.controls.contactDetails.setValue(this.phoneNumberArray[0]);
            if (!this.isTimeSlotUnavailable) {
                this.formContact.controls.date.setValidators(Validators.required);
                this.cdRef.detectChanges();
                this.formContact.controls.time.setValidators(Validators.required);
            }
        }

        this.cdRef.detectChanges();
    }

    datePickerFilter = (d: Date | null): boolean => {
        // disable weekends
        const day = (d || new Date()).getDay();
        if (day === 0 || day === 6) {
            return false;
        }

        // disable bank holidays
        let monthString = (d.getMonth() + 1).toString();
        if (monthString.length < 2) { monthString = '0' + monthString; }
        let dayString = d.getDate().toString();
        if (dayString.length < 2) { dayString = '0' + dayString; }
        const date = `${d.getFullYear()}-${monthString}-${dayString}`;

        if (this.bankHolidays.find(x => x === date) !== undefined) {
            return false;
        }

        // disable past dates
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
        if (d < yesterday) {
            return false;
        }

        return true;
    }

    onDateChange() {
        const date = this.formContact.value.date as Date;
        const isCurrentDay = date.toISOString().substr(0, 10) === new Date().toISOString().substr(0, 10);
        if (!isCurrentDay) {
            this.timeOptions.forEach(x => x.disabled = false);
            this.cdRef.detectChanges();
            return;
        }

        const currentHour = new Date().getHours();
        this.timeOptions.forEach(x => {
            if (currentHour >= 9 && x.time === 'AM') {
                x.disabled = true;
            } else if (currentHour >= 12 && x.time === 'PM') {
                x.disabled = true;
            } else if (currentHour >= 17) {
                x.disabled = true;
            }
        });

        this.cdRef.detectChanges();
    }

    onEnquiryTypeChange() {
        if (!this.isFAQsShown) { return; }
        const question = this.form.value.enquiryType;
        this.faqService.ask(question).subscribe(
            result => {
                const faqResult = result;
                if (faqResult.answers.length > 3) {
                    faqResult.answers = faqResult.answers
                        .sort((a, b) => a.score > b.score ? -1 : 1)
                        .slice(3);
                }

                this.faqResult = faqResult;
            }
        );
    }

    onSentTrigger(triggerArg: string) {
        this.hjService.hj('trigger', triggerArg);
    }
}
