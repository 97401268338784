import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { WelcomePackAuditService } from './welcome-pack-audit.service';
import { DocumentModel } from '../models/document.model';
import { Appointment } from '../models/medical-appointment.model';
import { CaseModel } from '../models/case.model';
import { AppInsightsService } from './appinsights.service';
import { OnboardingService } from './onboarding.service';
import { GetUserResultModel } from '../models/me.model';
import { MilestoneModel } from '../models/milestone.model';

const APPOINTMENT_THRESHOLD_MILLISECONDS = 72 * 60 * 60 * 1000; // 72 Hours
const NO_LOSS_UPLOAD_MILLISECONDS = 10 * 24 * 60 * 60 * 1000; // 10 Days
const LAST_LOSS_UPLOAD_MILLISECONDS = 21 * 24 * 60 * 60 * 1000; // 21 Days
const ACTIVE_MEDICAL_MILLISECONDS = 7 * 24 * 60 * 60 * 1000; // 7 Days

export enum InterruptType {
    None = 0,
    WelcomePack = 1,
    MedicalReportReview = 2,
    AppointmentReminder = 4,
    LossReminder = 8,
    RecentLossReminder = 16,
    RegistrationLossReminder = 32,
    ElapsedMedicalReportReview = 64,
    ClaimSettled = 128,
    ReadOnly = 256,
    Milestones = 512
}

export interface InterruptDetail {
    type: InterruptType;
    additionalInfo: DocumentModel | Appointment | null;
}

@Injectable({
    providedIn: 'root'
})
export class InterruptService {

    constructor(
        private storage: StorageService,
        private onboardingService: OnboardingService,
        private welcomePack: WelcomePackAuditService,
        private appInsights: AppInsightsService
    ) { }

    getNextInterrupt(_case: CaseModel, documents: DocumentModel[], appointments: Appointment[], milestones: MilestoneModel[]): InterruptDetail {

        const now = Date.now();
        const user = JSON.parse(this.storage.getItem('user')) as GetUserResultModel;

        if (_case.readOnly) {
            return { type: InterruptType.ReadOnly, additionalInfo: null };
        }

        if (!this.onboardingService.isOnboardingCompleted(_case.id)) {
            return { type: InterruptType.None, additionalInfo: null };
        }

        // If the user has not opened any of their welcome pack documents or seen the interrupt then we need to show them it.
        // if (!this.welcomePack.hasViewedAnyDocument(_case.id)) {
        //    if (!this.hasViewedInterrupt(_case.id, { type: InterruptType.WelcomePack, additionalInfo: null })) {
        //        this.appInsights.logComplexEvent("Interrupt shown", { case: _case.id, interrupt: "Welcome Pack" });
        //        return { type: InterruptType.WelcomePack, additionalInfo: null };
        //    }
        // }

        // If the client was onboarded before milestones, might ne to show milestones interrupt
        if(milestones && milestones.length > 0 && !this.onboardingService.isOnboardingVersion2Completed(_case.id) && !this.onboardingService.isMilestoneInterruptComplete(_case.id)){
            return { type: InterruptType.Milestones, additionalInfo: null };
        }

        // If the user has a medical report in the recent threshold which needs approving then interrupt them with that.
        /*const medicalReportThreshold = now - ACTIVE_MEDICAL_MILLISECONDS;
        const medicalReports = (documents || []).filter(d => d.group.toLowerCase() === 'medical' && d.state.toLowerCase() === 'received');
        const medicalsToApprove = medicalReports.filter(d => d.requiresApproval && d.status.toLowerCase() === 'new');
        const medicalReportsWithinThreshold = medicalsToApprove.filter(d => new Date(d.createdDate).getTime() > medicalReportThreshold);
        if (medicalReportsWithinThreshold.length > 0) {
            this.appInsights.logComplexEvent('Interrupt shown', { case: _case.id, interrupt: 'Medical Report', medical: medicalReportsWithinThreshold[0].id });
            return { type: InterruptType.MedicalReportReview, additionalInfo: medicalReportsWithinThreshold[0] };
        }*/

        // If the user has a medical report out the recent threshold which needs approving then interrupt them with that.
        /*const medicalReportsOutThreshold = medicalsToApprove.filter(d => new Date(d.createdDate).getTime() <= medicalReportThreshold);
        if (medicalReportsOutThreshold.length > 0) {
            this.appInsights.logComplexEvent('Interrupt shown', { case: _case.id, interrupt: 'Medical Report elapsed time', medical: medicalReportsOutThreshold[0].id });
            return { type: InterruptType.ElapsedMedicalReportReview, additionalInfo: medicalReportsOutThreshold[0] };
        }*/

        // If the user has an upcoming appointment then interrupt them with that.
        const futureAppointmentThreshold = now + APPOINTMENT_THRESHOLD_MILLISECONDS;

        // let newApp = new Date();
        // newApp.setDate(newApp.getDate() + 1);

        // appointments.push( {
        //    expertSurname:'Gilchrist',
        //    expertTitle:"Mr",
        //    addressLine1:"A",
        //    addressLine2:"b",
        //    addressLine3:"c",
        //    addressLine4:"d",
        //    addressLine5:"e",
        //    appointmentDateTime: newApp,
        //    attended:false,
        //    postcode:"DH77JS",
        //    reason:"Medical"
        // });

        /*const imminentAppointments = (appointments || [])
            .filter(a => (now < new Date(a.appointmentDateTime).getTime() && new Date(a.appointmentDateTime).getTime() < futureAppointmentThreshold));*/

        // If the user has an appointment in the next 72 hours that they've not been reminded about then we should tell them about it.
        /*if (imminentAppointments.length > 0) {
            for (let i = 0; i < imminentAppointments.length; i++) {
                const appointment = imminentAppointments[i];
                const appointmentInterrupt: InterruptDetail = { type: InterruptType.AppointmentReminder, additionalInfo: appointment };
                if (!this.isHiddenInterrupt(_case.id, InterruptType.AppointmentReminder)) {
                    this.appInsights.logComplexEvent('Interrupt shown', { case: _case.id, interrupt: 'Appointment' });
                    return appointmentInterrupt;
                }
            }
        }*/

        // If they've not uploaded any losses in the recent threshold since registration then remind them.
        /*const userRegisterThreshold = now - NO_LOSS_UPLOAD_MILLISECONDS;
        const lossDocuments = (documents || []).filter(d => d.group.toLowerCase() === 'losses');
        if (lossDocuments.length === 0 && new Date(user.RegistrationDate).getTime() > userRegisterThreshold && !this.isHiddenInterrupt(_case.id, InterruptType.RegistrationLossReminder)) {
            this.appInsights.logComplexEvent('Interrupt shown', { case: _case.id, interrupt: 'No Losses since registration' });
            return { type: InterruptType.RegistrationLossReminder, additionalInfo: null };
        }*/

        // We only start interrupting the user about losses if they've had a medical or have one booked.
        /*if ((appointments || []).length > 0) {
            if (lossDocuments.length === 0 && !this.isHiddenInterrupt(_case.id, InterruptType.LossReminder)) {
                this.appInsights.logComplexEvent('Interrupt shown', { case: _case.id, interrupt: 'No Losses' });
                return { type: InterruptType.LossReminder, additionalInfo: null }; // If they've not uploaded any then remind them.
            }

            // If they've not uploaded any documents in the recent threshold then remind them.
            const documentThreshold = now - LAST_LOSS_UPLOAD_MILLISECONDS;
            const lossesWithinThreshold = lossDocuments.filter(d => new Date(d.createdDate).getTime() > documentThreshold);
            if (lossesWithinThreshold.length === 0 && !this.isHiddenInterrupt(_case.id, InterruptType.RecentLossReminder)) {
                this.appInsights.logComplexEvent('Interrupt shown', { case: _case.id, interrupt: 'No Recent Losses' });
                return { type: InterruptType.RecentLossReminder, additionalInfo: null };
            }
        }*/

        return { type: InterruptType.None, additionalInfo: null };
    }

    recordInterruptViewed(caseId: string, interrupt: InterruptDetail) {
        const value = this.getInterruptValue(interrupt);
        const interrupts = this.getViewedInterrupts(caseId);
        interrupts.push(value);
        this.saveViewedInterrupts(caseId, interrupts);
    }

    hasViewedInterrupt(caseId: string, interrupt: InterruptDetail) {
        const value = this.getInterruptValue(interrupt);
        const interrupts = this.getViewedInterrupts(caseId);
        return interrupts.indexOf(value) > -1;
    }

    isHiddenInterrupt(caseId: string, interruptType: InterruptType) {
        const value = interruptType;
        const interrupts = this.getHiddenInterrupts(caseId);
        return interrupts.indexOf(value) > -1;
    }

    private instanceOfDocumentModel(object): object is DocumentModel {
        return 'mimeType' in object;
    }

    private instanceOfAppointment(object): object is Appointment {
        return 'appointmentDateTime' in object;
    }

    private getInterruptValue(interrupt: InterruptDetail): string {
        let v = `${interrupt.type}`;
        if (interrupt.additionalInfo) {
            if (this.instanceOfDocumentModel(interrupt.additionalInfo)) {
                v = v + '-document-' + (interrupt.additionalInfo as DocumentModel).id;
            }
            if (this.instanceOfAppointment(interrupt.additionalInfo)) {
                v = v + '-appointment-' + new Date((interrupt.additionalInfo as Appointment).appointmentDateTime).toISOString();
            }
        }
        return v;
    }

    private getStorageKey(caseId: string, type: number): string {
        let storageKey: string;
        if (type === 1) { storageKey = `INTERRUPTS-VIEWED-${caseId}`; }
        if (type === 2) { storageKey = `INTERRUPTS-HIDDEN-${caseId}`; }
        return storageKey;
    }

    private getViewedInterrupts(caseId: string) {
        return JSON.parse(this.storage.getItem(this.getStorageKey(caseId, 1)) || '[]');
    }

    private getHiddenInterrupts(caseId: string) {
        return JSON.parse(this.storage.getItem(this.getStorageKey(caseId, 2)) || '[]');
    }

    private saveViewedInterrupts(caseId: string, interrupts: string[]) {
        const itemsToSave = [...new Set(interrupts)];
        this.storage.setItem(this.getStorageKey(caseId, 1), JSON.stringify(itemsToSave));
    }
}
