<div class="u-mtop-sm">
    <h2 class="c-heading c-heading-uppercase">My losses</h2>
    <div *ngIf="case.readOnly" id="loss-instructions">
        <p class="c-text-width">Your account is now read only.</p>
        <p class="c-text-width">This means that you can still view and download any documents already on your portal, but you are unable to upload any new documents to us, and we won't upload any more to you. Your file handler will personally send anything to you. If you have outstanding documents to approve, please <a disableTabOnModal routerLink="../../contact" class="c-text-link c-text-link--white underline">contact your handler</a> with feedback.</p>
    </div>
    <ng-container *ngIf="!case.readOnly">
        <p class="c-text-width">
            Use our losses functionality to provide us with evidence of any losses incurred as a result of your personal accident.
        </p>
        <p class="c-text-width">
            Details of all your recorded losses can be accessed below.
        </p>
        <p class="c-text-width" *ngIf="!lossDocuments || lossDocuments.length === 0" id="no-losses">You've not recorded any losses yet, to begin click <minster-link (click)="addLoss()" text="add a loss." underline="true"></minster-link></p>
        <p class="c-text-width">To learn more about losses visit our <a disableTabOnModal routerLink="../../faqs" [queryParams]="{q: 'What is a loss?'}" class="c-text-link c-text-link--white underline">FAQs centre</a>.</p>
    </ng-container>
</div>

<minster-button *ngIf="!case.readOnly" buttonClass="button-full" (click)="addLoss()" isPrimary="true" text="Add Loss" [disabled]="isSettled" [isLong]="true"></minster-button>

<ng-container *ngIf="lossDocuments && lossDocuments.length > 0">

    <div class="c-filter-wrapper">
        <div class="c-text-white c-button-circle-label">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">
                    Filter by:
                </font>
            </font><span class="c-text-uppercase c-text-bold"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{selectedFilter.name}}</font></font></span>
        </div>
        <div class="c-button-circle-spacing" aria-label="Filter your losses">
            <ul class="c-text-list-style-none u-flex-row u-margin-none">
                <li *ngFor="let filter of filters;let i = index" [attr.id]="'loss-type-tab-' + i" class="c-button-circle-list  u-margin-none c-button-circle-filter-width">
                    <div class="u-flex">
                        <div class="c-button-circle-wrapper">
                            <button [attr.aria-label]="'Filter by ' + filter.name" [attr.aria-selected]="filter.name === selectedFilter.name" class="c-button-circle" disableTabOnModal [ngClass]="{'c-button-circle--active' : filter.name === selectedFilter.name}" (click)="filterBy(filter)">
                                <span class="u-hide-content"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Filter by all</font></font></span>
                                <span class="c-ic c-ic--sm c-ic-vertical">
                                    <i class="c-ic-font-awesome fas fa-{{filter.icon}}" [ngClass]="{'c-ic-font-awesome--white' : filter.name === selectedFilter.name, 'c-ic-font-awesome' : filter.name !== selectedFilter.name}"></i>
                                </span>
                            </button>
                            <div class="c-button-circle__text"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{filter.name}}</font></font></div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class=" c-dropdown-filter-layout u-mtop u-mtop--md">
            <label for="sortby" class="u-text-bold u-hide-content"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Sort by</font></font></label>
            <div class="">
                <label _ngcontent-cle-c92="" for="sortby" class="u-text-bold u-text-white filter-label" id="filter-label">Sort by loss date:</label>
                <select aria-label="Sort your losses" disableTabOnModal [value]="sortOrder" (change)='sortBy(sortSelect.value)' class="c-dropdown__select c-dropdown__select--filter" id="sortby" #sortSelect>
                    <option aria-label="Sort by newest first" value="newest"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Newest</font></font></option>
                    <option aria-label="Sort by oldest first" value="oldest"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Oldest</font></font></option>
                </select>
            </div>
        </div>
    </div>

    <div class="c-panel" *ngIf="groupedLosses.length > 0">
        <ul class="c-panel-list" aria-label="Your losses">
            <li loss *ngFor="let lossGroup of pagedLosses; index as i" [losses]="lossGroup" [id]="'loss-group-' + i" [index]="i"></li>
        </ul>
        <pager class="u-flex-center u-mtop-sm u-mbot-md" [page]="page" [numberOfItems]="groupedLosses.length" [pageSize]="pageSize" (next)="page = page + 1" (back)="page = page - 1"></pager>
    </div>

    <div class="u-mtop-md" *ngIf="groupedLosses.length === 0">
        <p class="c-text-width">You have no losses which match your selected criteria.</p>
    </div>

    <div class="u-mtop-md" *ngIf="legacyLosses.length > 0"  aria-label="Your older losses">
        <div class="c-banner">
            <h2 class="c-heading u-flex-col c-banner__content u-mtop-ism u-mleft-ism">Changes <span class="c-heading c-heading-light">We have recently refreshed our customer portal to provide a more detailed view of your losses. Losses previously uploaded can be found listed below.</span></h2>
        </div>

        <table class="c-table">
            <thead role="rowgroup">
                <tr>
                    <th role="columnheader">Name</th>
                    <th role="columnheader">Date Uploaded</th>
                    <th role="columnheader" class="text-center-desktop">Download</th>
                </tr>
            </thead>
            <tbody role="rowgroup">
                <tr role="row" *ngFor="let loss of pagedLegacyLosses">
                    <td role="gridcell" style="line-break: anywhere;" aria-label="Loss name">{{loss.name}}</td>
                    <td role="gridcell"  aria-label="Loss date">{{loss.uploadedDate | date : 'dd/MM/yyyy'}}</td>
                    <td role="gridcell" class="text-center-desktop document-download-td">
                        <minster-button  aria-label="Download loss evidence" buttonClass="no-margin-desktop loss-download-button" [disabled]="isDownloadingDoc(loss)" [isLoading]="isDownloadingDoc(loss)" (click)="download(loss)" isFull="true" isPrimary="true" text="Download"></minster-button>
                    </td>
                </tr>
            </tbody>
        </table>

        <pager class="u-flex-center u-mtop-sm u-mbot-md" [page]="page" [numberOfItems]="legacyLosses.length" [pageSize]="pageSize" (next)="olderLossesPage = olderLossesPage + 1" (back)="olderLossesPage = olderLossesPage - 1"></pager>
    </div>

</ng-container>
