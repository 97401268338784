import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppUtilities } from 'src/app/app.utility';
import { WelcomePackDocumentModel } from 'src/app/models/document.model';
import { DocumentName, DocumentService } from 'src/app/services/document.service';
import { extension } from 'mime-types';

@Component({
    selector: 'app-welcome-pack',
    templateUrl: './welcome-pack.component.html',
    styleUrls: ['./welcome-pack.component.scss']
})
export class WelcomePackComponent implements OnInit {

    attachments: WelcomePackDocumentModel[];
    content: any;
    error: boolean;

    constructor(
        private route: ActivatedRoute,
        private documentService: DocumentService
    ) { }

    ngOnInit() {
        const reference = AppUtilities.getRouteParam(this.route, 'reference');

        const handleError = () => {
            this.error = true;
        };

        this.documentService.getDocWelcomePack(reference).subscribe(
            result => {
                const welcomePack = result.find(x => x.name == DocumentName.WelcomePack);
                this.attachments = result.filter(x => x.name != DocumentName.WelcomePack);

                this.documentService.decode(welcomePack.content).subscribe(
                    result => {
                        this.content = result.data;
                    },
                    error => handleError()
                );
            },
            error => handleError()
        );
    }

    getTitle(type: DocumentName): string {
        let title = type.toString();
        switch (type) {
            case DocumentName._0CFA:
            case DocumentName._25CFA:
                title = 'Conditional Fee Agreement';
                break;
            case DocumentName.TermsAndConditions:
                title = 'Terms and Conditions';
                break;
            case DocumentName.KeyInformation:
                title = 'Key Information';
                break;
            case DocumentName.KeyInformationv1:
                title = 'Key Informationv1';
                break;
            case DocumentName.KeyInformationv2:
                title = 'Key Informationv2';
                break;
            case DocumentName.KeyInformationv3:
                title = 'Key Informationv3';
                break;
            case DocumentName.KeyInformationv4:
                title = 'Key Informationv4';
                break;
            case DocumentName.TermsAndConditionsv1:
                title = 'Terms and Conditionsv1';
                break;
            case DocumentName.TermsAndConditionsv2:
                title = 'Terms and Conditionsv2';
                break;
            case DocumentName._OCFAv1:
                title = 'CFA Success Fee 0%v1';
                break;
            case DocumentName._25CFAv1:
                title = 'CFA Success Fee 25%v1';
                break;
        }
        return title;
    }

    download(url: string) {
        const reference = AppUtilities.getRouteParam(this.route, 'reference');
        const guid = url.substr(url.lastIndexOf('/') + 1);
        this.documentService.download(guid, reference).subscribe(
            result => {
                const mimeType = 'application/pdf';
                const bytes = AppUtilities.toUTF8Array(result.content);
                const content = new Blob([bytes], { type: mimeType });
                const filename = `${result.name}.${extension(mimeType)}`;
                AppUtilities.saveToFile(content, filename);
            },
            error => this.error = true
        );
    }
}
