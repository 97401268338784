import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AlertsMessageModel } from '../models/alertsMessage.model';
import { AppInsightsService } from './appinsights.service';


declare  var loadService: any;

@Injectable({
  providedIn: 'root'
})
export class StateService {

    constructor(
        private http: HttpClient,
        @Inject('API_BASE_URL') private baseUrl: string,
        private appInsightsService: AppInsightsService
    ) { }


    readOnlyStatus: boolean = false;
    supressOnBoarding: boolean = false;
    reciteShown: boolean = false;

    public setReadOnly(readOnlyStatus) {
        this.readOnlyStatus = readOnlyStatus;
    }

    public getReadOnly() {
        return this.readOnlyStatus;
    }

    public showRecite(): void {
        this.reciteShown = true;
        loadService();
        this.appInsightsService.logEvent('Show Recite')
    }

    public setSupressOnBoarding(state: boolean) {
        this.supressOnBoarding = state;
    }

    public getSupressOnBoarding() {
        return this.supressOnBoarding;
    }

    public GetAlerts(): Observable<Array<AlertsMessageModel>> {
        const url = this.baseUrl + 'alerts/getAllAlerts';
        return this.http.get(url) as Observable<Array<AlertsMessageModel>>;
    }
}
