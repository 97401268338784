import { Component, Input, OnInit } from '@angular/core';
import { CaseModel, ThirdParty } from 'src/app/models/case.model';

@Component({
  selector: 'third-party-details',
  templateUrl: './third-party-details.component.html',
  styleUrls: ['./third-party-details.component.scss']
})
export class ThirdPartyDetailsComponent implements OnInit {

    @Input() case: CaseModel;

    thirdParties: ThirdParty[] = [];

    ngOnInit() {
        window.scrollTo(0, 0);

        // Put primary first using sort
        this.case.thirdParties.sort((a, b) => a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1);

        this.thirdParties = this.case.thirdParties.slice(0, 5);
    }
}

