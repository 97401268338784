<main class="c-login-page">
    <div class="c-login">
        <div unauthenticatedHeader class="c-main-nav-wrapper c-main-nav-wrapper--login"></div>
        <h1 class="c-heading u-mtop-mobile-md">No active cases</h1>
        <p class="c-text-width">
            Sorry, as your case has now moved to our Multitrack team,
            you are unable to register for using the portal, Please contact your file handler.
        </p>
    </div>
    <div unauthenticatedImageContainer></div>
</main>
<footer class="c-footer c-footer--login">
    <ul class="c-footer__content">
        <li class="c-footer__items"><a class="c-text-link c-text-link--white" href="https://www.minsterlaw.co.uk/privacy-notice/" target="_blank">Privacy Notice</a></li>
        <li class="c-footer__items"><a class="c-text-link c-text-link--white" href="https://www.minsterlaw.co.uk/cookie-policy/" target="_blank">Cookie Policy</a></li>
    </ul>
</footer>
