import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export enum PdfFilesType {
    MedicalPreparationGuide = 0,
    NegotiationsGuide = 1
}


@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

    constructor(private http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

    download(type: PdfFilesType): Observable<Blob> {

        const requestOptions: Object = {
            /* other options here */
            responseType: 'blob'
          }

        const url = this.baseUrl + 'fileDownload/get/' + type;
        return this.http.get<Blob>(url, requestOptions);
    }
}

