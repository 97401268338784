import { Component, OnInit } from '@angular/core';
import { ContactModel, ContactService } from 'src/app/services/contact.service';

@Component({
    selector: 'app-no-cases',
    templateUrl: './no-cases.component.html',
    styleUrls: ['./no-cases.component.scss']
})
export class NoCasesComponent {

    contact: ContactModel;

    constructor(
        private contactService: ContactService
    ) {
        this.contact = this.contactService.get();
    }

}
