import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'active-modals',
    templateUrl: './active-modals.component.html',
    styleUrls: ['./active-modals.component.scss']
})
export class ActiveModalsComponent implements OnInit {

    constructor() { }

    @Input() modals: any[];

    ngOnInit(): void {
    }

}
