import { Component, OnInit, ViewChild, ElementRef, Renderer2,  } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppValidators } from 'src/app/app.validator';
import { VerifyModel } from 'src/app/models/me.model';
import { AuthService } from 'src/app/services/auth.service';
import { MeService } from 'src/app/services/me.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { CaseService } from '../../services/case.service';
import { AppInsightsService } from 'src/app/services/appinsights.service';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    host: { 'style': 'width: 100%', 'display': 'block' }
})
export class RegisterComponent implements OnInit {

    form: UntypedFormGroup;
    submitted = false;
    submitting = false;
    requesting = false;
    errors: string[] = [];
    attempts = 3;
    guid = '';
    disableRegistration = false;
    pinSentDialogShown = false;

    accesscodeTextMask = createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        integerLimit: 4,
        allowLeadingZeroes: true
    });

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private meService: MeService,
        private caseService: CaseService,
        private authService: AuthService,
        private appInsightsService: AppInsightsService,
        private renderer: Renderer2,
        private storage: StorageService
    ) {
        this.form = fb.group({
            'accesscode': [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4)])],
            'password': [null, Validators.compose([Validators.required, AppValidators.password])],
            'passwordagain': [null, null]
        }, { validator: AppValidators.passwordmatch });
    }

    private get accessCodeKey(): string {
        return 'ACCESSCODE-REGISTER-' + this.guid;
    }

    ngOnInit() {

        this.guid = this.route.snapshot.params.guid;

        // redirect to login, if already registered
        this.meService.checkCode(this.guid).subscribe(
            result => {
                this.appInsightsService.logCustomEvent('CheckCode', 'succeeded', result.Email);
                if (result.Registered) {
                    this.router.navigate(['/auth/login']);

                } else {
                    // guid not valid due to user clicking on old email on re registration. Redirect to email link expired page
                    if (result.NotValidGuid) {
                        this.router.navigate(['/guid-expired']);
                    } else {

                        // Find if any of accounts cases are read only (Multi Track MT) by looking at cases API
                        // If there is one stop the user Registering and divert to a register forbid message page
                        this.caseService.getReadOnlyStatus(result.Cases).subscribe(
                            casesResult => {
                                const readOnlyCase = casesResult;
                                this.appInsightsService.logCustomEvent('GetReadOnlyStatus', 'succeeded', result.Email);
                                if (readOnlyCase) {
                                    // show forbidden Register Page
                                    this.appInsightsService.logCustomEvent('showCannotRegisterPage', 'succeeded', result.Email);
                                    this.showCannotRegisterPage(true);
                                } else {
                                    const accessCodeSent = JSON.parse(this.storage.getItem(this.accessCodeKey));
                                    if (accessCodeSent === null) {

                                        this.requestAccessCode();
                                        this.requesting = false;
                                    }
                                }

                            },
                            error => {
                                this.appInsightsService.logCustomEvent('GetReadOnlyStatus', 'failed', result.Email);
                            }
                        );
                    }
                }
            }, () => {}
        );
    }

    showCannotRegisterPage(canShowPage: boolean) {
        this.router.navigate(['/forbid_registration']);
    }


    requestAccessCode() {
        if (this.requesting) { return true; }

        this.requesting = true;
        setTimeout(() => {
            this.requesting = false;
        }, 3000);

        this.meService.pinCodeReset(this.guid).subscribe({
            next: (result) => {
                if (result.RegistrationReset) {
                    this.router.navigate(['/registration-expired/' + this.guid]);
                    this.appInsightsService.logCustomEvent(`RequestAccessCodeRegister ${this.guid}`, 'expired', '');
                } else {
                    this.pinSentDialogShown = true;
                    this.storage.setItem(this.accessCodeKey, 'true');
                    this.appInsightsService.logCustomEvent(`RequestAccessCodeRegister ${this.guid}`, 'succeeded', '');
                }
            },
            error: () => {
                this.appInsightsService.logCustomEvent(`RequestAccessCodeRegister ${this.guid}`, 'error', '');
                this.router.navigate(['/bad-link']);
            }
        })
    }


    clear() {
        this.errors = [];
    }

    submit(form: UntypedFormGroup) {
        this.submitted = true;
        if (form.invalid) { return; }
        form.disable();
        this.submitting = true;

        const model: VerifyModel = {
            code: form.value.accesscode,
            password: form.value.password,
            confirmPassword: form.value.passwordagain,
            registrationCode: this.guid,
            transactionId: ''
        };

        this.meService.verify(model).subscribe(
            result => {
                const username = result.Email;
                this.authService.authenticate(result.Email, form.value.password).subscribe(
                    result2 => {
                        this.storage.setItem('token', JSON.stringify(result2));
                        this.storage.setItem('username', username);
                        this.router.navigate(['/']);

                        this.appInsightsService.logCustomEvent('Register', 'succeeded', username);
                    }, () => {
                        form.enable();
                        this.submitting = false;
                    }
                );
            },
            error => {
                form.enable();
                this.submitting = false;
                this.attempts -= 1;
                const response = error ? error.error : undefined;
                if (response && response.Expired) { this.attempts = 0; }
                this.appInsightsService.logCustomEvent('Register', 'failed', null);
                this.errors = ['Please enter a valid access code'];
            }
        );
    }
}
