import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'minster-button',
    templateUrl: './minster-button.component.html',
    styleUrls: ['./minster-button.component.scss']
})
export class MinsterButtonComponent  {

    @Input() text: string;
    @Input() submittingText: string;
    @Input() disabled: boolean;
    @Input() buttonType: string = 'button';
    @Input() id: string;
    @Input() ariaLabel: string;
    @Input('isPrimary') isPrimary: boolean;
    @Input('isLink') isLink: boolean;
    @Input('isInfo') isInfo: boolean;
    @Input('isSuccess') isSuccess: boolean;
    @Input('isWarning') isWarning: boolean;
    @Input('isDanger') isDanger: boolean;
    @Input('isLoading') isLoading: boolean;
    @Input('isLong') isLong: boolean;
    @Input('isFull') isFull: boolean;
    @Input('buttonClass') buttonClass: string;
    @Input('isNoMargin') isNoMargin: boolean;
    @Input('isSmallMarginTop') isSmallMarginTop: boolean;
    @Output() clicked = new EventEmitter();
    @Input('disableTabOnModal') disableTabOnModal: boolean;

    constructor() { }

    wasClicked() {
        if (this.disabled) { return; }
        this.clicked.emit();
    }

    get class(): string {
        const classes = (this.buttonClass || '').split(' ');

        if (this.isSmallMarginTop) {
            classes.push('u-mtop-sm');
        } else {
            classes.push('u-mtop-ism');
        }

        classes.push('c-button--link');
        classes.push('c-button');

        if (this.disabled) {
            classes.push('c-button--disabled');
        }
        if (this.isPrimary) {
            classes.push('is-primary');
        }
        if (this.isDanger) {
            classes.push('c-button--red');
        }
        if (this.isInfo) {
            classes.push('c-button--grey');
        }
        if (this.isWarning) {
            classes.push('c-button--yellow');
        }
        if (this.isLong) {
            classes.push('c-button--long');
        }
        if (this.isFull) {
            classes.push('c-button--full');
        }
        if (this.isNoMargin) {
            classes.push('no-margin');
        }

        return classes.join(' ');
    }
}
