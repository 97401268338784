import { Injectable, EventEmitter, Component } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class ModalA11yService {

    opened = new EventEmitter<any>(true);
    closed = new EventEmitter<any>(true);

    constructor() {

    }

    open(component: any) {
        this.opened.emit(component);
    }

    close(component: any) {
        this.closed.emit(component);
    }
}
