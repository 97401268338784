import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/services/state.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

    constructor(private stateService: StateService) { }

    ngOnInit() {
    }

    loadRecite(): void {
        this.stateService.showRecite();
    }
}


