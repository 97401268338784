import { Component, OnInit } from '@angular/core';
import { ContactModel, ContactService } from 'src/app/services/contact.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MeService } from 'src/app/services/me.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-change-email-send',
  templateUrl: './change-email-send.component.html',
  styleUrls: ['./change-email-send.component.scss']
})
export class ChangeEmailSendComponent implements OnInit {

    email: string;
    contact: ContactModel;
    completed: boolean = false;
    submitted: boolean = false;
    submitting: boolean = false;

    constructor(
        private contactService: ContactService,
        private meService: MeService,
        private router: Router,
        private storage: StorageService,
        private route: ActivatedRoute) {
        this.contact = this.contactService.get();
    }

    ngOnInit() {
        this.email = this.storage.getItem('username');
    }

    onChangeEmail() {
        if (this.email) {
            this.submitting = true;
            this.meService.SendEmailLinkEmail(this.email).subscribe(() => {
                this.completed = true;
                this.submitted = true;
            });
        }
    }

    onReturn() {
        this.router.navigate(['../details'], {relativeTo: this.route});
    }
}
