import {Component, OnInit} from '@angular/core';
import {CaseModel} from '../../models/case.model';
import types from '../../data/loss-types.json';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AddLossRequest, LossService} from '../../services/loss.service';
import {DocumentUploadModel, DocumentUploadRequestModel} from '../../models/document.model';
import {AppUtilities} from '../../app.utility';
import {AppInsightsService} from '../../services/appinsights.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalA11yService} from '../../services/modal-a11y.service';
import {LossUploadModel} from '../../models/loss.model';

const TOTAL_UPLOAD_LIMIT = 30;
const MAX_FILES = Infinity;
const MAX_FILE_SIZE = 1048576 * 10;
const MAX_FILE_SIZE_TOTAL = 1048576 * TOTAL_UPLOAD_LIMIT;

enum DocumentMimeType {
    BMP = 'image/bmp',
    DOC = 'application/msword',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    JPG = 'image/jpg',
    JPEG = 'image/jpg',
    PDF = 'application/pdf',
    PNG = 'image/png',
    TIF = 'font/ttf',
    TIFF = 'font/ttf',
    TXT = 'text/plain',
    XLS = 'application/vnd.ms-excel',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    HEIC = 'image/heic'
}

interface LossType {
    name: string;
    icon: string;
    subTypes: SubLossType[];
}

interface SubLossType {
    name: string;
    icon: string;
}

enum UploadState {
    None = 0,
    ChooseType = 1,
    Upload = 2,
    Describe = 3,
    Complete = 4
}

@Component({
    selector: 'add-loss',
    templateUrl: './add-loss.component.html',
    styleUrls: ['./add-loss.component.scss']
})
export class AddLossComponent implements OnInit {

    lossUploadModel: LossUploadModel = new LossUploadModel;
    case: CaseModel;
    selectedLossType: LossType;
    selectedSubLossType: SubLossType;
    lossTypes: LossType[];
    form: UntypedFormGroup;
    nameForm: UntypedFormGroup;
    state: UploadState = UploadState.None;
    isLoading: boolean;
    submitted: boolean;
    docsToUpload: DocumentUploadModel[] = [];
    maxFileSize = MAX_FILE_SIZE;
    maxFileCount = MAX_FILES;
    uploadLimit = TOTAL_UPLOAD_LIMIT;
    fileErrors: string[];
    documentGroupId: string = null; // If this is present we can't upload anymore.
    isSettled: boolean;
    nameFormSubmitted: boolean;
    currentMaxFileSizeTotal = 0;

    constructor(private modalA11y: ModalA11yService,
                private fb: UntypedFormBuilder,
                private lossService: LossService,
                private appInsights: AppInsightsService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    get canUploadInfinite(): boolean {
        return this.maxFileCount === Infinity;
    }

    get availableSubLossTypes(): SubLossType[] {
        if (!this.selectedLossType) {
            return [];
        }

        return this.selectedLossType.subTypes;
    }

    get uploadNextButtonDisabled(): boolean {
        return this.isLoading || (this.docsToUpload.length === 0 && this.documentGroupId === null);
    }

    get canRemoveDocuments(): boolean {
        return this.documentGroupId === null && !this.isLoading;
    }

    ngOnInit(): void {
        this.lossTypes = types.lossTypes;
        const currentCase = this.route.snapshot.data.case;
        this.isSettled = currentCase.endOfClaim;

        this.form = this.fb.group({
            'date': [new Date().toISOString().substr(0, 10), Validators.compose([Validators.required])],
            'amount': [null, Validators.compose([Validators.required, Validators.min(0.01)])],
            'notes': ['', Validators.compose([Validators.maxLength(500)])]
        });

        this.nameForm = this.fb.group({
            'fileName': ['', Validators.compose([Validators.maxLength(50)])]
        });

        this.lossService.addLossStarted.subscribe((request: AddLossRequest) => {
            this.reset();
            this.case = request.case;
            this.state = UploadState.ChooseType;
            this.appInsights.logComplexEvent('Add loss started', {case: request.case.reference});
            this.modalA11y.open(this);
        });

        this.lossService.addLossCancelled.subscribe(() => {
            this.appInsights.logComplexEvent('Add loss cancelled', {case: this.case.reference});
            this.reset();
            this.modalA11y.close(this);
        });
    }

    closeAndGoToFaqs() {
        this.close();
        const currentCase = this.route.snapshot.data.case;
        this.router.navigate(['/portal', currentCase.reference, 'faqs'], {queryParams: {q: 'What is a loss?'}});
    }

    setLossType(type: LossType) {
        if (this.selectedLossType !== type) {
            this.selectedSubLossType = null;
        }
        this.selectedLossType = type;
        this.appInsights.logComplexEvent('Loss type selected', {case: this.case.reference, type: type.name});
    }

    addAnotherLoss() {
        this.lossService.startAddLoss(this.case);
    }

    setSubLossType(type: SubLossType) {
        if (this.selectedSubLossType === type) {
            this.selectedSubLossType = null;
        } else {
            this.selectedSubLossType = type;
            this.appInsights.logComplexEvent('Subloss type selected', {
                case: this.case.reference,
                sublossType: type.name
            });
        }
    }

    back() {
        switch (this.state) {
            case UploadState.Describe: {
                this.state = UploadState.Upload;
                break;
            }
            case UploadState.Upload: {
                this.state = UploadState.ChooseType;
                break;
            }
            case UploadState.ChooseType: {
                this.state = UploadState.None;
                break;
            }
            default:
                this.state = UploadState.None;
                break;
        }
    }

    close() {
        if (this.isLoading) {
            return;
        }
        this.lossService.cancel(this.case);
        this.reset();
    }

    submit(form: UntypedFormGroup) {
        this.submitted = true;
        if (form.invalid) {
            return;
        }
        form.disable();
        this.isLoading = true;
        this.fileErrors = [];
        this.isLoading = false;
        form.enable();
        this.state = UploadState.Complete;

        this.lossUploadModel.amount = form.value.amount;
        this.lossUploadModel.caseReference = this.case.reference;
        this.lossUploadModel.lossIncurredDate = new Date(form.value.date);
        this.lossUploadModel.notes = form.value.notes;
        this.lossUploadModel.type = this.selectedLossType.name;
        this.lossUploadModel.subType = this.selectedSubLossType.name;
        this.lossUploadModel.evidence = [];

        const evidence = [];
        this.docsToUpload.forEach(doc => {
            doc.contentBase64 = doc.contentBase64.split(',')[1];
            const newUploadModal: any = {};
            newUploadModal.fileContents = doc.contentBase64;
            newUploadModal.fileName = doc.originalFileName;
            newUploadModal.mimeType = doc.mimeType;
            evidence.push(newUploadModal);
        });

        this.lossUploadModel.evidence = evidence;
        this.lossService.createLoss(this.lossUploadModel);
    }

    reset() {
        this.submitted = false;
        this.form.setValue({date: new Date(), amount: 0, notes: null});
        this.case = null;
        this.state = UploadState.None;
        this.docsToUpload = [];
        this.fileErrors = [];
        this.documentGroupId = null;
        this.form = this.fb.group({
            'date': [new Date().toISOString().substr(0, 10), Validators.compose([Validators.required])],
            'amount': [null, Validators.compose([Validators.required, Validators.min(0.01)])],
            'notes': ['', Validators.compose([Validators.maxLength(500)])]
        });
        this.nameForm = this.fb.group({
            'fileName': ['', Validators.compose([Validators.maxLength(50)])]
        });
        this.selectedLossType = null;
        this.selectedSubLossType = null;
        this.currentMaxFileSizeTotal = 0;
    }

    submitDocumentSelection(fileInput: HTMLInputElement) {
        fileInput.click();
    }

    removeFile(doc: DocumentUploadModel) {
        this.docsToUpload.splice(this.docsToUpload.indexOf(doc), 1);
        this.currentMaxFileSizeTotal -= doc.size;
    }

    evidenceFilesChanged(event: InputEvent) {

        const newFiles: FileList = (event.target as any).files;

        this.appInsights.logComplexEvent('Loss files selected', {case: this.case.reference}, {fileCount: newFiles.length});

        this.fileErrors = [];

        Array.from(newFiles).forEach(file => {

            this.currentMaxFileSizeTotal += file.size;

            if (this.currentMaxFileSizeTotal > MAX_FILE_SIZE_TOTAL) {
                this.currentMaxFileSizeTotal -= file.size;
                this.fileErrors
                    .push(`File named ${file.name} wasn't added because it would exceed the maximum upload total of ${this.uploadLimit}Mb`);
                return;
            }


            if (this.docsToUpload.length === this.maxFileCount) {
                this.currentMaxFileSizeTotal -= file.size;
                this.fileErrors.push(`File named ${file.name} wasn't added because you can only add ${this.maxFileCount} per loss`);
                return;
            }

            const fileExtension: string = file.name.substr(file.name.lastIndexOf('.') + 1);

            // validate the file extension
            if (!AppUtilities.isTypeOfEnum(fileExtension.toUpperCase(), DocumentMimeType)) {
                this.currentMaxFileSizeTotal -= file.size;
                this.fileErrors.push(`File named ${file.name} wasn't added because it isn't a valid file type`);
                this.appInsights.logComplexEvent('File not added', {
                    case: this.case.reference,
                    reason: this.fileErrors[this.fileErrors.length - 1]
                });
                return;
            }

            if (file.size > this.maxFileSize) {
                this.currentMaxFileSizeTotal -= file.size;
                this.fileErrors.push(`File named ${file.name} wasn't added because exceeds the maximum file size of ${this.maxFileSize / 1024 / 1024}mb`);
                this.appInsights.logComplexEvent('File not added', {
                    case: this.case.reference,
                    reason: this.fileErrors[this.fileErrors.length - 1]
                });
                return;
            }

            const documentUpload: DocumentUploadModel = {
                content: null,
                isValid: true,
                newFileName: file.name,
                originalFileName: file.name,
                size: file.size,
                type: 'losses',
                displayType: 'Losses',
                mimeType: file.type,
                contentBase64: null
            };

            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = () => {
                documentUpload.contentBase64 = reader.result;
            };

            this.docsToUpload.push(documentUpload);
        });
    }

    uploadSubmit(form: UntypedFormGroup) {

        this.fileErrors = [];

        if (this.documentGroupId) { // Already uploaded.
            this.state = UploadState.Describe;
            return;
        }

        this.nameFormSubmitted = true;

        if (form.invalid) {
            return;
        }

        this.isLoading = true;

        form.disable();

        const uploadRequestList: DocumentUploadRequestModel[] = [];

        let i = 1;

        for (const documentUpload of this.docsToUpload) {

            const originalFilenameExtension = documentUpload.originalFileName.split('.').pop();

            const fileName = form.value.fileName.length === 0 ? documentUpload.newFileName : `${form.value.fileName}-${i}.${originalFilenameExtension}`;

            uploadRequestList.push({
                content: documentUpload.content,
                fileName: fileName
            });

            i++;
        }

        this.isLoading = false;
        this.state = UploadState.Describe;
    }
}
