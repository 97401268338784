import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'pager',
    templateUrl: './pager.component.html',
    styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit {

    @Input('pageSize') pageSize: number;
    @Input('numberOfItems') numberOfItems: number;
    @Input('page') page: number;

    @Output('next') onNext = new EventEmitter<number>();
    @Output('back') onBack = new EventEmitter<number>();

    constructor() { }

    ngOnInit(): void {
    }

    get totalPages(): number {
        if (this.pageSize === 0 || this.numberOfItems === 0) {
            return 0;
        }

        return Math.ceil(this.numberOfItems / this.pageSize);
    }

    next() {
        if (this.page < this.totalPages) {
            this.page += 1;
            this.onNext.emit(this.page);
        }
    }

    back() {
        if (this.page > 1) {
            this.page -= 1;
            this.onBack.emit(this.page);
        }
    }

    get backEnabled(): boolean {
        return this.page > 1;
    }

    get nextEnabled(): boolean {
        return this.page < this.totalPages;
    }
}
