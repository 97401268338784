import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { MenuItemType, MenuService } from '../services/menu.service';
import { ModalModel, ModalService, ModalStatus } from '../services/modal.service';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
    providedIn: 'root'
})
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

    constructor(
        private modalService: ModalService,
        private menuService: MenuService
    ) { }

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        // if there are no pending changes, just allow deactivation; else confirm first
        if (this.modalService.canDeactivate) {
            return true;
        }

        let subject = new Subject<boolean>();

        const modal: ModalModel = {
            title: 'Warning',
            content: `You have selected documents to upload but have not completed the upload process. If you select "Continue" the documents will be deselected and not uploaded to your claim.`,
            submitButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            htmlText: null
        };

        this.modalService.toggleSubject.next(modal);

        this.modalService.toggleSubject.subscribe(
            result => {
                if (result === ModalStatus.Reject) {
                    subject.next(false);
                    this.menuService.toggleMenuItemSubject.next(MenuItemType.Document);
                } else {
                    subject.next(true);
                }
            }
        );

        return subject.asObservable();
    }
}
