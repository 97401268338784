<button disableTabOnModal class="c-panels__style" [ngClass]="{'c-panels__style--active' : expanded}" (click)="openClose()">
    <span class="c-ic c-ic--sm c-ic-vertical c-ic-dark"><i class="c-ic-font-awesome fas" [ngClass]="'fa-' + icon"></i></span>
    <div class="c-panels__title" aria-label="Loss summary">
        {{lossAmount}} : {{lossType}} - {{subLossType}}
        <div class="c-heading c-heading-light c-text-dark u-mtop-xsm incurred"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;" aria-label="Loss date">Incurred: {{lossDate}}</font></font></div>
    </div>
    <span class="c-panel__chevron" *ngIf="expanded">
        <svg class="c-ic c-ic-vertical c-ic-dark c-ic--arrow c-ic--arrow-open"> <use xlink:href="#ic-arrow-down" href="#ic-arrow-down"></use></svg>
    </span>
    <span class="c-panel__chevron" *ngIf="!expanded">
        <svg class="c-ic c-ic-vertical c-ic-dark"> <use xlink:href="#ic-arrow-down" href="#ic-arrow-down"></use></svg>
    </span>
</button>
<div class="c-panels__content" *ngIf="expanded">
    <hr class="c-panels__divider">
    <span class="c-panels__hr"></span>
    <p class="c-text-width c-text-dark uploaded">{{subLossType}} uploaded <ng-container *ngIf="!uploadDateIsTodayOrYesterday">on </ng-container>{{uploadDate.toLowerCase()}}</p>
    <h3 class="c-heading c-heading-dark">Notes</h3>
    <p class="c-text-width c-text-dark notes" aria-label="Loss notes">{{losses[0].notes || 'N/A'}}</p>
    <div class="c-table-list">
        <ul class="c-table-list__content loss-documents">
            <li style="border-bottom: none;" class="c-table-list__item c-table-list__item--no-border c-table-list__item--no-bot-border u-flex-col loss-document" *ngFor="let loss of losses; let i = index;">
                <h2 class="c-heading c-heading-dark" [id]="'loss-' + index + '-name-' + i">{{loss.name}}</h2>
                <minster-button  aria-label="Download loss evidence" [id]="'loss-' + index + '-download-button-' + i" [disabled]="isDownloadingDoc(loss)" [isLoading]="isDownloadingDoc(loss)" (click)="download(loss)" isPrimary="true" isFull="true" text="Download" ariaLabel="download-{{i}}"></minster-button>
            </li>
        </ul>
    </div>
</div>
