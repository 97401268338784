import { Component, Input } from '@angular/core';

@Component({
    selector: 'minster-validation',
    templateUrl: './minster-validation.component.html',
    styleUrls: ['./minster-validation.component.scss']
})
export class MinsterValidationComponent {

    @Input() messages: string[];
    @Input() level: string = 'danger';
    @Input() id: string;

    constructor() { }

}
