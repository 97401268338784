import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CaseModel } from '../../models/case.model';
import { LinkModel, MilestoneModel } from '../../models/milestone.model';
import { LossLegacyService } from '../../services/loss-legacy.service';
import { MilestoneService } from '../../services/milestone.service';
import { ModalA11yService } from '../../services/modal-a11y.service';

@Component({
  selector: 'app-claim-progress',
  templateUrl: './claim-progress.component.html',
  styleUrls: ['./claim-progress.component.scss']
})
export class ClaimProgressComponent implements OnInit {

    @Input('case') case: CaseModel;
    @Input('milestones') milestones: MilestoneModel[];

    completedMilestones: MilestoneModel[] = [];
    aheadMilestones: MilestoneModel[] = [];
    currentMilestone: MilestoneModel = new MilestoneModel();
    milestone: MilestoneModel = new MilestoneModel();
    isPanelOpen: boolean;
    isTimeLine: boolean = true;
    isClaimProgressCompleted: boolean;
    isAheadMilestonesEmpty: boolean;
    detailsIndex: number;

    constructor(
        private router: Router,
        private milestoneService: MilestoneService,
        private modalA11y: ModalA11yService,
        private lossService: LossLegacyService
    ) { }

    ngOnInit(): void {
        this.completedMilestones = this.milestones.filter(x => x.completed == true);
        this.aheadMilestones = this.milestones.filter(x => x.completed == false);

        this.isClaimProgressCompleted = this.aheadMilestones.length == 0 ? true : false;
        this.isAheadMilestonesEmpty = this.aheadMilestones.length <= 1 ? true : false;

        if (this.aheadMilestones.length > 0) {
            this.currentMilestone = this.aheadMilestones.shift();
            this.milestone = this.currentMilestone;
        } else {
            this.milestone = this.milestones[this.milestones.length - 1];
        }

        this.milestoneService.viewTimelineStarted.subscribe(x => {
            let index: number = this.milestones.length - 1;
            var element = document.getElementById("current-milestone");
            var bottomElement = document.getElementById("milestone-" + index.toString());

            if (element) element.scrollIntoView();
            else if (bottomElement) bottomElement.scrollIntoView();

            this.isPanelOpen = true;
            this.modalA11y.open(this);
        });

        this.milestones.forEach(milestone => {
            milestone.internalLinks = [];
            milestone.externalLinks = [];
            milestone.links.forEach(x => {
                if (x.type == 0) milestone.internalLinks.push(x)
                if (x.type == 1) milestone.externalLinks.push(x.url)
            });
        });
    }

    onInfoClick(milestone: MilestoneModel) {
        this.isTimeLine = false;
        this.milestone = milestone;

        this.detailsIndex = this.milestones.findIndex(x => {
            return x == milestone;
        })
    }

    onPreviousMilestoneDescription() {
        if (this.detailsIndex == 0) return;
        this.detailsIndex--;
        this.milestone = this.milestones[this.detailsIndex];
    }

    onNextMilestoneDescription() {
        if (this.detailsIndex == this.milestones.length - 1) return;
        this.detailsIndex++;
        this.milestone = this.milestones[this.detailsIndex];
    }

    onViewInternalLink(internalLink: LinkModel) {
        this.onCancelTimeLine()
        this.isTimeLine = true;
        switch (internalLink.internalLinkType) {
            case 1:
            case 2:
                this.router.navigate(['/portal', this.case.reference, 'case', 'medical']);
                break;
            case 3:
                this.router.navigate(['/portal', this.case.reference, 'documents']);
                break;
            case 4:
                this.router.navigate(['/portal', this.case.reference, 'faqs'], {queryParams: { 'q' : internalLink.url }});
                break;
            case 5://add a loss
                this.lossService.startAddLoss(this.case);
                break;
            case 6://view a loss
                this.router.navigate(['/portal', this.case.reference, 'case', 'losses']);
                break;
            default:
                this.router.navigate(['/portal', this.case.reference, 'case', 'details']);
        }
    }

    onBackDescription() {
        this.isTimeLine = true;
    }

    onCancelTimeLine() {
        this.modalA11y.close(this);
        this.isPanelOpen = false;
    }

    getStatusText(milestone: MilestoneModel) {
        if(milestone == this.currentMilestone) {
            return "IN PROGRESS";
        } else if(milestone.completed){
            return "COMPLETED";
        } else {
            return "PENDING";
        }
    }

    getStatusClass(milestone: MilestoneModel) {
        if(milestone == this.currentMilestone) {
            return "c-heading-pink";
        } else if(milestone.completed){
            return "c-heading-green";
        } else {
            return "c-heading--yellow";
        }
    }
}
