import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MedicalAgencyDetails } from 'src/app/models/medical-agency-details.model';

@Component({
    selector: 'medical-agency-card',
    templateUrl: './medical-agency-card.component.html',
    styleUrls: ['./medical-agency-card.component.scss']
})
export class MedicalAgencyCardComponent implements OnInit, OnChanges {

    @Input() agencyId: number

    agencies: MedicalAgencyDetails[] = [
        { agencyId: 1, name: 'CL MediCall Aid', imagePath: '/images/Cl_medical.png', email: 'admin@clmedicalreports.co.uk', website: 'https://www.clmedaid.co.uk/contact-us/', phone: '01302 552318' },
        { agencyId: 2, name: 'Premex', imagePath: '/images/Premex.png', email: 'mail@premex.com', website: 'https://www.premexservices.co.uk/contact-us/', phone: '01204 478 300' },
        { agencyId: 3, name: 'Speed Medical', imagePath: '/images/SpeedMedical.png', email: 'medco@speedmedical.com', website: 'https://www.speedmedical.com/contact-us', phone: '01772 282425' },
        { agencyId: 4, name: 'SK Medical', imagePath: '/images/SK.png', email: 'team@skmedical.co.uk', website: 'https://www.skmedical.co.uk/contact/', phone: '0151 733 3000' },
        { agencyId: 5, name: 'UKIM', imagePath: '/images/UKIM.png', email: 'info@ukindmed.com', website: 'https://www.ukindmed.com/get-in-touch/', phone: '0333 414 1988' },
        { agencyId: 6, name: 'Premier Medical', imagePath: '/images/Premier.png', email: 'gp.team@premiermedical.co.uk', website: 'https://premiermedical.co.uk/contact-us/', phone: '0345 1300 735' },
        { agencyId: 7, name: 'Mobile Doctors', imagePath: '/images/MobileDoctors.png', email: 'info@mobiledoctors.co.uk', website: 'https://mobiledoctors.co.uk/contact/', phone: '0330 0990 444' },
        { agencyId: 8, name: 'MLA', imagePath: '/images/MedicalLegalAppointments.png', email: 'RTAFF@mla-ltd.co.uk', website: 'https://www.mla-ltd.co.uk/contact-us', phone: '0151 239 1660' },
        { agencyId: 9, name: 'Bodycare', imagePath: '/images/Bodycare.png', email: 'medicals@bodycareclinics.com', website: 'https://www.bodycareclinics.com/contact/index.html', phone: '0333 5772377' },
        { agencyId: 10, name: 'Doctors Chambers', imagePath: '/images/DoctorsChambers.png', email: 'DCML@Doctorschambers.com', website: 'https://www.doctorschambers.co.uk/contact/index.html', phone: '01753 478 458' },
    ]

    agency: MedicalAgencyDetails;

    constructor() { }

    ngOnInit(): void {
        this.agency = this.agencies.find(x => x.agencyId == this.agencyId);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.agency = this.agencies.find(x => x.agencyId == this.agencyId);
    }
}
