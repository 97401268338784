import { Directive, Input } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';

@Directive({
    selector: '[postcodeFormat]',
    host: {
        '(blur)': 'onBlur($event)'
    }
})
export class PostcodeFormatDirective {

    constructor(
        private control: NgControl
    ) { }

    onBlur($event: any) {
        if (!this.control.value) { return; }

        let postcode: string = this.control.value;
        postcode = postcode.trim();

        const hasWhiteSpace = (str: string) => {
            return /\s/g.test(str);
        };

        if (!hasWhiteSpace(postcode)) {
            if (postcode.length > 5) {
                const part1 = postcode.slice(0, postcode.length - 3);
                const part2 = postcode.slice(postcode.length - 3, postcode.length);
                this.control.control.setValue(part1 + ' ' + part2);
            }
        }
    }
}
