import { Component, OnInit, Input, ElementRef } from '@angular/core';

@Component({
    selector: 'show-hide-password-button',
    templateUrl: './show-hide-password-button.component.html',
    styleUrls: ['./show-hide-password-button.component.scss']
})
export class ShowHidePasswordButtonComponent implements OnInit {

    @Input('target') target;

    get isShown(): boolean {
        if (!this.target) {
            return false;
        }

        return this.target.getAttribute('type').toLowerCase() === 'text';
    }

    constructor() { }

    ngOnInit(): void {
    }

    showHide(): void {
        if (this.isShown) {
            this.target.setAttribute('type', 'password');
        } else {
            this.target.setAttribute('type', 'text');
        }
    }
}
