import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
    private configuration: IServerConfiguration;
    constructor(private http: HttpClient) { }

    loadConfig() {
        return this.http.get<IServerConfiguration>('/api/config')
            .toPromise()
            .then(result => {
                this.configuration = result as IServerConfiguration;
            }, error => console.error(error));
    }

    get googleMapsApiKey() {
        return this.configuration.googleMapsApiKey;
    }

    get isCovidContent() {
        return this.configuration.isCovidContent;
    }

    get portal() {
        return this.configuration.portal;
    }

    get portalName() {
        return this.configuration.portalName;
    }

    get email() {
        return this.configuration.email;
    }

    get phoneNumber() {
        return this.configuration.phoneNumber;
    }

    get newSiteNotificationExpiryDate() {
        return this.configuration.newSiteNotificationExpiryDate;
    }

    get publicKey() {
        return this.configuration.publicKey;
    }

    get applicationId() {
        return this.configuration.applicatonId;
    }

    get safariApiKey() {
        return this.configuration.safariApiKey;
    }

    get safariPushIdentifier() {
        return this.configuration.safariPushIdentifier;
    }

    get safariWebServiceUrl() {
        return this.configuration.safariWebServiceUrl;
    }
}

export interface IServerConfiguration {
    googleMapsApiKey: string;
    isCovidContent: boolean;
    email: string;
    phoneNumber: string;
    portal: string;
    portalName: string;
    newSiteNotificationExpiryDate: string;
    publicKey: string;
    applicatonId: string;
    safariApiKey: string;
    safariPushIdentifier: string;
    safariWebServiceUrl: string;
}
