import { Component, OnInit } from '@angular/core';
import { CaseModel } from 'src/app/models/case.model';
import { AppUtilities } from 'src/app/app.utility';
import { ActivatedRoute } from '@angular/router';
import { CaseService } from 'src/app/services/case.service';
import { AppInsightsService } from 'src/app/services/appinsights.service';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { Subscription } from 'rxjs';

export enum TabType {
    Personal = 'Personal',
    Accident = 'Accident',
    ThirdParty = 'Third party',
    Injury = 'Injury'
}

class TabItem {
    title: string;
    type?: TabType;
    icon: string;
    active?: boolean = false;
    hover?: boolean = false;
    id: string;
}

@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

    TabType = TabType;
    case: CaseModel;
    tabs: TabItem[] = [
        {
            title: 'Personal details',
            icon: '#ic-user',
            type: TabType.Personal,
            hover: false,
            id: 'personalDetails'
        },
        {
            title: 'Accident details',
            icon: '#ic-accident',
            type: TabType.Accident,
            hover: false,
            id: 'accidentDetails'
        },
        {
            title: 'Third party details',
            icon: '#ic-users',
            type: TabType.ThirdParty,
            hover: false,
            id: 'thirdPartyDetails'
        },
        {
            title: 'Injury details',
            icon: '#ic-injury',
            type: TabType.Injury,
            hover: false,
            id: 'injuryDetails'
        }
    ];
    active: TabType = TabType.Personal;

    constructor(
        private route: ActivatedRoute,
        private appInsightsService: AppInsightsService
    ) { }

    ngOnInit() {
        this.case = this.route.parent.snapshot.data.case;

        // Need to log the current tab
        const currentTab = this.tabs.find(x => x.type === this.active);
        this.appInsightsService.logPageView(currentTab.title, this.route.snapshot['_routerState'].url);
    }

    toggle(tab: TabItem) {
        this.active = tab.type;
        this.appInsightsService.logPageView(tab.title, this.route.snapshot['_routerState'].url);
    }
}
