import { Component, OnInit, Input } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'minster-footer,[minster-footer]',
    templateUrl: './minster-footer.component.html',
    styleUrls: ['./minster-footer.component.scss']
})
export class MinsterFooterComponent implements OnInit {

    portal: string;
    @Input('minster-footer') minsterFooter;

    constructor(private config: ConfigurationService) { }

    ngOnInit(): void {
        this.portal = this.config.portal;
    }

}
