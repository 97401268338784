import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, EventEmitter } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { NotificationModel, NotificationSaveModel, NotificationDeleteModel } from '../models/notification.model';
import { tap, catchError, retry } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class NotificationService implements Resolve<NotificationModel[]> {

    updated = new EventEmitter();

    constructor(
        private http: HttpClient,
        @Inject('API_BASE_URL') private baseUrl: string
    ) { }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'An error occurred; please try again later.');
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): NotificationModel[] | Observable<NotificationModel[]> | Promise<NotificationModel[]> {
        return this.get(route.parent.params.reference);
    }

    get(caseReference: string): Observable<NotificationModel[]> {

        const url = this.baseUrl + 'notification/byclaimid/' + caseReference;

        return this.http.get<NotificationModel[]>(url, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                retry(3),
                catchError(this.handleError)
            )
    }

    getIsFirstNotification(email: string) {
        const url = this.baseUrl + 'me/isfirsttimenotification/' + email;
        return this.http.post<boolean>(url, {});
    }

    postIsFirstNotification(email: string) {
        const url = this.baseUrl + 'me/updatefirsttimenotification/' + email;
        return this.http.post(url, {});
    }

    deleteNotification(model: NotificationModel) {

        const payload: NotificationDeleteModel = new NotificationDeleteModel();
        payload.caseReference = model.caseReference;
        payload.notificationObject = model.notificationObject;
        payload.notificationObjectId = model.notificationObjectId;

        const url = this.baseUrl + 'notification/delete';
        this.http.post(url, payload).subscribe(() => {
            this.updated.emit();
        });
    }

    markAsRead(model: NotificationModel) {
        const payload: NotificationSaveModel = new NotificationSaveModel();
        payload.caseReference = model.caseReference;
        payload.dateCreated = model.dateCreated;
        payload.isRead = true;
        payload.addToDailyEmail = model.addToDailyEmail;
        payload.name = model.name;
        payload.notificationObject = model.notificationObject;
        payload.notificationObjectId = model.notificationObjectId;
        payload.type = model.typeName;
        payload.id = model.id;

        const url = this.baseUrl + 'notification/setasread';
        this.http.post(url, payload).subscribe(() => {
            this.updated.emit();
        });
    }
}
