import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { DocumentModel } from '../../models/document.model';
import { DocumentService } from '../../services/document.service';
import { AppUtilities } from '../../app.utility';
import * as moment from 'moment';
import { AppInsightsService } from '../../services/appinsights.service';
import { CurrencyPipe } from '@angular/common';
import { KeyedCollection } from '../../data/keyedcollection';
import { CalendarFormat } from '../../data/calendar-format';
import mime from 'mime-types';

@Component({
    selector: '[loss-legacy]',
    templateUrl: './loss-legacy.component.html',
    styleUrls: ['./loss-legacy.component.scss']
})
export class LossLegacyComponent {

    @HostBinding('class.c-panels__item') isPanelItem: boolean = true;
    @Input('losses') losses: DocumentModel[];
    @Input('index') index: string;
    expanded = false;
    downloadingDocs = new KeyedCollection<boolean>();

    constructor(private appInsights: AppInsightsService, private documentService: DocumentService) {
    }

    get icon(): string {
        if (this.losses[0].lossType) {
            var lossType = this.losses[0].lossType.toLowerCase().trim();

            if (lossType === 'vehicle') {
                return 'car';
            }

            if (lossType === 'medical') {
                return 'first-aid';
            }

            if (lossType === 'expenses') {
                return 'wallet';
            }

            if (lossType === 'wellbeing') {
                return 'spa';
            }
        }

        return 'window-close';
    }

    get uploadDateIsTodayOrYesterday(): boolean {
        const dateFormatted = this.uploadDate;
        if (dateFormatted.toLowerCase() === 'yesterday' || dateFormatted.toLowerCase() === 'today') {
            return true;
        }
        return false;
    }

    get uploadDate(): string {
        return moment(this.losses[0].modifiedDate).calendar(null, CalendarFormat);
    }

    get lossType(): string {
        return this.losses[0].lossType || 'N/A';
    }

    get subLossType(): string {
        return this.losses[0].subLossType || 'N/A';
    }

    get lossTypeIcon(): string {
        return "list";
    }

    get lossDate(): string {
        return this.losses[0].lossIncurred ? moment(this.losses[0].lossIncurred).calendar(null, CalendarFormat) : 'Not known';
    }

    get lossAmount(): string {
        const currency = new CurrencyPipe('en_GB', 'GBP');
        return currency.transform(this.losses[0].lossAmount || 0);
    }

    isDownloadingDoc(doc: DocumentModel) {
        return this.downloadingDocs.ContainsKey(doc.id)
    }

    download(loss: DocumentModel) {

        this.appInsights.logComplexEvent('downloadLoss', { loss: loss.id });

        this.downloadingDocs.Add(loss.id, true);

        this.documentService.content(loss.contentUrl).subscribe(
            result => {
                const bytes = AppUtilities.toUTF8Array(result.data);
                const content = new Blob([bytes], { type: mime.lookup(loss.fileExtension) || 'application/pdf' });
                const filename = `${loss.name}.${loss.fileExtension}`;
                AppUtilities.saveToFile(content, filename);
                this.downloadingDocs.Remove(loss.id);
            }, error => {
                this.downloadingDocs.Remove(loss.id);
            }
        );
    }

    openClose(): void {
        this.expanded = !this.expanded;

        const eventName = this.expanded ? 'expandLossPanel' : 'collapseLossPanel';

        this.appInsights.logComplexEvent(eventName, { loss: this.losses[0].id });
    }

}
