import { Injectable } from '@angular/core';

export class ContactModel {
    email: string;
    telephone: string;
    address: string[];
}

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    get(): ContactModel {
        return {
            email: 'xxx.team@minsterlaw.co.uk',
            telephone: '01904 663238',
            address: [
                'Minster Law Solicitors',
                'Kingfisher House',
                'Peel Avenue',
                'Wakefield',
                'WF2 7UA'
            ]
        };
    }

}
