<ng-container *ngIf="!completed; else isCompleted">
    <h1 class="c-heading u-mtop-mobile-md">Forgotten your password?</h1>
    <p class="c-text-width">Please enter your email to reset your password.</p>
    <form [formGroup]="form" (submit)="submit(form)" autocomplete="off" novalidate>
        <div class="c-text-input u-mtop-ism">
            <label for="username" class="u-text-bold c-text-white">Email</label>
            <input class="c-text-input-style" type="text" placeholder="Email" name="username" id="username" [formControl]="form.controls['username']" autocomplete="off" autofocus [ngClass]="{'is-danger' : submitted && form.controls.username.hasError('required')}">
            <ng-container *ngIf="submitted">
                <minster-validation-message *ngIf="form.controls.username.hasError('required')" message="Please enter your email address"></minster-validation-message>
                <minster-validation-message *ngIf="form.controls.username.hasError('email')" message="Please enter a valid email address"></minster-validation-message>
            </ng-container>
        </div>

        <minster-validation [messages]="errors"></minster-validation>
        <minster-button buttonType="submit" [disabled]="submitting" id="logInButton" text="Submit" isPrimary="true" [isLoading]="submitting ? true : false"></minster-button>
        <div class="u-mtop-ism">
            <minster-link underline="true" [mlRouterLink]="['/auth/logout']" [disabled]="submitting" text="Back to login"></minster-link>
        </div>
    </form>
</ng-container>
<ng-template #isCompleted>
    <h1 class="c-heading u-mtop-mobile-md">Reset requested</h1>
    <p class="c-text-width"><strong>Thank you, a password reset email is on its way to your inbox. Please follow the steps in the email to reset your password.</strong></p>
    <p class="c-text-width">You should receive your email within 10 minutes. If you don't:</p>
    <ul>
        <li>
            Check the email you provided is correct, and the one you used to register on the portal. If it is not, go
            <a style="cursor: pointer" (click)="completed = false" class="h-focus-element text-link">
                <span class="h-focus__content" tabindex="-1">back</span>
            </a>
            and amend it
        </li>
        <li>Check your junk email folder</li>
        <li>
            Please contact our technical support line on
            <a href="tel:{{contact.telephone}}" class="c-text-link c-text-link--white underline"><span class="h-focus__content" tabindex="-1">{{contact.telephone}}</span></a>
            for further assistance.
        </li>
    </ul>
    <div class="u-mtop-ism">
        <minster-link underline="true" [mlRouterLink]="['/auth/logout']" [disabled]="submitting" text="Back to login"></minster-link>
    </div>
</ng-template>
