import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CaseModel } from '../models/case.model';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CaseService implements Resolve<CaseModel> {

    private isEndOfClaim: boolean;

    constructor(
        private http: HttpClient,
        @Inject('API_BASE_URL') private baseUrl: string
    ) { }

    setEndOfClaim(isEndOfClaim: boolean) {
        this.isEndOfClaim = isEndOfClaim;
    }

    getEndOfClaim(): boolean {
        return this.isEndOfClaim;
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): CaseModel | Observable<CaseModel> | Promise<CaseModel> {
        return this.get(route.params.reference);
    }

    get(reference: string): Observable<CaseModel> {
        const url = this.baseUrl + 'case/get/' + reference;
        return this.http.get(url, {}) as Observable<CaseModel>;
    }

    all(): Observable<CaseModel[]> {
        const url = this.baseUrl + 'case/get/all';
        return this.http.get(url, {}) as Observable<CaseModel[]>;
    }

    getReadOnlyStatus(references: string[]): Observable<boolean> {
        const url = this.baseUrl + 'case/getReadOnlyStatus/' + references;
        return this.http.get(url, {}) as Observable<boolean>;
    }
}
