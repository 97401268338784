import {Component, OnInit} from '@angular/core';
import {CaseModel} from '../../models/case.model';
import {DocumentService, AddDocumentRequest} from '../../services/document.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AppInsightsService} from '../../services/appinsights.service';
import {DocumentUploadModel, DocumentUploadRequestModel} from '../../models/document.model';
import {AppUtilities} from '../../app.utility';
import {ModalA11yService} from '../../services/modal-a11y.service';

const TOTAL_UPLOAD_LIMIT = 30;
const MAX_FILES = Infinity;
const MAX_FILE_SIZE = 1048576 * 10;
const MAX_FILE_SIZE_TOTAL = 1048576 * TOTAL_UPLOAD_LIMIT;

enum UploadState {
    None = 0,
    ChooseType = 1,
    Upload = 2,
    Describe = 3,
    Complete = 4
}

enum DocumentMimeType {
    BMP = 'image/bmp',
    DOC = 'application/msword',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    JPG = 'image/jpg',
    JPEG = 'image/jpg',
    PDF = 'application/pdf',
    PNG = 'image/png',
    TIF = 'font/ttf',
    TIFF = 'font/ttf',
    TXT = 'text/plain',
    XLS = 'application/vnd.ms-excel',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    HEIC = 'image/heic'
}

@Component({
    selector: 'add-document',
    templateUrl: './add-document.component.html',
    styleUrls: ['./add-document.component.scss']
})
export class AddDocumentComponent implements OnInit {

    case: CaseModel;
    typeForm: UntypedFormGroup;
    nameForm: UntypedFormGroup;
    state: UploadState = UploadState.None;
    isLoading: boolean;
    submittedTypeSelection: boolean;
    submittedNameSelection: boolean;
    docsToUpload: DocumentUploadModel[] = [];
    maxFileSize = MAX_FILE_SIZE;
    uploadLimit = TOTAL_UPLOAD_LIMIT;
    currentMaxFileSizeTotal = 0;
    maxFileCount = MAX_FILES;
    fileErrors: string[];
    documentGroupId: string = null; // If this is present we can't upload anymore.
    fileNameForm: UntypedFormGroup;
    submittedFileName: boolean;

    constructor(private modalA11y: ModalA11yService, private fb: UntypedFormBuilder, private documentService: DocumentService, private appInsights: AppInsightsService) {
    }

    ngOnInit(): void {
        this.documentService.addDocumentStarted.subscribe((request: AddDocumentRequest) => {
            this.reset();
            this.case = request.case;
            this.state = UploadState.ChooseType;
            this.appInsights.logComplexEvent('Add document started', {case: request.case.reference});
            this.modalA11y.open(this);
        });

        this.documentService.addDocumentCancelled.subscribe(() => {
            this.appInsights.logComplexEvent('Add document cancelled', {case: this.case.reference});
            this.reset();
            this.modalA11y.close(this);
        });
    }

    get canUploadInfinite(): boolean {
        return this.maxFileCount === Infinity;
    }

    get uploadNextButtonDisabled(): boolean {
        return this.isLoading || (this.docsToUpload.length === 0 && this.documentGroupId === null);
    }

    get canRemoveDocuments(): boolean {
        return this.documentGroupId === null && !this.isLoading;
    }

    addAnotherDocument() {
        this.documentService.startAddDocument(this.case);
    }

    back() {
        switch (this.state) {
            case UploadState.Describe: {
                this.state = UploadState.Upload;
                break;
            }
            case UploadState.Upload: {
                this.state = UploadState.ChooseType;
                break;
            }
            case UploadState.ChooseType: {
                this.state = UploadState.None;
                break;
            }
            default:
                this.state = UploadState.None;
                break;
        }
    }

    close() {

        if (this.isLoading) {
            return;
        }
        this.documentService.cancel(this.case);
        this.reset();
    }

    submit(form: UntypedFormGroup) {
        this.submittedNameSelection = true;
        if (form.invalid) {
            return;
        }
        form.disable();
        this.isLoading = true;

        this.fileErrors = [];

        this.documentService.commit(
            this.documentGroupId,
            this.case,
            this.typeForm.value.type,
            form.value.name,
            new Date(form.value.date),
            form.value.amount,
            form.value.notes).subscribe(() => {
            this.appInsights.logComplexEvent('Document committed', {
                case: this.case.reference,
                groupId: this.documentGroupId
            });
            this.isLoading = false;
            form.enable();
            this.state = UploadState.Complete;
        }, () => {
            this.fileErrors = ['An error occurred submitting your document. Please try again'];
            this.isLoading = false;
            form.enable();
        });
    }

    reset() {
        this.submittedTypeSelection = false;
        this.submittedNameSelection = false;
        this.submittedFileName = false;
        this.currentMaxFileSizeTotal = 0;
        this.case = null;
        this.state = UploadState.None;
        this.docsToUpload = [];
        this.fileErrors = [];
        this.documentGroupId = null;
        this.typeForm = this.fb.group({
            'type': ['', Validators.compose([Validators.required])]
        });
        this.nameForm = this.fb.group({
            'notes': ['', Validators.compose([Validators.maxLength(500)])]
        });
        this.fileNameForm = this.fb.group({
            'fileName': ['', Validators.compose([Validators.maxLength(50)])]
        });
    }

    submitDocumentSelection(fileInput: HTMLInputElement) {
        fileInput.click();
    }

    submitTypeSelection(form: UntypedFormGroup) {
        this.submittedTypeSelection = true;
        if (form.invalid) {
            return;
        }
        this.state = UploadState.Upload;
    }

    removeFile(doc: DocumentUploadModel) {
        this.docsToUpload.splice(this.docsToUpload.indexOf(doc), 1);
        this.currentMaxFileSizeTotal -= doc.size;
    }

    evidenceFilesChanged(event: InputEvent) {
        const newFiles: FileList = (event.target as any).files;

        this.appInsights.logComplexEvent('Loss files selected', {case: this.case.reference}, {fileCount: newFiles.length});

        this.fileErrors = [];

        Array.from(newFiles).forEach((file: File) => {

            this.currentMaxFileSizeTotal += file.size;

            if (this.currentMaxFileSizeTotal > MAX_FILE_SIZE_TOTAL) {
                this.currentMaxFileSizeTotal -= file.size;
                this.fileErrors
                    .push(`File named ${file.name} wasn't added because it would exceed the maximum upload total of ${this.uploadLimit}Mb`);
                return;
            }

            if (this.docsToUpload.length === this.maxFileCount) {
                this.currentMaxFileSizeTotal -= file.size;
                this.fileErrors.push(`File named ${file.name} wasn't added because you can only add ${this.maxFileCount} per loss`);
                return;
            }

            const fileExtension: string = file.name.substr(file.name.lastIndexOf('.') + 1);

            // validate the file extension
            if (!AppUtilities.isTypeOfEnum(fileExtension.toUpperCase(), DocumentMimeType)) {
                this.currentMaxFileSizeTotal -= file.size;
                this.fileErrors.push(`File named ${file.name} wasn't added because it isn't a valid file type`);
                this.appInsights.logComplexEvent('File not added', {
                    case: this.case.reference,
                    reason: this.fileErrors[this.fileErrors.length - 1]
                });
                return;
            }

            if (file.size > this.maxFileSize) {
                this.currentMaxFileSizeTotal -= file.size;
                this.fileErrors.push(`File named ${file.name} wasn't added because exceeds the maximum file size of ${this.maxFileSize / 1024 / 1024}mb`);
                this.appInsights.logComplexEvent('File not added', {
                    case: this.case.reference,
                    reason: this.fileErrors[this.fileErrors.length - 1]
                });
                return;
            }

            const documentUpload: DocumentUploadModel = {
                content: null,
                isValid: true,
                newFileName: file.name,
                originalFileName: file.name,
                size: file.size,
                type: 'losses',
                displayType: 'Losses',
                mimeType: file.type,
                contentBase64: null
            };

            const reader = new FileReader();
            const fileByteArray = [];

            reader.onload = (file: any) => {
                const arrayBuffer = file.target.result, array = new Uint8Array(arrayBuffer);
                for (let i = 0; i < array.length; i++) {
                    fileByteArray.push(array[i]);
                }

                documentUpload.content = fileByteArray;
            };

            reader.readAsArrayBuffer(file);

            this.docsToUpload.push(documentUpload);
        });
    }

    uploadSubmit(form: UntypedFormGroup) {
        this.fileErrors = [];

        if (this.documentGroupId) { // Already uploaded.
            this.state = UploadState.Describe;
            return;
        }

        this.submittedFileName = true;

        if (form.invalid) {
            return;
        }

        this.isLoading = true;

        form.disable();

        const uploadRequestList: DocumentUploadRequestModel[] = [];

        let i = 1;

        for (const documentUpload of this.docsToUpload) {

            const originalFilenameExtension = documentUpload.originalFileName.split('.').pop();

            const fileName = form.value.fileName.length === 0 ? documentUpload.newFileName : `${form.value.fileName}-${i}.${originalFilenameExtension}`;

            uploadRequestList.push({
                content: documentUpload.content,
                fileName: fileName
            });

            i++;
        }

        const now = new Date();
        const documentName = this.nameForm.value.name || `${this.typeForm.value.type}-${now.getFullYear()}-${now.getMonth()}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}`;

        this.documentService.upload(this.case.reference, this.typeForm.value.type, documentName, uploadRequestList).subscribe(result => {
            this.appInsights.logComplexEvent('Loss files uploaded', {
                case: this.case.reference,
                groupId: result
            }, {fileCount: uploadRequestList.length});
            this.documentGroupId = result;
            this.isLoading = false;
            this.state = UploadState.Describe;
        }, () => {
            this.isLoading = false;
            this.fileErrors.push('An error occurred uploading your documents');
            form.enable();
        });
    }
}
