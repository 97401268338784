<div class="c-appointment-cards" [ngClass]="isSelected ? '' : ' c-appointment-cards--inactive'">
    <div class="c-appointment-cards__date-time">
        <div class="c-appointment-cards__date">{{appointment.appointmentDateTime | date:'mediumDate'}}</div>
        <div class="c-appointment-cards__time">{{appointment.appointmentDateTime | date:'shortTime'}}</div>
    </div>
    <div class="c-appointment-cards__details">
        <a disableTabOnModal class="c-appointment-cards__name" [ngClass]="{'c-appointment-cards__name--inactive' : !isSelected}" (click)="select()">
            <span class="appointment-title" *ngIf="medical.medicalAgencyName && medical.medicalAgencyName.length > 0" [id]="'medical-appointment-' + index + '-appointment-medical-agency-name'" aria-label="Medical agency name"> {{medical.medicalAgencyName}} </span>
            <span class="appointment-title" *ngIf="appointment.expertSurname && appointment.expertSurname.length > 0" [id]="'medical-appointment-' + index + '-appointment-medical-expert-surname'"  aria-label="Medical expert name">{{appointment.expertTitle}} {{appointment.expertSurname}}</span>
        </a>
        <div class="c-text-bold"> Address: <span [id]="'medical-appointment-' + index + '-appointment-address'" class="c-text-normal" [innerHTML]="address"></span></div>
    </div>
    <div class="u-flex-center">
        <ul class="c-text-list-style-none u-flex-row u-mbot-none u-margin-none">
            <li class="c-appointment-cards__actions">
                <div class="c-button-circle-wrapper">
                    <button disableTabOnModal class="c-ic-button" (click)="reschedule()" [attr.aria-label]="'Reschedule appointment ' + appointment.appointmentDateTime">
                        <span class="u-hide-content">Reschedule my appointment</span>
                        <svg [ngClass]="isSelected ? 'c-ic-white' : 'c-ic-grey'" class="c-ic c-ic--md c-ic-vertical"> <use xlink:href="#ic-calendar" href="#ic-calendar"></use></svg>
                    </button>
                    <div [ngClass]="isSelected ? 'c-button-circle__text--white' : 'c-button-circle__text--grey'" class="c-button-circle__text c-button-circle__text--visible">Reschedule</div>
                </div>
            </li>
            <li class="c-appointment-cards__actions">
                <div class="c-button-circle-wrapper">
                    <button disableTabOnModal class="c-ic-button" (click)="directions()"  [attr.aria-label]="'Directions to ' + address">
                        <span class="u-hide-content">Directions</span>
                        <svg [ngClass]="isSelected ? 'c-ic-white' : 'c-ic-grey'" class="c-ic c-ic--md c-ic-vertical c-ic-white"> <use xlink:href="#ic-transport" href="#ic-transport"></use></svg>
                    </button>
                    <div [ngClass]="isSelected ? 'c-button-circle__text--white' : 'c-button-circle__text--grey'" class="c-button-circle__text c-button-circle__text--visible">Directions</div>
                </div>
            </li>
            <li class="c-appointment-cards__actions">
                <div class="c-button-circle-wrapper">
                    <button disableTabOnModal class="c-ic-button" (click)="preparations()"  [attr.aria-label]="'Preparation for appointment ' + appointment.appointmentDateTime">
                        <span class="u-hide-content">Preparation</span>
                        <svg [ngClass]="isSelected ? 'c-ic-white' : 'c-ic-grey'" class="c-ic c-ic--md c-ic-vertical c-ic-white"> <use xlink:href="#ic-document" href="#ic-document"></use></svg>
                    </button>
                    <div [ngClass]="isSelected ? 'c-button-circle__text--white' : 'c-button-circle__text--grey'" class="c-button-circle__text c-button-circle__text--visible">Preparation</div>
                </div>
            </li>
        </ul>
    </div>
</div>
