import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionModel } from '../models/push-notification-model';
import { ConfigurationService } from './configuration.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {

    applicationPublicKey: string;
    applicationId: string;
    safariPushIdentifier: string;

    constructor(
        private configurationService: ConfigurationService,
        private http: HttpClient,
        private storage: StorageService,
        @Inject('API_BASE_URL') private baseUrl: string
    ) {
        this.applicationPublicKey = configurationService.publicKey;
        this.applicationId = configurationService.applicationId;
        this.safariPushIdentifier = configurationService.safariPushIdentifier;
    }

    handleSafariDesktopNotification() {        

        if ('safari' in (window as any) && 'pushNotification' in (window as any).safari) {
            // safari notifications
            console.log('Safari desktop browser - checking permission status.');

            sessionStorage.setItem('pushNotificationPermissionShown', JSON.stringify(true));

            const permissionData = (window as any).safari.pushNotification.permission(this.safariPushIdentifier);

            this.checkSafariRemotePermission(permissionData);
        } else {
            console.log('Not a Safari desktop browser');
        }
    }

    handleWebPushNotification() {

        // web push notifications
        if (!('Notification' in window)) {
            console.log("This browser does not support notifications.");
            return;
        }

        if ('serviceWorker' in navigator) {
            sessionStorage.setItem('pushNotificationPermissionShown', JSON.stringify(true));

            // register the service worker
            navigator.serviceWorker.register('sw-master.js').then(function (registration) {
                console.log('Service worker registration succeeded:', registration);
            }, function (error) {
                console.log('Service worker registration failed:', error);
            });

            // when the service worker is ready then request permission and then subscribe.
            navigator.serviceWorker.ready.then((registration) => {

                // resolves bug in earlier versions of chrome
                const isSupported = this.checkNotificationPromise();

                // request permission
                this.requestWebPushPermission(isSupported).then((permission) => {
                    console.log(permission);
                    if (permission !== 'denied') {
                        this.subscribeToWebPush(registration);
                    }

                });
            });
        } else {
            console.log('Service workers are not supported.');
        }
    }

    // method to handle safari notifications
    checkSafariRemotePermission(permissionData) {

        console.log('Payload', permissionData);

        if (permissionData.permission === 'default') {

            const payload = {
                ApiKey: this.configurationService.safariApiKey,
                ApplicationId: this.configurationService.applicationId,
                "UserId": this.storage.getItem('username')
            };

            console.log('Payload - 2', payload);
            console.log('safariWebServiceUrl', this.configurationService.safariWebServiceUrl);
            console.log('safariPushIdentifier', this.configurationService.safariPushIdentifier);

            // This is a new web service URL and its validity is unknown.
            (window as any).safari.pushNotification.requestPermission(
                this.configurationService.safariWebServiceUrl,
                this.configurationService.safariPushIdentifier,
                payload,
                this.checkSafariRemotePermission // The callback function.
            );
        }
        else if (permissionData.permission === 'denied') {
            // The user said no.
            console.log('permission denied');
        }
        else if (permissionData.permission === 'granted') {
            // The web service URL is a valid push provider, and the user said yes.
            // permissionData.deviceToken is now available to use.
            console.log('permission granted');
            console.log(permissionData.deviceToken);
        }
    }

    requestWebPushPermission(isSupported) {

        return new Promise(function (resolve, reject) {
            if (isSupported) {
                Notification.requestPermission().then((permission) => {
                    console.log('Notification permission status:', permission);
                    resolve(permission);
                }).catch((error) => {
                    reject(error);
                });
            } else {
                Notification.requestPermission((permission) => {
                    resolve(permission);
                }).catch((error) => {
                    reject(error);
                });
            }
        });
    }

    // subscribe to the notification
    subscribeToWebPush(registration) {

        registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(this.applicationPublicKey)
        }).then((subscription) => {
            console.log('Subscription: ', JSON.stringify(subscription));

            this.postWebPushSubscription(subscription).subscribe(result => {
                console.log(result);
            });

        }).catch((e) => {
            if (Notification.permission === 'denied') {
                console.warn('Permission for notifications was denied');
            } else {
                console.error('Unable to subscribe to push', e);
            }
        });
    }

    postWebPushSubscription(subscription): Observable<any> {
        const url = this.baseUrl + 'subscription';

        const username = this.storage.getItem('username');

        const model: SubscriptionModel = new SubscriptionModel();
        model.applicationId = this.applicationId;
        model.userId = username;
        model.subscriptionDetail = JSON.stringify(subscription);

        return this.http.post(url, model) as Observable<any>;
    }

    // check broswer supports the promise version of Notification.requestPermission()
    private checkNotificationPromise() {
        try {
            Notification.requestPermission().then();
        } catch (e) {
            return false;
        }

        return true;
    }

    private urlBase64ToUint8Array = (base64String) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };

}

