import { Injectable, Output, EventEmitter } from '@angular/core';
import { DocumentModel } from '../models/document.model';
import { DocumentService } from './document.service';
import { CaseModel } from '../models/case.model';
import { Observable, from } from 'rxjs';
import { AppInsightsService } from './appinsights.service';

export interface MedicalReportRequest {
    document: DocumentModel;
    case: CaseModel;
}

@Injectable({
    providedIn: 'root'
})
export class MedicalReportService {

    @Output() approvalStarted: EventEmitter<MedicalReportRequest> = new EventEmitter<MedicalReportRequest>();
    @Output() rejectionStarted: EventEmitter<MedicalReportRequest> = new EventEmitter<MedicalReportRequest>();
    @Output() approvalCommitted: EventEmitter<MedicalReportRequest> = new EventEmitter<MedicalReportRequest>();
    @Output() approvalCancelled: EventEmitter<MedicalReportRequest> = new EventEmitter<MedicalReportRequest>();
    @Output() rejectionCommitted: EventEmitter<MedicalReportRequest> = new EventEmitter<MedicalReportRequest>();
    @Output() rejectionCancelled: EventEmitter<MedicalReportRequest> = new EventEmitter<MedicalReportRequest>();

    constructor(private documentService: DocumentService, private appInsights: AppInsightsService) { }

    startApproval(document: DocumentModel, _case: CaseModel) {
        this.appInsights.logComplexEvent('Medical report approval started', { document: document.id, case: _case.reference });
        this.approvalStarted.emit({ document, case: _case });
    }

    commitApproval(document: DocumentModel, _case: CaseModel) {
        this.appInsights.logComplexEvent('Medical report approved', { document: document.id, case: _case.reference });

        const promise = new Promise<DocumentModel>((resolve, reject) => {
            this.documentService.approve({
                id: document.id,
                name: document.name,
                caseReference: _case.reference,
                comment: '',
                type: document.group,
                proclaimId: document.proclaimId
            }).subscribe(result => {
                resolve(result);
                this.approvalCommitted.emit({ document: result, case: _case });
            }, error => {
                reject(error);
            });
        });

        return from(promise);
    }

    startRejection(document: DocumentModel, _case: CaseModel) {
        this.appInsights.logComplexEvent('Medical report rejection started', { document: document.id, case: _case.reference });
        this.rejectionStarted.emit({ document, case: _case });
    }

    cancelRejection(document: DocumentModel, _case: CaseModel) {
        this.appInsights.logComplexEvent('Medical report rejection cancelled', { document: document.id, case: _case.reference });
        this.rejectionCancelled.emit({ document, case: _case });
    }

    cancelApproval(document: DocumentModel, _case: CaseModel) {
        this.appInsights.logComplexEvent('Medical report approval cancelled', { document: document.id, case: _case.reference });
        this.approvalCancelled.emit({ document, case: _case });
    }

    commitRejection(document: DocumentModel, _case: CaseModel, reason: string): Observable<DocumentModel> {

        this.appInsights.logComplexEvent('Medical report rejected', { document: document.id, reason: reason, case: _case.reference });

        const promise = new Promise<DocumentModel>((resolve, reject) => {
            this.documentService.reject({
                id: document.id,
                name: document.name,
                caseReference: _case.reference,
                comment: reason,
                type: document.group,
                proclaimId: document.proclaimId
            }).subscribe(result => {
                resolve(result);
                this.rejectionCommitted.emit({ document: result, case: _case });
            }, error => {
                reject(error);
            });
        });

        return from(promise);
    }
}
