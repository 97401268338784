import { Component, OnInit, OnDestroy, ElementRef, Directive } from '@angular/core';
import { ModalA11yService } from '../../services/modal-a11y.service';

@Directive({
    selector: '[disableTabOnModal]'
})
export class DisableTabOnModalComponent implements OnInit, OnDestroy {

    subscriptions = [];
    openedDialogs = [];

    oldTabIndex: string;

    constructor(private elementRef: ElementRef, private modalA11yService: ModalA11yService) { }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    ngOnInit(): void {

        this.subscriptions.push(this.modalA11yService.closed.subscribe((component: any) => {
            if (this.openedDialogs.indexOf(component) > -1) {
                this.openedDialogs.splice(this.openedDialogs.indexOf(component), 1);
            }

            if (this.openedDialogs.length === 0) {
                if (this.oldTabIndex) {
                    this.elementRef.nativeElement.setAttribute('tabindex', this.oldTabIndex);
                } else {
                    this.elementRef.nativeElement.removeAttribute('tabindex');
                }
            }
        }));

        this.subscriptions.push(this.modalA11yService.opened.subscribe((component: any) => {
            if (this.openedDialogs.length === 0) {
                this.oldTabIndex = this.elementRef.nativeElement.getAttribute('tabindex');
            }
            if (this.openedDialogs.indexOf(component) === -1) {
                this.openedDialogs.push(component);
            }

            this.elementRef.nativeElement.setAttribute('tabindex', '-1');
        }));
    }

}
