import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppInsightsService } from '../../services/appinsights.service';

@Component({
    selector: 'minster-modal',
    templateUrl: './minster-modal.component.html',
    styleUrls: ['./minster-modal.component.scss']
})
export class MinsterModalComponent {

    @Input('show') show: boolean;
    @Input('isMiaModal') isMiaModal: boolean;
    @Input('closeButtonTitle') closeButtonTitle: string;
    @Input('cancelButtonTitle') cancelButtonTitle: string;
    @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private appInsights: AppInsightsService
    ) { }

    buttonClicked(positive: boolean): void {
        this.closed.emit(positive);
        this.show = false;
        this.appInsights.logCustomEvent('modalDismissed', positive ? 'TRUE' : 'FALSE', null);
    }
}
