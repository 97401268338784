import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

@Component({
    selector: 'app-cookie-modal',
    templateUrl: './cookie-modal.component.html',
    styleUrls: ['./cookie-modal.component.scss']
})
export class CookieModalComponent implements OnInit {

    @Output('hideModal') $hideModal: EventEmitter<void> = new EventEmitter();

    constructor(private storage: StorageService) { }

    ngOnInit(): void {
    }

    onDecline(): void {
        this.storage.deleteAllCookies();
        this.storage.setItem('cookieConsent', 'false');
        this.$hideModal.emit();
    }

    onAccept(): void {
        const evt = new Event("cookiePolicyAccepted", { bubbles: true, cancelable: false });
        document.dispatchEvent(evt);

        this.storage.setItem('cookieConsent', 'true');
        this.$hideModal.emit();

        setTimeout(() => {
            location.reload();
         }, 100)
    }
}
