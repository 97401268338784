<section class="l-main-view__sliding-panel" [ngClass]="{'l-main-view__sliding-panel--is-open': isOpen}">
    <div class="l-main-view__sliding-panel__padding">
        <button class="h-focus-element h-margin-tb--sm l-main-view__sliding-panel__close" (click)="dismiss()">
            <span class="h-focus__content" tabindex="-1"><svg class="m-ic"><use xlink:href="#ic-arrow" href="#ic-arrow"></use></svg></span>
        </button>
        <ng-container *ngIf="activeMenuItem == MenuItemType.Notification">
            <h2 class="subheading subheading--light subheading--sm">Notifications</h2>
            <ul class="m-nav m-nav--sec" id="notifications-panel">
                <p *ngIf="!notifications || notifications.length === 0">You don't currently have any notifications</p>
                <li class="m-nav__item" *ngFor="let notification of notifications">
                    <a href="javascript:undefined" class="h-focus-element" (click)="onNotificationClick(notification)">
                        <div class="h-focus__content" tabindex="-1">
                             <div class="l-flex__item">
                                <span class="h-block"><svg class="m-ic m-ic--xxs m-ic--ie-col" style="margin:0 5px 0 0"><use xlink:href="#ic-clock" href="#ic-clock"></use></svg><svg class="m-ic m-ic--xxs  m-ic--ie-white ie-only"><use xlink:href="#ic-clock-white" href="#ic-clock-white"></use></svg> <b>{{notification.dateCreated | date: 'd MMM yy'}}</b></span>
                                <span *ngIf="!notification.isRead" class="h-block"><b>{{notification.portalName}}</b></span>
                                <span *ngIf="notification.isRead" class="h-block">"{{notification.portalName}}"</span>
                            </div>
                        </div>
                    </a>
                    <button class="l-flex__item h-focus-element m-nav__item__close" (click)="onDismissNotification(notification)">
                        <div class="h-focus__content" tabindex="-1">
                            <svg class="m-ic m-ic--xxs m-ic--ie-col"><use xlink:href="#ic-close-purple" href="#ic-close-purple"></use></svg>
                            <svg class="m-ic m-ic--xxs m-ic--ie-white ie-only"><use xlink:href="#ic-close-white" href="#ic-close-white"></use></svg>
                        </div>
                    </button>
                </li>
            </ul>
        </ng-container>
        <ng-container *ngIf="activeMenuItem == MenuItemType.Contact">
            <h2 class="subheading subheading--light subheading--sm">Contact us</h2>
            <ul class="m-list m-list--no-bull m-list--vertical">
                <ng-container *ngIf="fileHandler">
                    <li class="m-list__item m-list__item--spaced">
                            <a href="mailto:{{fileHandler.emailAddress}}" class="h-focus-element"><span class="m-ic__wrapper-circle h-focus__content" tabindex="-1"><svg class="m-ic m-ic--sm h-absolute-center"><use xlink:href="#ic-email" href="#ic-email"></use></svg></span></a>
                            <div style="max-width:15rem">
                                <h3 class="subheading subheading--xs">Email</h3>
                                <a href="mailto:{{fileHandler.emailAddress}}" class="h-focus-element text-link">
                                    <span class="h-focus__content" tabindex="-1" id="file-handler-email-address" style="text-transform:lowercase">{{fileHandler.emailAddress}}</span>
                                </a>
                        </div>
                    </li>
                    <li class="m-list__item m-list__item--spaced">
                            <a href="tel:{{fileHandler.phoneNumber}}" class="h-focus-element"><span class="m-ic__wrapper-circle h-focus__content" tabindex="-1"><svg class="m-ic m-ic--sm h-absolute-center"><use xlink:href="#ic-phone" href="#ic-phone"></use></svg></span></a>
                            <div><h3 class="subheading subheading--xs">Telephone</h3><a href="tel:{{fileHandler.phoneNumber}}" class="h-focus-element text-link">
                                <span class="h-focus__content" tabindex="-1" id="file-handler-telephone">{{fileHandler.phoneNumber}}</span>
                            </a>
                        </div>
                    </li>
                </ng-container>
                <li class="m-list__item m-list__item--spaced">
                        <a href="http://maps.google.com/?q=Minster Law Solicitors Kingfisher House Peel Avenue Wakefield WF2 7UA" target="_blank" class="h-focus-element"><span class="m-ic__wrapper-circle h-focus__content" tabindex="-1"><svg class="m-ic m-ic--sm h-absolute-center"><use xlink:href="#ic-address" href="#ic-address"></use></svg></span></a>
                        <div>
                            <h3 class="subheading subheading--xs">Address</h3>
                            <p>
                                <ng-container *ngFor="let line of contact.address">{{line}}<br /></ng-container>
                            </p>
                    </div>
                </li>
                <div [ngSwitch]="fileHandler.department">
                    <div *ngSwitchCase="'CSC/CST'">
                        <p id="CSC-CST">
                            Our hours of business are: <br />
                            Monday-Friday 8am - 9pm <br />
                            Saturday and Bank Holidays 9am-6pm <br />
                            We will respond to your email enquiry during these hours.
                        </p>
                    </div>
                    <div *ngSwitchCase="'MOJ'">
                        <p id="MOJ">
                            Our hours of business are:<br />
                            Monday-Friday 9am -5pm <br />
                            We will respond to your email enquiry during these hours.
                        </p>
                    </div>
                    <div *ngSwitchCase="'FRC'">
                        <p id="FRC">
                            Our hours of business are: <br />
                            Monday-Friday 9am -5pm <br />
                            We will respond to your email enquiry during these hours.
                        </p>
                    </div>
                    <div *ngSwitchCase="'MT'">
                        <p id="MT">
                            Our hours of business are: <br />
                            Monday-Friday 9am -5pm <br />
                            We will respond to your email enquiry during these hours.
                        </p>
                    </div>

                </div>

            </ul>
        </ng-container>
        <ng-container *ngIf="activeMenuItem == MenuItemType.General">
            <h2 class="subheading subheading--light subheading--sm">General information</h2>
            <ul class="m-list m-list--no-bull m-list--vertical">
                <li class="m-list__item">
                    <a href="https://www.minsterlaw.co.uk/privacy-notice/" (click)="addPageView('Privacy Notice')"
                       target="_blank" class="text-link h-focus-element"><span class="h-focus__content" tabindex="-1">Privacy Notice</span></a>
                </li>
                <li class="m-list__item">
                    <a href="https://www.minsterlaw.co.uk/cookie-policy/" (click)="addPageView('Cookie Policy')"
                       target="_blank" class="text-link h-focus-element"><span class="h-focus__content" tabindex="-1">Cookie Policy</span></a>
                </li>
            </ul>
        </ng-container>
        <h2 class="subheading subheading--light subheading--sm" *ngIf="activeMenuItem == MenuItemType.Claim">Switch claims</h2>
    </div>
    <nav *ngIf="activeMenuItem == MenuItemType.Claim" id="menu">
        <ul class="m-nav m-nav--sec">
            <li class="m-nav__item" *ngFor="let item of cases" [ngClass]="{'m-nav__item--active': item.reference == activeCaseRef}">
                <a [routerLink]="['/portal', item.reference]" class="h-focus-element" (click)="dismiss(MenuItemType.Home)">
                    <div class="h-focus__content" tabindex="-1">
                        <p>
                            <strong class="h-block subheading subheading--xs subheading--uppercase">{{item.client.forename}} {{item.client.surname}}</strong>
                            <span class="h-block"><svg class="m-ic m-ic--xxs"><use xlink:href="#ic-clock" href="#ic-clock"></use></svg> {{item.lastUpdatedDate | date:'dd MMM yy'}}</span>
                            <span class="h-block">Reference no: {{item.reference}}</span>
                        </p>
                    </div>
                </a>
            </li>
        </ul>
    </nav>
</section>
