<button  disableTabOnModal class="c-panels__style" [attr.aria-expanded]="expanded" [ngClass]="{'c-panels__style--active' : !isSimple && expanded, 'c-panels__style--simple--active' : isSimple && expanded, 'c-panels__style--simple': isSimple}" (click)="openClose()" [id]="buttonId">
    <span *ngIf="icon" class="c-ic c-ic--sm c-ic-vertical c-ic-dark"><i class="c-ic-font-awesome fas" [ngClass]="'fa-' + icon"></i></span>
    <span class="c-panels__title">{{heading}}</span>
    <span class="c-panel__chevron" *ngIf="expanded">
        <svg class="c-ic c-ic-vertical c-ic-dark c-ic--arrow c-ic--arrow-open"> <use xlink:href="#ic-arrow-down" href="#ic-arrow-down"></use></svg>
    </span>
    <span class="c-panel__chevron" *ngIf="!expanded">
        <svg class="c-ic c-ic-vertical c-ic-dark"> <use xlink:href="#ic-arrow-down" href="#ic-arrow-down"></use></svg>
    </span>
</button>
<div class="c-panels__content" *ngIf="expanded">
    <hr class="c-panels__divider">
    <span class="c-panels__hr"></span>
    <ng-content></ng-content>
</div>
