<div class="u-mtop-xs">
    <h1 class="c-heading c-heading-uppercase">Frequently asked questions</h1>
    <p class="c-text-width">
        We have used our personal injury expertise to create a handy FAQ centre to guide you through your personal injury claim.
    </p>
    <p class="c-text-width">
        Due to government reforms under the Civil Liability Act 2018, the answer to some of these questions may be different depending on if your accident date was before May 31st 2021 or after. These differences will be clearly signposted in the Q&As affected by these changes
    </p>
</div>

<form [formGroup]="form" (submit)="search()">
    <div class="c-search">
        <input autocomplete="off" id="faq-question" class="c-search--box" type="search" placeholder="Please type your question" [formControl]="form.controls['question']" #questioninput>
        <svg class="c-ic c-ic--sm  c-search--icon" (click)="search()"> <use xlink:href="#ic-search" href="#ic-search"></use></svg>
    </div>
</form>
<div class="c-panel u-mtop-sm" *ngIf="results && results.found; else noResults">
    <ul class="c-panel-list">
        <li class="c-panels__item" *ngFor="let result of results.answers; let i = index;">
            <minster-expandable-panel icon="question-circle" [heading]="result.questions[0]" #answers [id]="'faq-answer-' + i">
                <div class="answer-body c-text-dark" [id]="'faq-answer-body-' + i" [innerHTML]="result.answer"></div>
                <ng-container *ngIf="result.context && result.context.prompts && result.context.prompts.length > 0">
                    <hr />
                    <span class="c-panels__title" [id]="'faq-answer-related-questions-' + i">Related questions</span>
                    <ul class="related-questions">
                        <li *ngFor="let prompt of result.context.prompts; let p = index">
                            <minster-link underline="true" textLinkDark="true" [id]="'faq-answer-related-questions-' + i + '-question-' + p" (click)="promptSelected(prompt.displayText)" [text]="prompt.displayText"></minster-link>
                        </li>
                    </ul>
                </ng-container>
            </minster-expandable-panel>
        </li>
    </ul>
    <!--<div *ngIf="isQuestionAnswered == undefined">
        <p class="u-mbot-none">{{isQuestionAnswered === true ? "Thank you for using our FAQs" : isQuestionAnswered === false ? "We are sorry you couldn’t find what you were looking for, can we help?" : "Did you find an answer to your question?"}}</p>
        <div class="">
            <button class="c-button c-button--fixed c-button--outline-blue" (click)="onQuestionAnswer(true)">Yes</button>
            <button class="c-button c-button--fixed c-button--outline-blue" (click)="onQuestionAnswer(false)">No</button>
        </div>
    </div>-->
    <minster-button buttonClass="is-fullwidth" isPrimary="true" text="Send us a message" *ngIf="isQuestionAnswered==false" [routerLink]="['../contact']" [queryParams]="{mode: 'faq'}"></minster-button>
</div>
<ng-template #noResults>
    <div id="faq-no-results" *ngIf="!form.controls.question.errors && !searching && !typing">
        <hr />
        <p>Sorry - no results matching your search. If your question remains unanswered then please <a class="c-text-link c-text-link--white underline" routerLink="../contact">contact us.</a></p>
    </div>
</ng-template>
