<div class="u-mtop-sm">
    <h2 class="c-heading c-heading-uppercase">My claim details</h2>
    <p class="c-text-width">Here you will find all the information you provided when you registered your claim. If any information is incorrect please <minster-link underline="true" mlRouterLink="../../contact" text="contact us."></minster-link></p>
</div>

<div class="c-panel">
    <ul class="c-panel-list">
        <li minster-expandable-panel [heading]="'Personal details'" id="button-personal-details" icon="user" aria-label="Personal details">
            <personal-details [case]="case"></personal-details>
        </li>

        <li minster-expandable-panel [heading]="'Accident details'" id="button-accident-details" icon="car" aria-label="Accident details">
            <accident-details [case]="case"></accident-details>
        </li>

        <li minster-expandable-panel [heading]="'Injury details'" id="button-injury-details" icon="user-injured" aria-label="injury details">
            <injury-details [case]="case"></injury-details>
        </li>

        <li minster-expandable-panel [heading]="'Other details'" id="button-Other-details" icon="clipboard-list" aria-label="Other details">
            <third-party-details [case]="case"></third-party-details>
        </li>
    </ul>
</div>
