<div class="c-modal" role="alertdialog" *ngIf="isOpen" style="display: block;">
    <div class="c-modal__content">
        <div class="c-modal-nav">
            <div class="c-modal-nav__item--back u-is-hidden" (click)="back()">
                <button class="c-ic-button c-modal-nav__btn-back" role="button" aria-label="back">
                    <svg class="c-ic c-ic--back-modal">
                        <use xlink:href="#ic-back-modal" href="#ic-back-modal"></use>
                    </svg>
                </button>
            </div>
            <div class="c-modal-nav__item--logo">
                <a class="c-modal-nav__link" >
                    <svg class="c-ic-purple c-modal-nav__logo-svg">
                        <use xlink:href="#ic-logo-2" href="#ic-logo-2"></use>
                    </svg>
                </a>
            </div>
            <div class="c-modal-nav__item--close" (click)="buttonClicked(false)">
                <button class="c-ic-button c-modal-nav__btn-close" role="button" aria-label="close">
                    <svg class="c-ic c-ic--sm">
                        <use xlink:href="#ic-close" href="#ic-close"></use>
                    </svg>
                </button>
            </div>
        </div>
        <div class="c-modal__content__body">
            <h3 class="c-heading c-heading-purple" [innerHTML]="title"></h3>
            <div class="c-text-width c-text-dark" [innerHTML]="body">
            </div>
            <div class="u-flex-center  u-mtop-ism">
                <minster-button *ngIf="okButtonTitle && okButtonTitle.length > 0" buttonClass="c-button" isPrimary="true" (click)="buttonClicked(true)" [text]="okButtonTitle"></minster-button>
                <minster-button *ngIf="cancelButtonTitle && cancelButtonTitle.length > 0" buttonClass="c-button" isInfo="true" (click)="buttonClicked(false)" [text]="cancelButtonTitle"></minster-button>
            </div>
        </div>
    </div>
</div>


