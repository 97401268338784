<ng-container *ngIf="pastItems && pastItems.length > 0; else noAppointments">
    <div class="m-box__wrapper m-box-wrapper--half">
        <div class="m-box m-box--tabbed" *ngFor="let item of pastItems; let i = index;">
            <div class="m-box__tab m-box__tab--positive" *ngIf="item.attended"><svg class="m-ic m-ic--sm"><use xlink:href="#ic-check" href="#ic-check"></use></svg></div>
            <div class="m-box__tab m-box__tab--negative" *ngIf="!item.attended"><svg class="m-ic m-ic--sm m-ic--white"><use xlink:href="#ic-close" href="#ic-close"></use></svg></div>
            <div class="m-box__inner">
                <span class="h-block h-text-weight--bold h-margin-bottom"><svg class="m-ic m-ic--xs m-ic--inline-block"><use xlink:href="#ic-clock" href="#ic-clock"></use></svg>{{item.appointmentDateTime | date: 'dd MMMM yyyy, h:mm a'}}</span>
                <dl class="m-description-list h-font-size--sm" [ngClass]="{'m-box__inner__flex__item': i % 2 != 0}">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12 h-spacing--md">
                            <dt class="m-description-list__title">Expert name</dt>
                            <dd class="m-description-list__description">{{item.expertTitle}} {{item.expertSurname}}</dd>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 h-spacing--md">
                            <dt class="m-description-list__title">Attended?</dt>
                            <dd class="m-description-list__description">{{item.attended ? 'Yes' : 'No'}}</dd>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 h-spacing--md" *ngIf="!item.attended">
                            <dt class="m-description-list__title">Reason</dt>
                            <dd class="m-description-list__description">{{item.reason}}</dd>
                        </div>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #noAppointments>
    <p><strong>We do not hold any appointment information.  If you think this is incorrect, please give your medical agency a call and they will be able to confirm your up to date appointment status.</strong></p>
</ng-template>
