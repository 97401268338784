<main class="c-login-page">
    <div class="c-login">
        <img class="recite-link" alt="accessibility tools" (click)="loadRecite()" src="/images/Standard _ Small _ Squared _ Red _ Option 3.svg">
        <div class="c-logo-banner">
            <img class="c-logo-banner__logo recite-ignore-styles-background" alt="Minster law logo" src="images/minster-logo-banner.svg">
        </div>
        <div unauthenticatedHeader class="c-main-nav-wrapper c-main-nav-wrapper--login c-logo-banner__margin"></div>
        <router-outlet #route="outlet"></router-outlet>
        <div minster-footer></div>
    </div>
    <div unauthenticatedImageContainer></div>
</main>
