import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppInsightsService } from './services/appinsights.service';
import { Subject, Subscription, filter, interval, map, switchMap, timer } from 'rxjs';
import { RouteStateService } from './services/route-state.service';
import { Title } from '@angular/platform-browser';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { OffLineService } from './services/off-line.service';
import { StorageService } from './services/storage.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    private destroy = new Subject<void>();
    public offLine = false;
    subscription: Subscription;
    public supressOffline = false;
    showCookieModal = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private appInsightsService: AppInsightsService,
        private routeStateService: RouteStateService,
        private titleService: Title,
        private swUpdate: SwUpdate,
        private storage: StorageService,
        @Inject(DOCUMENT) private document: Document,
        private offLineService: OffLineService
    ) {
        setTimeout(() => {
            if (this.swUpdate.isEnabled) {
                this.swUpdate.versionUpdates.
                    pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
                        map(evt => ({
                            type: 'UPDATE_AVAILABLE',
                            current: evt.currentVersion,
                            available: evt.latestVersion,
                        }))).subscribe(evt => {
                            alert('A new version of INK is available. Please click OK to refresh');
                            window.location.reload();
                        })
            }
        }, 10000)

        this.subscription = timer(0, 60000)
            .pipe(switchMap(() => this.offLineService.getSiteStatus()))
            .subscribe(result => this.offLine = result.offline);

        // subscribe to router events and send page views to Google Analytics
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const gtag = (window as any).gtag;
                const gtag_key = (window as any).gtag_key;

                // app insights - get the child route to get the page title
                let route = this.activatedRoute;

                while (route.firstChild) {
                    route = route.firstChild;
                }

                // gtag confirms cookies accepted.
                if (gtag) {
                    gtag('config', gtag_key, {
                        // page_title: 'Minsterlaw Online Portal',
                        page_path: event.urlAfterRedirects
                    });

                    if (route.outlet === 'primary') {
                        appInsightsService.logPageView(route.data['value'].title, event.urlAfterRedirects);
                    }
                }

                // Set the class of the body.
                document.body.removeAttribute('class');
                if (route && route.snapshot.data['bodyClass']) {
                    document.body.setAttribute('class', route.snapshot.data['bodyClass']);
                }

                if (route && route.snapshot.data['title']) {
                    this.titleService.setTitle(route.snapshot.data['title']);
                }
            }
        });
    }
    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
        this.routeStateService.updatePathParamState(null);
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.showCookieModal = this.storage.getItem('cookieConsent') == null;

        this.appInsightsService.generateSessionKey();

        this.activatedRoute.queryParamMap.pipe().subscribe(params => {
            this.supressOffline = params.get('supressOffline') === "true";

            if (this.supressOffline) {
                this.offLine = false;
                this.subscription.unsubscribe();
            }
        })
    }

    ngAfterContentInit() {
        var cookiesAreEnabled = this.storage.getItem('cookieConsent') == 'true';

        if (cookiesAreEnabled) {
            ((h, o, t, j, a, r) => {
                h.hj =
                    h.hj ||
                    /* tslint:disable:only-arrow-functions */
                    function () {
                        (h.hj.q = h.hj.q || []).push(arguments);
                    };
                h._hjSettings = { hjid: 1911560, hjsv: 1 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script');
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window as any, this.document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        }
    }
}
