import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertsMessageModel } from '../../models/alertsMessage.model';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'minster-alert-message',
    templateUrl: './minster-alert-message.component.html',
    styleUrls: ['./minster-alert-message.component.scss']
})
export class MinsterAlertMessageComponent implements OnInit {

    @Input('message') message: AlertsMessageModel;
    @Output('close') close = new EventEmitter<AlertsMessageModel>();

    constructor() { }

    ngOnInit(): void {
        if (this.message.type != 3) { this.message.alertMessage = this.message.alertMessage.replace('<a', '<a class="c-text-link c-text-link--white"'); }
        else { this.message.alertMessage = this.message.alertMessage.replace('<a', '<a class="c-text-link"'); }

        if (this.message.timeSpan > 0 && !this.message.mustDismiss) { // Dismiss after timespan.
            of(this.message).pipe(delay(this.message.timeSpan)).subscribe(() => {
                this.close.emit(this.message);
            });
        }
    }

    get displayClass(): string {
        if (!this.message) {
            return null;
        }

        switch (this.message.type) {
            case 0: {
                return 'c-service-banner--blue';
            }
            case 1: {
                return 'c-service-banner--info';
            }
            case 2: {
                return 'c-service-banner--warning';
            }
            case 3: {
                return 'c-service-banner--error';
            }
            default:
                return 'c-service-banner--success';
        }
    }

    closeClicked(event: Event) {
        event.stopPropagation();
        this.close.emit(this.message);
    }

}
