<div class="c-top-nav-mobile">
    <!-- .is-hidden hides back button using opacity:0 -->
    <div class="c-top-nav-mobile__item--back u-is-hidden">
        <button class="c-ic-button c-top-nav-mobile__btn-back" role="button" aria-label="back">
            <svg class="c-ic c-ic--sm">
                <use xlink:href="#ic-back" href="#ic-back"></use>
            </svg>
        </button>
    </div>
    <div class="c-top-nav-mobile__item--logo">
        <a class="c-top-nav-mobile__link" >
            <svg class="c-ic-purple c-top-nav-mobile__logo-svg">
                <use xlink:href="#ic-logo-2" href="#ic-logo-2"></use>
            </svg>
        </a>
    </div>
</div>
