import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export class ModalModel {
    title: string = '';
    content: string = '';
    submitButtonText: string = '';
    cancelButtonText: string = '';
    htmlText: string = '';
}

export enum ModalStatus {
    Accept = 'Accept',
    Reject = 'Reject',
    Dismiss = 'Dismiss'
}

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    private _canDeactivate: boolean = true;
    get canDeactivate(): boolean { return this._canDeactivate; }
    set canDeactivate(value: boolean) { this._canDeactivate = value; }

    toggleSubject: Subject<ModalModel | ModalStatus> = new Subject<ModalModel | ModalStatus>();

}
