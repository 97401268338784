import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';


export enum Criteria {
    AtLeastEightChars,
    AtLeastOneLowerCaseChar,
    AtLeastOneUpperCaseChar,
    AtLeastOneNumberChar,
    AtLeastOneSpecialChar
}

@Component({
  selector: 'password-strength-validator',
  templateUrl: './password-strength-validator.component.html',
  styleUrls: ['./password-strength-validator.component.scss']
})
export class PasswordStrengthValidatorComponent implements OnInit, OnChanges {

    @Input() password: string;

    passwordToVerify: string;

    @Input() validators: Criteria[] = Object.keys(Criteria).map(key => Criteria[key]);

    criteriaMap = new Map<Criteria, RegExp>();

    containAtLeastEightChars: boolean;
    containAtLeastOneLowerCaseChar: boolean;
    containAtLeastOneUpperCaseChar: boolean;
    containAtLeastOneNumberChar: boolean;
    containAtLeastOneSpecialChar: boolean;

  constructor() {
      this.criteriaMap.set(Criteria.AtLeastEightChars, RegExp(/^.{8,63}$/));
      this.criteriaMap.set(Criteria.AtLeastOneLowerCaseChar, RegExp(/^(?=.*?[a-z])/));
      this.criteriaMap.set(Criteria.AtLeastOneUpperCaseChar, RegExp(/^(?=.*?[A-Z])/));
      this.criteriaMap.set(Criteria.AtLeastOneNumberChar, RegExp(/^(?=.*?[0-9])/));
      this.criteriaMap.set(Criteria.AtLeastOneSpecialChar, RegExp(/^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/));
  }

  ngOnInit() {
  }

    ngOnChanges(changes: SimpleChanges) {
        this.password && this.password.length > 0 ? this.calculatePasswordStrength() : this.reset();
    }

    private calculatePasswordStrength() {
        const requirements: Array<boolean> = [];
        requirements.push(
            this._containAtLeastEightChars(),
            this._containAtLeastOneLowerCaseChar(),
            this._containAtLeastOneUpperCaseChar(),
            this._containAtLeastOneNumberChar(),
            this._containAtLeastOneSpecialChar()
        );
    }

    private _containAtLeastEightChars(): boolean {
        this.containAtLeastEightChars = this.password.length >= 8 && this.password.length <= 20;
        console.log('c 8 characters: ' + this.containAtLeastEightChars);
        return this.containAtLeastEightChars;
    }

    _containAtLeastOneLowerCaseChar(): boolean {
       this.containAtLeastOneLowerCaseChar = this.criteriaMap
            .get(Criteria.AtLeastOneLowerCaseChar)
           .test(this.password);

       return this.containAtLeastOneLowerCaseChar;
    }

    _containAtLeastOneUpperCaseChar(): boolean {
        this.containAtLeastOneUpperCaseChar = this.criteriaMap
            .get(Criteria.AtLeastOneUpperCaseChar)
            .test(this.password);

        return this.containAtLeastOneUpperCaseChar;
    }

    _containAtLeastOneNumberChar(): boolean {
        this.containAtLeastOneNumberChar = this.criteriaMap
            .get(Criteria.AtLeastOneNumberChar)
            .test(this.password);

        return this.containAtLeastOneNumberChar;
    }

    _containAtLeastOneSpecialChar(): boolean {
        this.containAtLeastOneSpecialChar = this.criteriaMap
            .get(Criteria.AtLeastOneSpecialChar)
            .test(this.password);

        return this.containAtLeastOneSpecialChar;
    }

  reset() {
      this.containAtLeastOneLowerCaseChar =
          this.containAtLeastOneUpperCaseChar =
          this.containAtLeastEightChars =
          this.containAtLeastOneNumberChar =
          this.containAtLeastOneSpecialChar = false;
  }



}
