<div class="u-mtop-sm">
    <h2 class="c-heading c-heading-uppercase">My settlement</h2>
    <p class="c-text-width">
        We wish to inform you that your claim has been settled.
    </p>
    <p class="c-text-width">
        Your compensation has been processed and should be with you within the next 7 working days.
    </p>
    <p class="c-text-width">
        Thank you for choosing Minster Law for your personal injury claim.
    </p>
</div>
