<div class="c-modal" role="dialog" style="display: block;" *ngIf="visible" id="onboarding">
    <div class="c-modal__content c-onboarding-body">
        <div class="c-onboarding-img">
            <div class="c-onboarding-img__size">
                <img [src]="currentStep.imageUrlMobile" class="is-hidden-mobile c-onboarding-img__steps">
                <img [src]="currentStep.imageUrlDesktop" class="is-hidden-tablet c-onboarding-img__steps">
            </div>
        </div>
        <button class="c-ic-button c-modal__close" (click)="skip()"> <svg class="c-ic c-ic--xs c-ic-vertical"> <use xlink:href="#ic-close" href="#ic-close"></use></svg></button>
        <div class="c-modal__content__body c-onboarding-content">
            <h2 class="c-heading c-heading-purple u-flex-center">{{currentStep.title}}</h2>
            <p class="c-text-width c-text-dark c-text-align-center" [innerHTML]="currentStep.description"></p>
            <div class="u-flex-center">
                <ul class="c-text-list-style-none u-flex-row u-margin-none u-flex-center u-flex-wrap">
                    <li class="" *ngFor="let step of steps; let i = index;">
                        <button (click)="goToStep(i)" [ngClass]="{'c-onboarding-dot-slider--active': i === page}" class="c-onboarding-dot-slider "></button>
                    </li>
                </ul>
            </div>
            <div class=" u-flex-center u-mtop-sm">
                <button id="onboarding-skip-button" *ngIf="page < steps.length - 1" (click)="skip()" class="c-button c-button--grey u-mright-sm">Skip</button>
                <button id="onboarding-next-button" *ngIf="page < steps.length - 1" (click)="next()" class="c-button c-button--yellow">Next</button>
                <button id="onboarding-done-button" *ngIf="page == steps.length - 1" (click)="done()" class="c-button c-button--grey  c-button--full u-mright-sm">Done</button>
                <button id="onboarding-welcome-pack-button" *ngIf="page == steps.length - 1" (click)="welcomepack()" class="c-button c-button--full ">View welcome pack</button>
            </div>
        </div>
    </div>
</div>
