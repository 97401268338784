import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { ConfigurationService } from './configuration.service';

export enum LoginInterruptType {
    None = 0,
    NewPortal = 1
}

export interface LoginInterruptDetail {
    type: LoginInterruptType;
    additionalInfo: null;
}

@Injectable({
    providedIn: 'root'
})
export class InterruptLoginService {

    constructor(
        private storage: StorageService,
        private config: ConfigurationService
    ) { }

    getLoginNextInterrupt() {
        const now = Date.now();
        const newPortalThreshold = new Date(this.config.newSiteNotificationExpiryDate).getTime();

        if (!this.hasViewedLoginInterrupt({ type: LoginInterruptType.NewPortal, additionalInfo: null }) && newPortalThreshold > now ) {
            return { type: LoginInterruptType.NewPortal, additionalInfo: null };
        }
        return { type: LoginInterruptType.None, additionalInfo: null };
    }

    recordInterruptViewed(interrupt: LoginInterruptDetail) {
        const value = `${interrupt.type}`;
        const interrupts = this.getViewedLoginInterrupts();
        interrupts.push(value);
        this.saveViewedInterrupts(interrupts);
    }

    hasViewedLoginInterrupt(interruptType: LoginInterruptDetail) {
        const value = `${interruptType.type}`;
        const interrupts = this.getViewedLoginInterrupts();
        return interrupts.indexOf(value) > -1;
    }

    private saveViewedInterrupts(interrupts: string[]) {
        const itemsToSave = [...new Set(interrupts)];
        this.storage.setItem('INTERRUPTS-LOGIN-VIEWED-NEW-PORTAL', JSON.stringify(itemsToSave));
    }

    private getViewedLoginInterrupts() {
        return JSON.parse(this.storage.getItem('INTERRUPTS-LOGIN-VIEWED-NEW-PORTAL') || '[]');
    }
}
