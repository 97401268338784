import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { AlertsMessageModel } from '../../models/alertsMessage.model';
import { StateService } from '../../services/state.service';
import { StorageService } from '../../services/storage.service';
import { CaseModel } from '../../models/case.model';

@Component({
    selector: 'minster-alert-messages',
    templateUrl: './minster-alert-messages.component.html',
    styleUrls: ['./minster-alert-messages.component.scss']
})
export class MinsterAlertMessagesComponent implements AfterViewInit, OnDestroy {

    @Input('case') case: CaseModel;
    messages: AlertsMessageModel[];
    subscriptions = [];

    constructor(private stateService: StateService, private storage: StorageService) { }

    ngAfterViewInit() {

        const now = new Date();
        const today = `${now.getUTCFullYear()}-${('0' + now.getUTCMonth()).slice(-2)}-${('0' + now.getUTCDate()).slice(-2)}`;
        const key = `ALERTS-RECEIVED-${this.case.id}`;

        if (this.storage.getItem(key) === today) {
            return;
        }

        this.subscriptions.push(this.stateService.GetAlerts().subscribe(results => {
            this.storage.setItem(key, today); // Don't get alerts every time.
            this.messages = results;
            console.log('Messages:', this.messages);
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    messageClosed(model: AlertsMessageModel ): void {
        const index = this.messages.indexOf(model);
        if (index > -1) {
            this.messages.splice(index, 1);
        }
    }
}
