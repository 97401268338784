import { Component, OnInit } from '@angular/core';
import { CaseModel } from '../../models/case.model';
import { Subscription } from 'rxjs';
import { DocumentModel, WelcomePackDocumentModel } from '../../models/document.model';
import { AppInsightsService } from '../../services/appinsights.service';
import { DocumentName, DocumentService } from '../../services/document.service';
import { ActivatedRoute } from '@angular/router';
import { KeyedCollection } from '../../data/keyedcollection';
import { AppUtilities } from '../../app.utility';
import mime, { extension } from 'mime-types';
import * as moment from 'moment';
import { MedicalReportRequest, MedicalReportService } from '../../services/medical-report.service';
import { WelcomePackAuditService } from '../../services/welcome-pack-audit.service';
import { CalendarFormat } from '../../data/calendar-format';

enum DisplayFilter {
    SentToYou = 1,
    ReceivedFromYou = 2
}

@Component({
    selector: 'documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

    subscriptions: Subscription[] = [];
    case: CaseModel;
    documents: DocumentModel[];
    //welcomePack: WelcomePackDocumentModel[];
    displayFilter: DisplayFilter = DisplayFilter.SentToYou;
    isLoadingWelcomePack: boolean;
    expandWelcomePack: boolean;
    downloadingDocs = new KeyedCollection<boolean>();
    isSettled: boolean;

    constructor(private route: ActivatedRoute,
        private appInsights: AppInsightsService,
        private documentService: DocumentService,
        private medicalReportService: MedicalReportService,
        private welcomePackAuditService: WelcomePackAuditService) {
    }

    ngOnInit(): void {
        this.case = this.route.parent.snapshot.data.case;
        this.documents = this.route.parent.snapshot.data.documents;
        this.isSettled = this.case.endOfClaim;

        this.expandWelcomePack = this.route.snapshot.queryParams.openWelcomePack === 'true';

        /*this.isLoadingWelcomePack = true;
        this.documentService.getDocWelcomePack(this.case.reference).subscribe(welcomePack => {
            this.welcomePack = welcomePack;
            this.isLoadingWelcomePack = false;
        }, () => {
            this.isLoadingWelcomePack = false;
        });*/

        this.subscriptions.push(this.documentService.addDocumentCommitted.subscribe(r => {
            this.documentService.get(this.case.reference).subscribe(result => {
                this.documents = result;
            });
        }));

        this.subscriptions.push(this.medicalReportService.approvalCommitted.subscribe((request: MedicalReportRequest) => {
            const matchingDocuments = this.documents.filter(d => d.id === request.document.id);
            matchingDocuments.forEach(d => {
                this.documents.splice(this.documents.indexOf(d), 1);
            });

            this.documents.push(request.document);
        }));
        this.subscriptions.push(this.medicalReportService.rejectionCommitted.subscribe((request: MedicalReportRequest) => {
            const matchingDocuments = this.documents.filter(d => d.id === request.document.id);
            matchingDocuments.forEach(d => {
                this.documents.splice(this.documents.indexOf(d), 1);
            });

            this.documents.push(request.document);
        }));
    }

    isDocument(object): object is DocumentModel {
        return 'id' in object;
    }

    getDocKey(doc): string {
        return this.isDocument(doc) ? doc.id : doc.name;
    }

    getDocUrl(doc): string {
        return this.isDocument(doc) ? doc.contentUrl : doc.url;
    }

    isDownloadingDoc(doc: DocumentModel | WelcomePackDocumentModel) {
        return this.downloadingDocs.ContainsKey(this.getDocKey(doc));
    }

    /*downloadWelcomePackDocument(doc: WelcomePackDocumentModel) {

        this.appInsights.logComplexEvent('downloadWelcomePackDocument', {name: doc.name});

        this.welcomePackAuditService.recordDocumentViewed(this.case.id, doc.name as DocumentName);

        this.downloadingDocs.Add(doc.name, true);

        const url = doc.url;
        const reference = AppUtilities.getRouteParam(this.route, 'reference');
        const guid = url.substr(url.lastIndexOf('/') + 1);
        this.documentService.download(guid, reference).subscribe(
            result => {
                const mimeType = 'application/pdf';
                const bytes = AppUtilities.toUTF8Array(result.content);
                const content = new Blob([bytes], {type: mimeType});
                const filename = `${result.name}.${extension(mimeType)}`;
                AppUtilities.saveToFile(content, filename);
                this.downloadingDocs.Remove(doc.name);
            },
            () => {
                this.downloadingDocs.Remove(doc.name);
            }
        );
    }*/

    downloadDocument(doc: DocumentModel) {

        this.appInsights.logComplexEvent('downloadDocument', { id: doc.id });

        const key = this.getDocKey(doc);

        this.downloadingDocs.Add(key, true);

        this.documentService.content(doc.contentUrl).subscribe(
            result => {
                const bytes = AppUtilities.toUTF8Array(result.data);
                const content = new Blob([bytes], { type: mime.lookup(doc.fileExtension) || 'application/pdf' });
                const filename = `${doc.name}.${doc.fileExtension}`;
                AppUtilities.saveToFile(content, filename);
                this.downloadingDocs.Remove(doc.id);
            }, () => {
                this.downloadingDocs.Remove(doc.id);
            }
        );
    }

    getFriendlyMedicalReportName(document: DocumentModel) {
        if (document.requiresApproval) {
            return `Medical report`;
        }

        return 'Medical'
    }

    getFriendlyUpdateDate(document: DocumentModel) {
        return moment(document.statusChangedDate).calendar(null, CalendarFormat);
    }

    getFriendlyCreateDate(document: DocumentModel) {
        return moment(document.createdDate).calendar(null, CalendarFormat);
    }

    accept(medical: DocumentModel) {
        this.medicalReportService.startApproval(medical, this.case);
    }

    reject(medical: DocumentModel) {
        this.medicalReportService.startRejection(medical, this.case);
    }

    get medicalsSentToYou(): DocumentModel[] {
        return this.documents.filter(d => d.state.toLowerCase() === 'received' && d.group.toLowerCase() === 'medical').sort((a, b) => {
            if (a.status.toLowerCase() === 'new') {
                if (b.status.toLowerCase() === 'new') {
                    return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
                } else {
                    return -1;
                }
            } else if (b.status.toLowerCase() === 'new') {
                return 1;
            }

            return new Date(a.statusChangedDate) < new Date(b.statusChangedDate) ? 1 : -1;
        });
    }

    getWelcomePackDisplayName(fileName: string): string {
        switch (fileName) {
            case "KeyInformation":
                return "Key Information";
            case "KeyInformationv1":
                return "Key Information";
            case "KeyInformationv2":
                return "Key Information";
            case "KeyInformationv3":
                return "Key Information";
            case "KeyInformationv4":
                return "Key Information";
            case "TermsAndConditions":
                return "Terms and Conditions";
            case "TermsAndConditionsv1":
                return "Terms and Conditions";
            case "TermsAndConditionsv2":
                return "Terms and Conditions";
            case "OCFAv1":
                return "CFA";
            case "25CFAv1":
                return "CFA";
            case "WelcomeLetter":
                return "Welcome Letter";
            case "WhatToExpect":
                return "What to Expect";
            case "UnderstandingCompensation":
                return "Understanding Compensation";
            case "LegalInformationAndComplaints":
                return "Legal Information and Complaints";
            case "FundingYourClaim":
                return "Funding Your Claim";
            case "CertificateOfSuitability":
                return "Certificate Of Suitability";
            case "PrivacyNoticeUninsured":
                return "Privacy Notice Uninsured";
            case "AuthorityForm":
                return "Authority Form";
            case "ClientInformationSheet":
                return "Client Information Sheet";
            case "UninsuredLossesQuestionnaire":
                return "Uninsured Losses Questionnaire";
            case "ForeignInitialLetter":
                return "Foreign Initial Letter";
            case "WelcomePackUntraced":
                return "Welcome Pack Untraced";
            case "GuidanceNotes":
                return "Guidance Notes";
            case "FundingYourClaim-CCFA":
                return "Funding Your Claim - CCFA";
            default:
                return fileName;
        }
    }

    get welcomePackSentToYou(): DocumentModel[] {
        return this.documents.filter(d => d.state.toLowerCase() === 'received' && d.group.toLowerCase() === 'client' && d.fileExtension === 'pdf');
    }

    get courtSentToYou(): DocumentModel[] {
        return this.documents.filter(d => d.state.toLowerCase() === 'received' && d.group.toLowerCase() === 'court').sort((a, b) => {
            return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
        });
    }

    get settlementSentToYou(): DocumentModel[] {
        return this.documents.filter(d => d.state.toLowerCase() === 'received' && d.group.toLowerCase() === 'settlement').sort((a, b) => {
            return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
        });
    }

    get liabilitySentToYou(): DocumentModel[] {
        return this.documents.filter(d => d.state.toLowerCase() === 'received' && d.group.toLowerCase() === 'liabilityevidence').sort((a, b) => {
            return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
        });
    }

    get medicalsReceivedFromYou(): DocumentModel[] {
        return this.documents.filter(d => d.state.toLowerCase() === 'sent' && d.group.toLowerCase() === 'medical').sort((a, b) => {
            return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
        });
    }

    get courtReceivedFromYou(): DocumentModel[] {
        return this.documents.filter(d => d.state.toLowerCase() === 'sent' && d.group.toLowerCase() === 'court').sort((a, b) => {
            return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
        });
    }

    get settlementReceivedFromYou(): DocumentModel[] {
        return this.documents.filter(d => d.state.toLowerCase() === 'sent' && d.group.toLowerCase() === 'settlement').sort((a, b) => {
            return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
        });
    }

    get liabilityReceivedFromYou(): DocumentModel[] {
        return this.documents.filter(d => d.state.toLowerCase() === 'sent' && d.group.toLowerCase() === 'liabilityevidence').sort((a, b) => {
            return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
        });
    }

    /*get conditionalFeeAgreement(): WelcomePackDocumentModel | null {
        const matchingDocs = (this.welcomePack || []).filter(d => d.name === DocumentName._0CFA || d.name === DocumentName._25CFA);

        if (matchingDocs.length === 0) {
            return null;
        }

        return matchingDocs[0];
    }

    get keyInformation(): WelcomePackDocumentModel | null {
        const matchingDocs = (this.welcomePack || []).filter(d => d.name === DocumentName.KeyInformation);

        if (matchingDocs.length === 0) {
            return null;
        }

        return matchingDocs[0];
    }

    get termsAndConditions(): WelcomePackDocumentModel | null {
        const matchingDocs = (this.welcomePack || []).filter(d => d.name === DocumentName.TermsAndConditions);

        if (matchingDocs.length === 0) {
            return null;
        }

        return matchingDocs[0];
    }

    //LV/Arag
    get keyInformationv1(): WelcomePackDocumentModel | null {
        const matchingDocs = (this.welcomePack || []).filter(d => d.name === DocumentName.KeyInformationv1);

        if (matchingDocs.length === 0) {
            return null;
        }

        return matchingDocs[0];
    }

    get keyInformationv2(): WelcomePackDocumentModel | null {
        const matchingDocs = (this.welcomePack || []).filter(d => d.name === DocumentName.KeyInformationv2);

        if (matchingDocs.length === 0) {
            return null;
        }

        return matchingDocs[0];
    }

    get keyInformationv3(): WelcomePackDocumentModel | null {
        const matchingDocs = (this.welcomePack || []).filter(d => d.name === DocumentName.KeyInformationv3);

        if (matchingDocs.length === 0) {
            return null;
        }

        return matchingDocs[0];
    }

    get keyInformationv4(): WelcomePackDocumentModel | null {
        const matchingDocs = (this.welcomePack || []).filter(d => d.name === DocumentName.KeyInformationv4);

        if (matchingDocs.length === 0) {
            return null;
        }

        return matchingDocs[0];
    }

    get termsAndConditionsv1(): WelcomePackDocumentModel | null {
        const matchingDocs = (this.welcomePack || []).filter(d => d.name === DocumentName.TermsAndConditionsv1);

        if (matchingDocs.length === 0) {
            return null;
        }

        return matchingDocs[0];
    }

    get termsAndConditionsv2(): WelcomePackDocumentModel | null {
        const matchingDocs = (this.welcomePack || []).filter(d => d.name === DocumentName.TermsAndConditionsv2);

        if (matchingDocs.length === 0) {
            return null;
        }

        return matchingDocs[0];
    }

    get conditionalFeeAgreement0v1(): WelcomePackDocumentModel | null {
        const matchingDocs = (this.welcomePack || []).filter(d => d.name === DocumentName._OCFAv1);

        if (matchingDocs.length === 0) {
            return null;
        }

        return matchingDocs[0];
    }

    get conditionalFeeAgreement25v1(): WelcomePackDocumentModel | null {
        const matchingDocs = (this.welcomePack || []).filter(d => d.name === DocumentName._25CFAv1);

        if (matchingDocs.length === 0) {
            return null;
        }

        return matchingDocs[0];
    }*/

    get noDocumentsToDisplay(): boolean {
        return this.courtReceivedFromYou.length === 0 &&
            this.liabilityReceivedFromYou.length === 0 &&
            this.medicalsReceivedFromYou.length === 0 &&
            this.settlementReceivedFromYou.length === 0;
    }

    addDocument(): void {
        this.documentService.startAddDocument(this.case);
    }
}
