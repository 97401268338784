import { Component, OnInit, Input } from '@angular/core';
import { CaseModel } from '../../models/case.model';
import { DocumentModel } from '../../models/document.model';
import { Appointment } from '../../models/medical-appointment.model';
import { InterruptService, InterruptDetail, InterruptType } from '../../services/interrupt.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { OnboardingService } from '../../services/onboarding.service';
import { StorageService } from '../../services/storage.service';
import { CalendarFormat } from '../../data/calendar-format';
import { ModalA11yService } from '../../services/modal-a11y.service';
import { ConfigurationService } from '../../services/configuration.service';
import { StateService } from 'src/app/services/state.service';
import { MilestoneModel } from 'src/app/models/milestone.model';

@Component({
    selector: 'interrupt',
    templateUrl: './interrupt.component.html',
    styleUrls: ['./interrupt.component.scss']
})
export class InterruptComponent implements OnInit {

    @Input('case') case: CaseModel;
    @Input('documents') documents: DocumentModel[];
    @Input('appointments') appointments: Appointment[];
    @Input('milestones') milestones: MilestoneModel[];

    interruptToShow: InterruptDetail;
    key: string;
    showMessage: boolean;
    portalName: string;

    constructor(
        private router: Router,
        private interruptService: InterruptService,
        private onboarding: OnboardingService,
        private storage: StorageService,
        private modalA11y: ModalA11yService,
        private config: ConfigurationService,
        private stateService: StateService
    ) { }

    ngOnInit(): void {
        // remove onboading for automated testing
        if (!this.stateService.getSupressOnBoarding()) {
            this.key = `INTERRUPTS-HIDDEN-${this.case.id}`;

            if (!this.onboarding.isOnboardingCompleted(this.case.id)) {
                return;
            }
            this.interruptToShow = this.interruptService.getNextInterrupt(this.case, this.documents, this.appointments, this.milestones);

            if (this.interruptToShow.type !== InterruptType.None) {
                this.interruptService.recordInterruptViewed(this.case.id, this.interruptToShow);
                this.modalA11y.open(this);
            }

            this.portalName = this.config.portalName;
        } else {
            this.interruptToShow = { type: InterruptType.None, additionalInfo: null };
        }
    }

    get showInterrupt(): boolean {
        return this.interruptToShow.type !== InterruptType.None;
    }

    get showReadOnly(): boolean {
        return this.interruptToShow.type === InterruptType.ReadOnly;
    }

    get showWelcomePackInterrupt(): boolean {
        return this.interruptToShow.type === InterruptType.WelcomePack;
    }

    get showMedicalReportReview(): boolean {
        return this.interruptToShow.type === InterruptType.MedicalReportReview;
    }

    get showAppointmentReminder(): boolean {
        return this.interruptToShow.type === InterruptType.AppointmentReminder;
    }

    get showLossReminder(): boolean {
        return this.interruptToShow.type === InterruptType.LossReminder;
    }

    get showRegistrationLossReminder(): boolean {
        return this.interruptToShow.type === InterruptType.RegistrationLossReminder;
    }

    get showRecentLossReminder(): boolean {
        return this.interruptToShow.type === InterruptType.RecentLossReminder;
    }

    get showElapsedMedicalReportReview(): boolean {
        return this.interruptToShow.type === InterruptType.ElapsedMedicalReportReview;
    }

    get showMilestones(): boolean {
        return this.interruptToShow.type === InterruptType.Milestones;
    }

    get document(): DocumentModel | null {
        return (this.interruptToShow.additionalInfo as DocumentModel);
    }

    get appointment(): Appointment | null {
        return (this.interruptToShow.additionalInfo as Appointment);
    }

    get friendlyAppointmentDate(): string {
        return moment(this.appointment.appointmentDateTime).calendar(null, CalendarFormat);
    }

    get friendlyAppointmentAddress(): string {
        const parts = [];
        parts.push(this.appointment.addressLine1);
        parts.push(this.appointment.addressLine2);
        parts.push(this.appointment.addressLine3);
        parts.push(this.appointment.addressLine4);
        parts.push(this.appointment.addressLine5);
        parts.push(this.appointment.postcode);

        return parts.filter(p => p.trim().length > 0).join(', ');
    }

    closeAndGoToFaq(query: string) {
        this.close();
        this.router.navigate(['/portal', this.case.reference, 'faqs'], { queryParams: { q: query } });
    }

    showMedicalReport(document: DocumentModel) {
        this.interruptToShow.type = InterruptType.None;
        this.router.navigate(['/portal', this.case.reference, 'case', 'medical']);
    }

    showMedicalAppointment(document: DocumentModel) {
        this.interruptToShow.type = InterruptType.None;
        this.router.navigate(['/portal', this.case.reference, 'case', 'medical']);
    }

    showDocuments() {
        this.interruptToShow.type = InterruptType.None;
        this.router.navigate(['/portal', this.case.reference, 'documents'], { queryParams: { openWelcomePack: true } });
    }

    addLosses() {
        this.interruptToShow.type = InterruptType.None;
        this.router.navigate(['/portal', this.case.reference, 'case', 'losses'], { queryParams: { addLosses: true } });
    }

    showClaimSettled() {
        this.interruptToShow.type = InterruptType.None;
        this.router.navigate(['/portal', this.case.reference, 'case', 'settlement']);
    }

    close() {
        if(this.interruptToShow.type == InterruptType.Milestones) {
            this.onboarding.setMilestoneCompleted(this.case.id);
        }

        this.interruptToShow.type = InterruptType.None;
        this.modalA11y.close(this);
    }

    displayInterrupt(hiddenInterrupts: InterruptType[]) {
        hiddenInterrupts.splice(hiddenInterrupts.indexOf(this.interruptToShow.type), 1);
        this.storage.setItem(this.key, JSON.stringify(hiddenInterrupts));
    }

    hideInterrupt(hiddenInterrupts: InterruptType[]) {
        hiddenInterrupts.push(this.interruptToShow.type);
        this.storage.setItem(this.key, JSON.stringify(hiddenInterrupts));
    }

    toggleShowMessage() {
        this.showMessage = !this.showMessage;

        let hiddenInterrupts: InterruptType[];
        hiddenInterrupts = JSON.parse(this.storage.getItem(this.key) || '[]');

        if (hiddenInterrupts.indexOf(this.interruptToShow.type) > -1) {
            this.displayInterrupt(hiddenInterrupts);
        } else {
            this.hideInterrupt(hiddenInterrupts);
        }
    }
}
