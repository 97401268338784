<div class="c-modal" role="dialog" style="display: block;" *ngIf="show">
    <div class="c-modal__content">
        <div class="c-modal-top-nav">
            <div class="c-modal-top-nav__logo">
                <svg class="c-ic-purple c-modal-top-nav__logo-img">
                    <use xlink:href="#ic-logo-2" href="#ic-logo-2"></use>
                </svg>
            </div>
            <button class="c-ic-button c-modal-top-nav__close" role="button" aria-label="close" (click)="buttonClicked(false)">
                <svg class="c-ic c-ic--sm">
                    <use xlink:href="#ic-close" href="#ic-close"></use>
                </svg>
            </button>
        </div>
        <div class="c-mia-modal" *ngIf="isMiaModal">
            <div class="mia-modal-img">
                <img class="c-banner__mia c-banner__mia--modal c-mia-img recite-ignore-styles-background" alt="Minster law logo" src="images/Mia.svg">
            </div>
        </div>
        <div class="c-modal__content__body" [ngClass]="{'c-mia-modal-body': isMiaModal}">
            <ng-content></ng-content>
            <div class="u-flex-center u-mtop-ism" *ngIf="closeButtonTitle || cancelButtonTitle">
                <minster-button buttonClass="c-button" *ngIf="closeButtonTitle" [text]="closeButtonTitle" (click)="buttonClicked(true)" [isLong]="true"></minster-button>
                <minster-button buttonClass="c-button" *ngIf="cancelButtonTitle" [text]="cancelButtonTitle" [isDanger]="true" (click)="buttonClicked(false)" [isLong]="true"></minster-button>
            </div>
        </div>
    </div>
</div>
