<div class="u-mtop-sm">
    <h2 class="c-heading c-heading-uppercase">My Medical</h2>
    <p class="c-text-width">Once instructed, details about the medical agency assigned to your case will be shown below.</p>
    <p class="c-text-width">Here, you can find guides on the appointment stage of your claim and access your medical report when it is ready.</p>
</div>

<div class="c-panel">
    <ul class="c-panel-list">
        <li minster-expandable-panel *ngIf="medicals.length > 0" [heading]="'Reports'" id="medical-agency-details" icon="heartbeat" [expanded]="true" aria-label="Personal details">
            <div class="c-table-list">
                <ul class="c-table-list__content">
                    <li class="c-table-list__item c-table-list__item--no-border c-table-list__item--no-bot-border u-flex-col" [attr.id]="'doc-' + i" *ngFor="let medical of medicals; let i = index;">
                        <h2 class="c-heading c-heading-dark">{{medical.name}}</h2>
                        <span>{{getFriendlyCreatedDate(medical)}}</span>
                        <p class="c-text-width c-text-dark rejected-text" *ngIf="medical.status.toLowerCase() === 'rejected'">You rejected this report {{getFriendlyUpdateDate(medical)}}</p>
                        <p class="c-text-width c-text-dark approve" *ngIf="medical.status.toLowerCase() === 'approved'">You approved this report {{getFriendlyUpdateDate(medical)}}</p>
                        <div class="u-mtop-ism">
                            <minster-button  buttonClass="u-mright-sm review" ariaLabel="Review medical report {{medical.name}}" [disabled]="isDownloadingDoc(medical)" [isLoading]="isDownloadingDoc(medical)" class="medical-approval-button" isPrimary="true" [text]="case.readOnly ? 'Download' : 'Review'" (click)="download(medical)"></minster-button>
                            <minster-button  buttonClass="u-mright-sm approve" ariaLabel="Accept medical report {{medical.name}}" *ngIf="!case.readOnly && medical.status.toLowerCase() === 'new' && medical.requiresApproval" isWarning="true" text="Accept" (click)="accept(medical)"></minster-button>
                            <minster-button buttonClass="reject" ariaLabel="Reject medical report {{medical.name}}" *ngIf="!case.readOnly && medical.status.toLowerCase() === 'new' && medical.requiresApproval" isDanger="true" text="Reject" (click)="reject(medical)"></minster-button>
                        </div>
                    </li>
                </ul>
            </div>
        </li>
        <li minster-expandable-panel *ngIf="case.medicalAgencyId" [heading]="'Medical Agency Details'" id="medical-agency-details" icon="briefcase-medical" [expanded]="true" aria-label="Personal details">
            <medical-agency-card [agencyId]="case.medicalAgencyId"></medical-agency-card>
        </li>

        <li minster-expandable-panel [heading]="'Preparation Guide'" id="preparation-details" icon="video-camera" [expanded]="true" aria-label="Accident details">
            <div class="video_wrapper">
                <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="
                    https://player.vimeo.com/video/896823665?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Appointment guide"></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
        </li>
        <li minster-expandable-panel [heading]="'More Information'" id="medical-agency-details" icon="info-circle" [expanded]="false" aria-label="Personal details">
            <p>To learn more about medical appointments and the next steps in your claim journey visit our <a disableTabOnModal routerLink="../../faqs" [queryParams]="{q: 'medical'}" class="c-text-link">FAQs centre</a>.</p>
            <p>The preparation guide is also available as a PDF download</p>
            <button type="button" class="u-mtop-ism c-button--link c-button is-primary" (click)="downloadMedicalPreparationGuide()">Download Preparation Guide</button>
        </li>
    </ul>
</div>
