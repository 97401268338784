<div class="l-main-view__content-panel__container l-container-fluid">
    <div class="row l-main-view__content-panel__container-max-width">
        <div class="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
            <h1 class="heading h-text--center">Welcome Pack</h1>
            <br />
            <ng-container *ngIf="content; else noContent">
                <div id="welcome-pack-intro" [innerHtml]="content"></div>
                <minster-link [text]="getTitle(item.name)" svgId="#ic-document" *ngFor="let item of attachments" (click)="download(item.url)"></minster-link>
            </ng-container>
            <ng-template #noContent>
                <ng-container *ngIf="error; else noError">
                    <p>An error occurred while retrieving your welcome pack. Please try again later.</p>
                </ng-container>
                <ng-template #noError>
                    <p>Fetching your welcome pack...</p>
                </ng-template>
            </ng-template>
        </div>
    </div>
</div>
