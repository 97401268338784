import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MeService } from 'src/app/services/me.service';
import { AppInsightsService } from 'src/app/services/appinsights.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { AppValidators } from 'src/app/app.validator';
import { VerifyChangeEmailModel } from 'src/app/models/me.model';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'app-change-email',
    templateUrl: './change-email.component.html',
    styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {
    form: UntypedFormGroup;
    submitted: boolean = false;
    submitting: boolean = false;
    requesting: boolean = false;
    errors: string[] = [];
    completed: boolean = false;
    attempts: number = 3;
    guid: string = '';
    username: string;


    accesscodeTextMask = createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        integerLimit: 4,
        allowLeadingZeroes: true
    });

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private meService: MeService,
        private appInsightsService: AppInsightsService,
        private storage: StorageService
    ) {
        this.form = fb.group({
            'accesscode': [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4)])],
            'email': [null, Validators.compose([Validators.required, Validators.email])],
            'emailAgain': [null, null]
        }, { validator: AppValidators.emailsMatch });
    }

    ngOnInit() {
        this.guid = this.route.snapshot.params.guid;

        const accessCodeSent = JSON.parse(this.storage.getItem('accesscode_email'));

        if (!accessCodeSent) {
            this.requestAccessCode();
            this.requesting = false;
            this.storage.setItem('accesscode_email', 'true');
        }
    }

    requestAccessCode() {
        if (!this.requesting) {
            this.requesting = true;

            setTimeout(() => {
                this.requesting = false;
            }, 3000);

            this.meService.checkChangeEmailCode(this.guid).subscribe(
                result => {
                    this.appInsightsService.logCustomEvent('Check change email code', 'Succeeded', result.Username);
                    this.username = result.Username;
                },
                error => this.router.navigate(['/bad-link'])
            );
         }
    }

    clear() {
        this.errors = [];
    }

    submit(form: UntypedFormGroup) {
        this.submitted = true;

        if (form.invalid) {
            return;
        }

        form.disable();
        this.submitting = true;

        const model: VerifyChangeEmailModel = {
            accessCode: form.value.accesscode,
            email: form.value.email,
            confirmEmail: form.value.emailAgain,
            changeEmailCode: this.guid
        };

        this.meService.changeEmail(model).subscribe(
            result => {
                this.submitting = false;
                this.completed = true;
                this.appInsightsService.logCustomEvent('Use access code ', 'Succeeded', this.username);
            },
            error => {
                form.enable();
                this.submitting = false;
                this.attempts -= 1;
                const response = error ? error.error : undefined;

                if (response && response.Expired) {
                     this.attempts = 0;
                }

                if (error.status === 400) {
                    this.errors = [response];
                } else {
                    this.errors = ['There is a system error please contact minster law'];
                }

                this.appInsightsService.logCustomEvent('Use access code ', 'Failed', this.username);
            }
        );
    }
}
