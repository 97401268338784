import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CaseService } from '../services/case.service';
import { MeService } from '../services/me.service';
import { StorageService } from '../services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class UserGuard implements CanActivate {

    constructor(
        private router: Router,
        private caseService: CaseService,
        private meService: MeService,
        private storage: StorageService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const username = this.storage.getItem('username');
        if (!username) {
            this.router.navigate(['/auth/logout']);
            return false;
        }

        this.meService.getUser(username).subscribe(
            result => {
                this.storage.setItem('user', JSON.stringify(result));

                if (result.Cases.length) {
                    const reference = result.Cases[0];
                    this.router.navigate(['/portal', reference]);
                    return true;
                } else {
                    this.router.navigate(['/no-cases']);
                    return false;
                }
            }
        );
    }
}
