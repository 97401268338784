import { Component, OnInit, Input } from '@angular/core';
import { MedicalAppointmentModel, Appointment } from '../../models/medical-appointment.model';

@Component({
  selector: 'past-appointments',
  templateUrl: './past-appointments.component.html',
  styleUrls: ['./past-appointments.component.scss']
})
export class PastAppointmentsComponent implements OnInit {
    @Input() appointment: MedicalAppointmentModel;

    pastItems: Appointment[];
    currentDateTime: Date;

    constructor() { }

    ngOnInit() {
        window.scrollTo(0, 0);

        this.currentDateTime = new Date();

        this.pastItems = this.appointment.appointment.filter(x => {
            return new Date(x.appointmentDateTime) <= this.currentDateTime;
        }).sort((a, b) => {
            if (a.appointmentDateTime < b.appointmentDateTime) { return -1; }
            if (a.appointmentDateTime > b.appointmentDateTime) { return 1; }
            return 0;
        });
    }

}
