import { Injectable } from '@angular/core';
import { ModalService, ModalStatus } from './modal.service';
import { StorageService } from './storage.service';

export enum OnboardingStep {
    RegistrationComplete = 'RegistrationComplete',
    ClaimDetails = 'ClaimDetails',
    Medical = 'Medical',
    Losses = 'Losses',
    WelcomePack = 'WelcomePack',
    Notifications = 'Notifications',
    End = 'End'
}

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

    constructor(private storage: StorageService) { }

    isOnboardingCompleted(caseId: string): boolean {
        const key = `ONBOARDING-${caseId}`;

        return this.storage.getItem(key) === '1' || this.storage.getItem(key) === '2' ;
    }

    isOnboardingVersion2Completed(caseId: string): boolean {
        const key = `ONBOARDING-${caseId}`;

        return this.storage.getItem(key) === '2' ;
    }

    setCompleted(caseId: string) {
        const key = `ONBOARDING-${caseId}`;
        this.storage.setItem(key, '2');
    }

    isMilestoneInterruptComplete(caseId: string): boolean {
        const key = `MILESTONE-${caseId}`;

        return this.storage.getItem(key) === '1';
    }

    setMilestoneCompleted(caseId: string) {
        const key = `MILESTONE-${caseId}`;
        this.storage.setItem(key, '1');
    }
}
