<div class="row ie-fix">
    <div class="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12 h-text--light h-text--center h-text--left-sm">
        <ng-container *ngIf="!completed; else isCompleted">
            <h1 class="heading">Change your email address</h1>
            <p class="c-text-width">Now please enter your access code (you should have received this via SMS) and choose a new email address.</p>
            <form class="m-form m-form--white m-form--wizard" [formGroup]="form" (submit)="submit(form)" (input)="clear()" autocomplete="off" novalidate>
                <div class="m-form__group m-validation m-validation--wizard m-validation--danger">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 inline-label-container l-flex l-flex--align-center">
                            <label for="accesscode" class="m-form__label h-text--left">Access code</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 form-field-container l-flex l-flex--align-center">
                            <input id="accesscode" class="m-form__input" type="tel" [formControl]="form.controls['accesscode']" [textMask]="{mask: accesscodeTextMask}" autofocus>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 col-md-offset-4 col-sm-offset-4 h-text--left">
                            <ng-container *ngIf="submitted">
                                <minster-validation-message *ngIf="form.controls.accesscode.hasError('required')" message="Please enter your access code"></minster-validation-message>
                                <minster-validation-message *ngIf="form.controls.accesscode.hasError('minlength') || form.controls.accesscode.hasError('maxlength')" message="Please enter a valid access code"></minster-validation-message>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="m-form__group m-validation m-validation--wizard m-validation--danger">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 inline-label-container l-flex l-flex--align-center">
                            <label for="email" class="m-form__label h-text--left">Email</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 form-field-container l-flex l-flex--align-center">
                            <input id="email" class="m-form__input" type="text" [formControl]="form.controls['email']">
                        </div>

                        <div class="col-md-12 col-sm-12 col-xs-12 col-md-offset-4 col-sm-offset-4 h-text--left">
                            <ng-container *ngIf="submitted">
                                <minster-validation-message *ngIf="form.controls.email.hasError('required')" message="Please enter your new email address"></minster-validation-message>
                                <minster-validation-message *ngIf="!form.controls.email.hasError('required') && form.controls.email.hasError('email')" message="Please enter a valid email address"></minster-validation-message>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="m-form__group m-validation m-validation--wizard m-validation--danger">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 inline-label-container l-flex l-flex--align-center">
                            <label for="emailAgain" class="m-form__label h-text--left">Confirm email</label>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 form-field-container l-flex l-flex--align-center">
                            <input id="emailAgain" class="m-form__input" type="text" [formControl]="form.controls['emailAgain']">
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 col-md-offset-4 col-sm-offset-4 h-text--left">
                            <ng-container *ngIf="submitted">
                                <minster-validation-message *ngIf="form.hasError('emailsMatch')" message="The emails you have entered do not match. Please try again."></minster-validation-message>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <minster-validation level="info" [messages]="['Please contact Minster Law if you are having difficulties, or request a new access code']" *ngIf="attempts <= 0"></minster-validation>
                <minster-validation [messages]="errors"></minster-validation>
                <minster-button buttonType="submit" id="submit-email-change" [disabled]="submitting" [text]="submitting ? 'Submitting...' : 'Next'" isLarge="true"></minster-button>
                <minster-link [disabled]="submitting || requesting" [text]="requesting ? 'Requesting a new access code...' : 'Request a new access code'" (click)="requestAccessCode()"></minster-link>
            </form>
        </ng-container>
        <ng-template #isCompleted>
            <div id="email-change-confirmation-panel">
                <h1 class="heading">Email changed</h1>
                <p class="c-text-width">Your email has been changed. Please use the link below to log in to your case.</p>
                <minster-link id="log-out-link" [mlRouterLink]="['/auth/logout']" [disabled]="submitting" text="Back to login"></minster-link>
            </div>
        </ng-template>
    </div>
</div>
