import { Component, OnInit, OnDestroy, HostListener, ChangeDetectorRef, Renderer2, ViewChildren, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CaseService } from 'src/app/services/case.service';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from '../../services/notification.service';
import { MenuService, MenuItemType } from 'src/app/services/menu.service';
import { CaseModel } from '../../models/case.model';
import { StateService } from '../../services/state.service';
import { MinsterModalService } from '../../services/minster-modal.service';
import { AlertsMessageModel } from '../../models/alertsMessage.model';
import { StorageService } from '../../services/storage.service';
import { DocumentModel } from '../../models/document.model';
import { MedicalAppointmentModel, Appointment } from '../../models/medical-appointment.model';
import * as moment from 'moment';
import { OnboardingService } from '../../services/onboarding.service';
import { ModalA11yService } from '../../services/modal-a11y.service';
import { MilestoneModel } from 'src/app/models/milestone.model';

@Component({
    selector: 'app-portal',
    templateUrl: './portal.component.html',
    styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit, OnDestroy {

    reference: string;

    case: CaseModel;
    documents: DocumentModel[];
    medicalAppointment: MedicalAppointmentModel;
    milestones: MilestoneModel[];
    subscriptions = [];
    showInterrupts = false;
    disableScroll = false;

    alertMessages: AlertsMessageModel[];

    @ViewChild('main') main: ElementRef;

    constructor(
        private route: ActivatedRoute,
        private caseService: CaseService,
        private authService: AuthService,
        private stateService: StateService,
        public modalService: MinsterModalService,
        private storage: StorageService,
        private onboardingService: OnboardingService,
        private router: Router,
        private modalA11y: ModalA11yService
    ) {
        moment.locale('en-GB');
    }

    get appointments(): Appointment[] {
        return this.medicalAppointment ? this.medicalAppointment.appointment : [];
    }

    ngOnInit() {
        this.case = this.route.snapshot.data.case;
        this.documents = this.route.snapshot.data.documents;
        this.milestones = this.route.snapshot.data.milestones;
        this.medicalAppointment = this.route.snapshot.data.medicalAppointment;

        this.reference = this.case.reference;
        this.caseService.setEndOfClaim(this.case.endOfClaim);
        this.storage.setItem('isendofclaim', JSON.stringify(this.case.endOfClaim));

        // Add Team in to storage so that ConfirmIT can read it
        this.storage.setItem('team', JSON.stringify(this.case.fileHandler.department));

        this.stateService.setReadOnly(this.case.readOnly || this.case.endOfClaim);

        this.modalA11y.closed.subscribe(event => {
            document.body.removeAttribute('class');
            this.disableScroll = false;
        });

        this.modalA11y.opened.subscribe(event => {
            document.body.setAttribute('class', 'u-scroll-body');
            this.disableScroll = true;
        });

        this.subscriptions.push(this.stateService.GetAlerts().subscribe(results => {
            this.alertMessages = results;
        }));

        if (this.onboardingService.isOnboardingCompleted(this.case.id)) {
            setTimeout(() => {
                this.showInterrupts = true;
            }, 1500);
        }

        // subscribe to router for main classes.
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {

                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera.

                let route = this.route;

                while (route.firstChild) {
                    route = route.firstChild;
                }

                if (!this.main || !this.main.nativeElement) {
                    return;
                }

                // Set the class of the main element.
                this.main.nativeElement.removeAttribute('class');
                if (route && route.snapshot.data['mainClass']) {
                    this.main.nativeElement.setAttribute('class', route.snapshot.data['mainClass']);
                } else {
                    this.main.nativeElement.setAttribute('class', 'e-body-margin');
                }
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    isLoggedIn() {
        return this.authService.isLoggedIn;
    }

    loadRecite(): void {
        this.stateService.showRecite();
    }
}
