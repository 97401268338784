import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MilestoneModel } from '../models/milestone.model';

@Injectable({
  providedIn: 'root'
})
export class MilestoneService implements Resolve<MilestoneModel[]> {

    @Output() viewTimelineStarted: EventEmitter<MilestoneModel> = new EventEmitter<MilestoneModel>();

    constructor(
        private http: HttpClient,
        @Inject('API_BASE_URL') private baseUrl: string
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MilestoneModel[] | Observable<MilestoneModel[]> | Promise<MilestoneModel[]> {
        return this.get(route.params.reference);
    }

    startViewTimeline(_milestone: MilestoneModel) {
        this.viewTimelineStarted.emit(_milestone);
    }

    get(reference: string): Observable<MilestoneModel[]> {
        const url = this.baseUrl + 'milestones/' + reference;
        return this.http.get(url, {}) as Observable<MilestoneModel[]>;
    }
}
