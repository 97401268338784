import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MeService } from 'src/app/services/me.service';
import { ContactModel, ContactService } from 'src/app/services/contact.service';
import { AppInsightsService } from 'src/app/services/appinsights.service';

@Component({
    selector: 'app-forgotten-password',
    templateUrl: './forgotten-password.component.html',
    styleUrls: ['./forgotten-password.component.scss'],
    host: { 'style': 'width: 100%', 'display': 'block' }
})
export class ForgottenPasswordComponent {

    form: UntypedFormGroup;
    submitted: boolean = false;
    submitting: boolean = false;
    errors: string[] = [];
    completed: boolean = false;
    contact: ContactModel;

    constructor(
        private router: Router,
        private fb: UntypedFormBuilder,
        private meService: MeService,
        private contactService: ContactService,
        private appInsightsService: AppInsightsService
    ) {
        this.contact = this.contactService.get();

        this.form = fb.group({
            'username': [null, [Validators.email, Validators.required]]
        });
    }

    submit(form: UntypedFormGroup) {
        this.submitted = true;
        if (form.invalid) { return; }
        form.disable();
        this.submitting = true;
        this.errors = [];

        this.meService.forgottenPasswordSendEmail(form.value.username).subscribe(
            result => {
                this.completed = true;
                this.submitting = false;
                this.appInsightsService.logCustomEvent('Password Reset', 'succeeded', form.value.username);
            },
            error => {
                form.enable();
                const response = error.error;
                if (response && response.IsDisabled) {
                    this.errors.push(
                        `We are unable to reset your password, please contact us on ${this.contact.telephone}.`
                    );
                } else {
                    this.completed = true;
                }

                this.submitting = false;
                this.appInsightsService.logCustomEvent('Password Reset', 'error', form.value.username);
            }
        );
    }
}
