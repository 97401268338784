import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CaseModel } from '../models/case.model';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MedicalAppointmentModel } from '../models/medical-appointment.model';

@Injectable({
    providedIn: 'root'
})
export class MedicalAppointmentService implements Resolve<MedicalAppointmentModel> {

    constructor(
        private http: HttpClient,
        @Inject('API_BASE_URL') private baseUrl: string
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MedicalAppointmentModel | Observable<MedicalAppointmentModel> | Promise<MedicalAppointmentModel> {
        return this.get(route.params.reference);
    }

    get(reference: string): Observable<MedicalAppointmentModel> {
        const url = this.baseUrl + 'medicals/getmedical/' + reference;
        return this.http.get(url, {}) as Observable<MedicalAppointmentModel>;
    }

}
