import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { CaseModel } from '../../models/case.model';
import { AppInsightsService } from '../../services/appinsights.service';
import { Router } from '@angular/router';
import { OnboardingService } from '../../services/onboarding.service';
import { ModalA11yService } from '../../services/modal-a11y.service';
import { StateService } from 'src/app/services/state.service';
import { StepModel } from '../../models/step.model';

interface step {
    imageUrlDesktop: string;
    imageUrlMobile: string;
    title: string;
    description: string;
}

@Component({
    selector: 'onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

    @Input('case') case: CaseModel;
    @Input('steps') inputSteps: StepModel[];
    visible = false;
    subscriptions = [];
    steps: StepModel[] = [
        {
            imageUrlDesktop: '/images/desktop-01-welcome-end.png',
            imageUrlMobile: '/images/mobile-01-welcome-end.png',
            title: 'Welcome',
            description: '<p class="c-text-width c-text-dark c-text-align-center">Welcome to Ink. Life doesn\'t slow down after an accident which is why we\'ve made progressing your claim simple and easy with our online portal. To help you get started here is a quick introduction to some of the features available to you.</p>'
        },
        {
            imageUrlDesktop: '/images/desktop-02-dashboard.png',
            imageUrlMobile: '/images/mobile-02-dashboard.png',
            title: 'Dashboard',
            description: '<p class="c-text-width c-text-dark c-text-align-center">The dashboard is the central hub for managing your claim online. Here you can access details about your accident, losses, medical, and as your claim progresses, information about your settlement.</p>'
        },
        {
            imageUrlDesktop: '/images/desktop-03-navigation.png',
            imageUrlMobile: '/images/mobile-03-navigation.png',
            title: 'Navigation',
            description: '<p class="c-text-width c-text-dark c-text-align-center">Moving around is easy and our navigation provides quick access to a number of areas important to you.</p>'
        },
        {
            imageUrlDesktop: '/images/desktop-10-progress.jpg',
            imageUrlMobile: '/images/mobile-10-progress.jpg',
            title: 'Milestones',
            description: '<p class="c-text-width c-text-dark c-text-align-center">The progress bar presents all the major milestones in your claims journey, keeping you up to date on your claim and any actions you may need to complete. Skip ahead in the timeline to understand upcoming stages or click the information icon to access descriptions and FAQs to learn about medicals, settlement and more.</p>'
        },
        {
            imageUrlDesktop: '/images/desktop-04-notification.png',
            imageUrlMobile: '/images/mobile-04-notification.png',
            title: 'Notifications',
            description: '<p class="c-text-width c-text-dark c-text-align-center">Our notifications area helps you keep up to date with your claim.</p>'
        },
        {
            imageUrlDesktop: '/images/desktop-05-claims.png',
            imageUrlMobile: '/images/mobile-05-claims.png',
            title: 'Claim details',
            description: '<p class="c-text-width c-text-dark c-text-align-center">You can view information you\'ve provided about you and your claim here. Each area can be expanded by clicking on the arrow to view the specific details around you and your injuries, the accident and the other people involved.</p>'
        },
        {
            imageUrlDesktop: '/images/desktop-06-losses.png',
            imageUrlMobile: '/images/mobile-06-losses.png',
            title: 'Losses',
            description: '<p class="c-text-width c-text-dark c-text-align-center">As a result of your accident you may find that you incur financial losses. Here you can upload and view details of your losses at a time that suits you.</p>'
        },
        {
            imageUrlDesktop: '/images/desktop-07-medical.png',
            imageUrlMobile: '/images/mobile-07-medical.png',
            title: 'Medical',
            description: '<p class="c-text-width c-text-dark c-text-align-center">The medical appointment is an important stage of your claim journey. You can view contact details of the medical agency assigned to your case as well as review and approve your medical report when available. We have included information about how to prepare for your medical appointment. So you know exactly what to expect.</p>'
        },
        {
            imageUrlDesktop: '/images/desktop-08-docs.png',
            imageUrlMobile: '/images/mobile-08-docs.png',
            title: 'Documents',
            description: '<p class="c-text-width c-text-dark c-text-align-center">Your document centre is where you will find important correspondance about your claim and where you can also send us any supporting documents. This is also where your welcome pack is stored. It’s important you read these documents and understand them.</p>'
        },
        {
            imageUrlDesktop: '/images/desktop-09-faqs.png',
            imageUrlMobile: '/images/mobile-09-faqs.png',
            title: 'FAQs',
            description: '<p class="c-text-width c-text-dark c-text-align-center">You may have questions throughout your claim and you can use our FAQs centre to search information on a number of topics including medical appointments and losses.</p>'
        },
        {
            imageUrlDesktop: '/images/desktop-01-welcome-end.png',
            imageUrlMobile: '/images/mobile-01-welcome-end.png',
            title: 'Thank you',
            description: '<p class="c-text-width c-text-dark c-text-align-center">Thank you for taking the time to learn more about Ink.</p>'
        }, ];
    page = 0;

    constructor(
        private onboardingService: OnboardingService,
        private appInsights: AppInsightsService,
        private router: Router,
        private modalA11y: ModalA11yService,
        private stateService: StateService) { }

    get currentStep(): step {
        return this.steps[this.page];
    }

    ngOnInit() {

        if (this.onboardingService.isOnboardingCompleted(this.case.id)) {
            return;
        }

        if (this.stateService.getSupressOnBoarding()) {
            return;
        }

        if (this.inputSteps && this.inputSteps.length > 0) {
            this.steps = this.inputSteps;
        }

        this.onboardingService.setCompleted(this.case.id);

        this.appInsights.logComplexEvent('Onboarding step read', { onboardingStep: this.currentStep.title });
        setTimeout(() => {
            this.visible = true;
            this.modalA11y.open(this);
        }, 100);
    }

    goToStep(index: number) {
        this.page = index;
    }

    next() {
        this.page += 1;
        this.appInsights.logComplexEvent('Onboarding step read', { onboardingStep: this.currentStep.title });
        }

    skip() {
        this.appInsights.logComplexEvent('Onboarding skipped', { onboardingStep: this.currentStep.title });
        this.visible = false;
        this.modalA11y.close(this);
    }

    welcomepack() {
        this.appInsights.logComplexEvent('Onboarding completed', { destination: 'documents' });
        this.visible = false;
        this.modalA11y.close(this);
        this.router.navigate(['/portal', this.case.reference, 'documents']);
    }

    done() {
        this.appInsights.logComplexEvent('Onboarding completed', { destination: 'dashboard' });
        this.visible = false;
        this.modalA11y.close(this);
    }

}
