import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Appointment, MedicalAppointmentModel } from '../../models/medical-appointment.model';

@Component({
    selector: 'appointment-summary, [appointment-summary]',
    templateUrl: './appointment-summary.component.html',
    styleUrls: ['./appointment-summary.component.scss']
})
export class AppointmentSummaryComponent {

    @Input('medical') medical: MedicalAppointmentModel;
    @Input('index') index: number;
    @Input('appointment') appointment: Appointment;
    @Input('selected') isSelected: boolean;
    @Output('directionsClicked') directionsClicked = new EventEmitter<Appointment>();
    @Output('preparationsClicked') preparationsClicked = new EventEmitter<Appointment>();
    @Output('rescheduleClicked') rescheduleClicked = new EventEmitter<Appointment>();
    @Output('selectClicked') selectClicked = new EventEmitter<Appointment>();

    constructor() { }

    get address(): string {
        if (!this.appointment) { return ''; }
        return [
            this.appointment.addressLine1,
            this.appointment.addressLine2,
            this.appointment.addressLine3,
            this.appointment.addressLine4,
            this.appointment.addressLine5,
            this.appointment.postcode]
            .filter(p => p.trim().length > 0)
            .join(', ');
    }

    select() {
        this.selectClicked.emit(this.appointment);
    }

    reschedule() {
        this.rescheduleClicked.emit(this.appointment);
    }

    directions() {
        this.directionsClicked.emit(this.appointment);
    }

    preparations() {
        this.preparationsClicked.emit(this.appointment);
    }

}
