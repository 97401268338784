<ng-container *ngIf="href; else noHref">
    <a disableTabOnModal [ngClass]="{'underline': underline, 'c-text-link--white' : !textLinkDark}" [attr.href]="href" [attr.id]="id" class="c-text-link" (click)="wasClicked()">
        {{text}}
    </a>
</ng-container>
<ng-template #noHref>
    <a disableTabOnModal [ngClass]="{'underline': underline, 'c-text-link--white' : !textLinkDark}" href="javascript:undefined" [attr.id]="id" [routerLink]="mlRouterLink" class="c-text-link" (click)="wasClicked()">
        {{text}}
    </a>
</ng-template>
