<div class="u-mtop-xs">
    <h1 class="c-heading c-heading-uppercase">My Documents</h1>
    <div *ngIf="case.readOnly" id="loss-instructions">
        <p class="c-text-width">Your account is now read only.</p>
        <p class="c-text-width">This means that you can still view and download any documents already on your portal, but you are unable to upload any new documents to us, and we won't upload any more to you. Your file handler will personally send anything to you. If you have outstanding documents to approve, please <a disableTabOnModal routerLink="../../contact" class="c-text-link c-text-link--white underline">contact your handler</a> with feedback.</p>
    </div>
    <ng-container *ngIf="!case.readOnly">
        <p class="c-text-width" *ngIf="!isSettled">All documentation relating to your claim can be found here. You can also upload additional documentation when required. </p>
        <p class="c-text-width" *ngIf="isSettled">Your case has now been settled and you can no longer add documents. If you have any questions please <a class="c-text-link c-text-link--white underline" [routerLink]="['/portal', case.reference, 'contact']">contact us</a>.</p>
    </ng-container>
    <minster-button id="upload-document-button" *ngIf="!case.readOnly" buttonClass="is-fullwidth m-b-20" (click)="addDocument()" isPrimary="true" text="Upload document" [disabled]="isSettled" [isLong]="true"></minster-button>
</div>

<div class="c-tab-buttons-wrapper u-mtop-sm">
    <button role="tab" id="minster-tab-button" [attr.aria-selected]="displayFilter == 1" aria-label="Filter by documents sent to you from Minster Law" disableTabOnModal class="c-tab-buttons c-tab-buttons--left" [ngClass]="{'c-tab-buttons--active' : displayFilter === 1}" (click)="displayFilter = 1">Sent to you</button>
    <button role="tab" id="client-tab-button" [attr.aria-selected]="displayFilter == 2" aria-label="Filter by documents sent to Minster Law from you" disableTabOnModal class="c-tab-buttons c-tab-buttons--right" [ngClass]="{'c-tab-buttons--active' : displayFilter === 2}" (click)="displayFilter = 2">Received from you</button>
</div>

<div [hidden]="displayFilter === 2">
    <minster-expandable-panel heading="Welcome pack" [expanded]="expandWelcomePack" icon="user" id="button-welcome-pack" aria-label="Welcome pack documents">
        <div class="c-table-list">
            <ul class="c-table-list__content">
                <li *ngFor="let doc of welcomePackSentToYou; let i = index;" [attr.id]="'court-' + i" class="c-table-list__item c-table-list__item--no-border u-flex-col u-padding-left-none">
                    <h2 class="c-heading c-heading-dark">{{getWelcomePackDisplayName(doc.name)}}</h2>
                    <div class="c-table-list__title u-margin-none">
                        <minster-button [disabled]="isDownloadingDoc(doc)" [isLoading]="isDownloadingDoc(doc)" (click)="downloadDocument(doc)" isPrimary="true" text="Download"></minster-button>
                    </div>
                </li>
            </ul>
        </div>
    </minster-expandable-panel>
</div>


<div [hidden]="displayFilter === 2">
    <minster-expandable-panel heading="Medical" icon="briefcase-medical" id="button-medical" aria-label="Medical documents">

        <p class="c-text-dark" id="no-sent-to-you-medical-reports" *ngIf="medicalsSentToYou.length === 0">You currently don't have any medical reports.</p>

        <div class="c-table-list" *ngIf="medicalsSentToYou.length > 0">
            <ul class="c-table-list__content">
                <li *ngFor="let medical of medicalsSentToYou; let i = index;" [attr.id]="'doc-' + i" class="c-table-list__item c-table-list__item--no-border u-flex-col u-padding-left-none">
                    <div class="c-table-list__title u-margin-none ">
                        <h2 class="c-heading c-heading-dark">{{medical.name}}</h2>
                        <span class="date">{{getFriendlyCreateDate(medical)}}</span>
                    </div>
                    <div class="c-table-list__title u-margin-none" *ngIf="medical.status.toLowerCase() === 'rejected'">
                        <p class="c-text-dark rejected-text">You rejected this report {{getFriendlyUpdateDate(medical)}}</p>
                    </div>
                    <div class="c-table-list__title u-margin-none" *ngIf="medical.status.toLowerCase() === 'approved'">
                        <p class="c-text-dark approve text">You approved this report {{getFriendlyUpdateDate(medical)}}</p>
                    </div>
                    <div class="c-table-list__title u-margin-none" *ngIf="medical.notes">
                        <p class="c-text-dark c-text-width notes-text" [innerHTML]="medical.notes"></p>
                    </div>
                    <div class="c-table-list__body u-mtop-ism">
                        <div>
                            <minster-button buttonClass="u-mright-sm review" [ariaLabel]="'Review medical report: ' + medical.name" class="medical-approval-button" isPrimary="true" text="Review" [disabled]="isDownloadingDoc(medical)" [isLoading]="isDownloadingDoc(medical)" (click)="downloadDocument(medical)"></minster-button>
                            <minster-button buttonClass="u-mright-sm approve" [ariaLabel]="'Accept medical report: ' + medical.name" *ngIf="medical.status.toLowerCase() === 'new' && medical.requiresApproval" class="medical-approval-button" isWarning="true" text="Accept" (click)="accept(medical)"></minster-button>
                            <minster-button buttonClass="reject" [ariaLabel]="'Reject medical report: ' + medical.name" *ngIf="medical.status.toLowerCase() === 'new' && medical.requiresApproval" class="medical-approval-button" isDanger="true" text="Reject" (click)="reject(medical)"></minster-button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </minster-expandable-panel>
</div>

<div [hidden]="displayFilter === 2">
    <minster-expandable-panel heading="Court" icon="gavel" id="button-court" aria-label="Court documents">
        <p class="c-text-dark" id="no-sent-to-you-court" *ngIf="courtSentToYou.length === 0">You currently don't have any documents relating to the court.</p>
        <div class="c-table-list">
            <ul class="c-table-list__content">
                <li *ngFor="let doc of courtSentToYou; let i = index;" [attr.id]="'court-' + i" class="c-table-list__item c-table-list__item--no-border u-flex-col u-padding-left-none">

                        <h2 class="c-heading c-heading-dark">{{doc.name}}</h2>
                        <p class="c-text-dark">{{getFriendlyCreateDate(doc)}}</p>

                    <p class="c-text-dark c-text-width" *ngIf="doc.notes" [innerHTML]="doc.notes"></p>
                    <div class="c-table-list__title u-margin-none">
                        <p class="c-text-dark c-text-width" *ngIf="doc.notes" [innerHTML]="doc.notes"></p>
                        <minster-button [disabled]="isDownloadingDoc(doc)" [isLoading]="isDownloadingDoc(doc)" (click)="downloadDocument(doc)" isPrimary="true" text="Download"></minster-button>
                    </div>
                </li>
            </ul>
        </div>
    </minster-expandable-panel>
</div>
<div [hidden]="displayFilter === 2">
    <minster-expandable-panel heading="Settlement" icon="money-bill-wave" id="button-settlement" aria-label="Settlement documents">
        <p class="c-text-dark" id="no-sent-to-you-settlement" *ngIf="settlementSentToYou.length === 0">You currently don't have any documents relating to settlement.</p>
        <div class="c-table-list">
            <ul class="c-table-list__content">
                <li *ngFor="let doc of settlementSentToYou; let i = index;" [attr.id]="'set-' + i" class="c-table-list__item c-table-list__item--no-border u-flex-col u-padding-left-none">

                        <h2 class="c-heading c-heading-dark">{{doc.name}}</h2>
                        <p class="c-text-dark">{{getFriendlyCreateDate(doc)}}</p>

                    <p class="c-text-dark c-text-width" *ngIf="doc.notes" [innerHTML]="doc.notes"></p>
                    <div class="c-table-list__title u-margin-none">
                        <minster-button [disabled]="isDownloadingDoc(doc)" [isLoading]="isDownloadingDoc(doc)" (click)="downloadDocument(doc)" isPrimary="true" text="Download"></minster-button>
                    </div>
                </li>
            </ul>
        </div>
    </minster-expandable-panel>
</div>
<div [hidden]="displayFilter === 2">
    <minster-expandable-panel heading="Liability" icon="clipboard" id="button-liability" aria-label="Liability documents">
        <p class="c-text-dark" id="no-sent-to-you-liability" *ngIf="liabilitySentToYou.length === 0">You currently don't have any documents relating to liability evidence.</p>
        <div class="c-table-list">
            <ul class="c-table-list__content">
                <li *ngFor="let doc of liabilitySentToYou; let i = index;" [attr.id]="'liab-' + i" class="c-table-list__item c-table-list__item--no-border u-flex-col u-padding-left-none">

                        <h2 class="c-heading c-heading-dark">{{doc.name}}</h2>
                        <p class="c-text-dark">{{getFriendlyCreateDate(doc)}}</p>

                    <p class="c-text-dark c-text-width" *ngIf="doc.notes" [innerHTML]="doc.notes"></p>
                    <div class="c-table-list__title u-margin-none">
                        <minster-button [disabled]="isDownloadingDoc(doc)" [isLoading]="isDownloadingDoc(doc)" (click)="downloadDocument(doc)" isPrimary="true" text="Download"></minster-button>
                    </div>
                </li>
            </ul>
        </div>
    </minster-expandable-panel>
</div>
<div [hidden]="displayFilter === 1">
    <minster-expandable-panel heading="Medical" icon="briefcase-medical" id="button-medical-client" aria-label="Medical documents">
        <p class="c-text-dark" id="no-received-from-you-medical" *ngIf="medicalsReceivedFromYou.length === 0">You currently have not sent any documents relating to your medicals.</p>
        <div class="c-table-list">
            <ul class="c-table-list__content">
                <li *ngFor="let doc of medicalsReceivedFromYou;let i = index;" [attr.id]="'doc-client-' + i" class="c-table-list__item c-table-list__item--no-border u-flex-col u-padding-left-none">

                        <h2 class="c-heading c-heading-dark">{{doc.name}}</h2>
                        <p class="c-text-dark">{{getFriendlyCreateDate(doc)}}</p>

                    <p class="c-text-dark c-text-width" *ngIf="doc.notes" [innerHTML]="doc.notes"></p>
                    <div class="c-table-list__title u-margin-none">
                        <minster-button [disabled]="isDownloadingDoc(doc)" [isLoading]="isDownloadingDoc(doc)" (click)="downloadDocument(doc)" isPrimary="true" text="Download"></minster-button>
                    </div>
                </li>
            </ul>
        </div>
    </minster-expandable-panel>
</div>
<div [hidden]="displayFilter === 1">
    <minster-expandable-panel heading="Court" icon="gavel" id="button-court-client" aria-label="Court documents">
        <p id="no-received-from-you-court" *ngIf="courtReceivedFromYou.length === 0">You currently have not sent any documents relating to court proceedings.</p>
        <div class="c-table-list">
            <ul class="c-table-list__content">
                <li *ngFor="let doc of courtReceivedFromYou" class="c-table-list__item c-table-list__item--no-border u-flex-col u-padding-left-none">

                        <h2 class="c-heading c-heading-dark">{{doc.name}}</h2>
                        <p class="c-text-dark">{{getFriendlyCreateDate(doc)}}</p>

                    <p class="c-text-dark c-text-width" *ngIf="doc.notes" [innerHTML]="doc.notes"></p>
                    <div class="c-table-list__title u-margin-none">
                        <minster-button [disabled]="isDownloadingDoc(doc)" [isLoading]="isDownloadingDoc(doc)" (click)="downloadDocument(doc)" isPrimary="true" text="Download"></minster-button>
                    </div>
                </li>
            </ul>
        </div>
    </minster-expandable-panel>
</div>
<div [hidden]="displayFilter === 1">
    <minster-expandable-panel heading="Settlement" icon="money-bill-wave" id="button-settlement-client" aria-label="Settlement documents">
        <p class="c-text-dark" id="no-received-from-you-settlement" *ngIf="settlementReceivedFromYou.length === 0">You currently have not sent any documents relating to settlement.</p>
        <div class="c-table-list">
            <ul class="c-table-list__content">
                <li *ngFor="let doc of settlementReceivedFromYou; let i = index" [attr.id]="'set-client-' + i" class="c-table-list__item c-table-list__item--no-border u-flex-col u-padding-left-none">
                    <h2 class="c-heading c-heading-dark">{{doc.name}}</h2>
                    <p class="c-text-dark">{{getFriendlyCreateDate(doc)}}</p>
                    <p class="c-text-dark c-text-width" *ngIf="doc.notes" [innerHTML]="doc.notes"></p>
                    <div class="c-table-list__title u-margin-none">
                        <minster-button [disabled]="isDownloadingDoc(doc)" [isLoading]="isDownloadingDoc(doc)" (click)="downloadDocument(doc)" isPrimary="true" text="Download"></minster-button>
                    </div>
                </li>
            </ul>
        </div>
    </minster-expandable-panel>
</div>
<div [hidden]="displayFilter === 1">
    <minster-expandable-panel heading="Liability" icon="clipboard" id="button-liability-client" aria-label="Liability documents">
        <p class="c-text-dark" id="no-received-from-you-liability" *ngIf="liabilityReceivedFromYou.length === 0">You currently have not sent any documents relating to liability evidence.</p>
        <div class="c-table-list">
            <ul class="c-table-list__content">
                <li *ngFor="let doc of liabilityReceivedFromYou" class="c-table-list__item c-table-list__item--no-border u-flex-col u-padding-left-none">

                        <h2 class="c-heading c-heading-dark">{{doc.name}}</h2>
                        <p class="c-text-dark">{{getFriendlyCreateDate(doc)}}</p>

                    <p class="c-text-dark c-text-width" *ngIf="doc.notes" [innerHTML]="doc.notes"></p>
                    <div class="c-table-list__title u-margin-none">
                        <minster-button [disabled]="isDownloadingDoc(doc)" [isLoading]="isDownloadingDoc(doc)" (click)="downloadDocument(doc)" isPrimary="true" text="Download"></minster-button>
                    </div>
                </li>
            </ul>
        </div>
    </minster-expandable-panel>
</div>
