import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FaqResponseModel } from '../models/faq.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

    constructor(
        private http: HttpClient,
        @Inject('API_BASE_URL') private baseUrl: string
    ) { }

    ask(question: string): Observable<FaqResponseModel> {
        const url = this.baseUrl + 'faq/ask';
        return this.http.post(url, { question }) as Observable<FaqResponseModel>;
    }
}
