<div class="c-page-container u-scroll-x">
    <header class="c-main-nav-wrapper">
        <nav minster-menu class="c-main-nav" [case]="case"></nav>
        <div class="c-top-nav-mobile">
            <!-- .is-hidden hides back button using opacity:0 -->
            <div class="c-top-nav-mobile__item--back u-is-hidden">
                <button class="c-ic-button c-top-nav-mobile__btn-back" role="button" aria-label="back">
                    <svg class="c-ic c-ic--sm">
                        <use xlink:href="#ic-back" href="#ic-back"></use>
                    </svg>
                </button>
            </div>
            <div class="c-top-nav-mobile__item--logo">
                <a class="c-top-nav-mobile__link">
                    <svg class="c-ic-purple c-top-nav-mobile__logo-svg">
                        <use xlink:href="#ic-logo-2" href="#ic-logo-2"></use>
                    </svg>
                </a>
                <img class="recite-link" alt="accessibility tools" (click)="loadRecite()" src="/images/recite_icon.png">
            </div>
            <!-- .is-hidden hides close button using opacity:0 -->
            <div class="c-top-nav-mobile__item--close u-is-hidden">
                <button class="c-ic-button c-top-nav-mobile__btn-close" role="button" aria-label="close">
                    <svg class="c-ic c-ic--sm">
                        <use xlink:href="#ic-close" href="#ic-close"></use>
                    </svg>
                </button>
            </div>
        </div>
    </header>

    <main #main class="e-body-margin main-content">
        <router-outlet #route="outlet"></router-outlet>
    </main>

    <div minster-footer></div>

    <div class="footer-buffer">

    </div>

    <footer>
        <div minster-menu [case]="case" [isMobile]="true"></div>
    </footer>


    <onboarding [case]="case"></onboarding>

    <minster-alert-messages [case]="case"></minster-alert-messages>

    <interrupt *ngIf="showInterrupts" [case]="case" [documents]="documents" [appointments]="appointments" [milestones]="milestones"></interrupt>

    <medical-report-approval></medical-report-approval>

    <add-loss-legacy></add-loss-legacy>

    <add-document></add-document>
</div>
