import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    host: { 'style': 'width: 100%', 'display': 'block' }
})
export class LogoutComponent implements OnInit {

    returnUrl: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private storage: StorageService
    ) { }

    ngOnInit() {

        this.storage.removeItem('user');
        this.storage.removeItem('username');
        this.storage.removeItem('isendofclaim');
        this.storage.removeItem('token');

        this.authService.isLoggedIn = false;
        this.authService.isLoggingOut.next(null);

        // get return url from route parameters or default to null
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;

        setTimeout(() => {
            console.log("this.returnUrl", this.returnUrl);
            this.router.navigate(['auth/login'], { queryParams: { returnUrl: this.returnUrl } });
        }, 500);
    }
}
