import { Injectable, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TokenModel } from '../models/auth.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    isLoggedIn = true;

    isLoggingOut: Subject<any> = new Subject();

    constructor(
        private http: HttpClient,
        @Inject('API_BASE_URL') private baseUrl: string
    ) { }

    authenticate(username: string, password: string): Observable<TokenModel> {
        const url = this.baseUrl + 'auth/authenticate';
        return this.http.post(url, {
            username: username,
            password: password
        }) as Observable<TokenModel>;
    }

    refresh(token: string): Observable<TokenModel> {
        const url = this.baseUrl + 'auth/refresh';
        return this.http.post(url, {
            token: token
        }) as Observable<TokenModel>;
    }
}
