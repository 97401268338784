import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, UntypedFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TextMaskModule } from 'angular2-text-mask';
import { GoogleMapsModule } from '@angular/google-maps';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MinsterMenuComponent } from './components/minster-menu/minster-menu.component';
import { MenuPanelComponent } from './components/menu-panel/menu-panel.component';
import { WelcomePackComponent } from './components/welcome-pack/welcome-pack.component';
import { FaqComponent } from './components/faq/faq.component';
import { LogoutComponent } from './components/logout/logout.component';
import { PortalComponent } from './components/portal/portal.component';
import { AuthComponent } from './components/auth/auth.component';
import { LoginComponent } from './components/login/login.component';
import { NoneComponent } from './components/none/none.component';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { HomeComponent } from './components/home/home.component';
import { DetailsComponent } from './components/details/details.component';
import { PersonalDetailsComponent } from './components/personal-details/personal-details.component';
import { AccidentDetailsComponent } from './components/accident-details/accident-details.component';
import { ThirdPartyDetailsComponent } from './components/third-party-details/third-party-details.component';
import { InjuryDetailsComponent } from './components/injury-details/injury-details.component';
import { RegisterComponent } from './components/register/register.component';
import { PostcodeFormatDirective } from './directives/postcode-format.directive';
import { MinsterButtonComponent } from './components/minster-button/minster-button.component';
import { MinsterLinkComponent } from './components/minster-link/minster-link.component';
import { MinsterValidationComponent } from './components/minster-validation/minster-validation.component';
import { MinsterValidationMessageComponent } from './components/minster-validation-message/minster-validation-message.component';
import { ForgottenPasswordComponent } from './components/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { BadLinkComponent } from './components/bad-link/bad-link.component';
import { NoCasesComponent } from './components/no-cases/no-cases.component';
import { MinsterModalComponent } from './components/minster-modal/minster-modal.component';
import { LoginFailComponent } from './components/login-fail/login-fail.component';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { ForbidRegisterPageComponent } from './components/forbid-register-page/forbid-register-page.component';
import { RegisterExpiryPageComponent } from './components/register-expiry-page/register-expiry-page.component';
import { GuidExpiredComponent } from './components/guid-expired/guid-expired.component';
import { LossesComponent } from './components/losses/losses.component';
import { FutureAppointmentsComponent } from './components/future-appointments/future-appointments.component';
import { PastAppointmentsComponent } from './components/past-appointments/past-appointments.component';
import { PasswordStrengthValidatorComponent } from './components/password-strength-validator/password-strength-validator.component';
import { ClaimSettledComponent } from './components/claim-settled/claim-settled.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { ChangeEmailSendComponent } from './components/change-email-send/change-email-send.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MinsterAlertComponent } from './components/minster-alert/minster-alert.component';
import { ClaimComponent } from './components/claim/claim.component';
import { MedicalComponent } from './components/medical/medical.component';
import { MinsterExpandablePanelComponent } from './components/minster-expandable-panel/minster-expandable-panel.component';
import { LossComponent } from './components/loss/loss.component';
import { AppointmentSummaryComponent } from './components/appointment-summary/appointment-summary.component';
import { ActiveModalsComponent } from './components/active-modals/active-modals.component';
import { ConfigurationService } from './services/configuration.service';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationRowComponent } from './components/notification-row/notification-row.component';
import { MinsterAlertMessageComponent } from './components/minster-alert-message/minster-alert-message.component';
import { MinsterAlertMessagesComponent } from './components/minster-alert-messages/minster-alert-messages.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { InterruptComponent } from './components/interrupt/interrupt.component';
import { SettlementComponent } from './components/settlement/settlement.component';
import { MedicalReportApprovalComponent } from './components/medical-report-approval/medical-report-approval.component';
import { AddLossComponent } from './components/add-loss/add-loss.component';
import { AddDocumentComponent } from './components/add-document/add-document.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { ContactComponent } from './components/contact/contact.component';
import { ShowHidePasswordButtonComponent } from './components/show-hide-password-button/show-hide-password-button.component';
import { UnauthenticatedHeaderComponent } from './components/unauthenticated-header/unauthenticated-header.component';
import { UnauthenticatedImageContainerComponent } from './components/unauthenticated-image-container/unauthenticated-image-container.component';
import { PagerComponent } from './components/pager/pager.component';
import { DisableTabOnModalComponent } from './components/disable-tab-on-modal/disable-tab-on-modal.component';
import { MinsterFooterComponent } from './components/minster-footer/minster-footer.component';
import { RouterLinkAriaActiveDirective } from './directives/router-link-aria-active.directive';
import { InterruptLoginComponent } from './components/interrupt-login/interrupt-login.component';
import { LoginDisabledComponent } from './components/login-disabled/login-disabled.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { LossesLegacyComponent } from './components/losses-legacy/losses-legacy.component';
import { LossLegacyComponent } from './components/loss-legacy/loss-legacy.component';
import { AddLossLegacyComponent } from './components/add-loss-legacy/add-loss-legacy.component';
import { ClaimProgressComponent } from './components/claim-progress/claim-progress.component';
import { MedicalAgencyCardComponent } from './components/medical-agency-card/medical-agency-card.component';
import { environment } from '../environments/environment';
import { NegotiationsComponent } from './components/negotiations/negotiations.component';
import { CookieModalComponent } from './components/cookie-modal/cookie-modal.component';


@NgModule({
    declarations: [
        AppComponent,
        MinsterMenuComponent,
        WelcomePackComponent,
        FaqComponent,
        LogoutComponent,
        PortalComponent,
        HomeComponent,
        AuthComponent,
        LoginComponent,
        NoneComponent,
        DetailsComponent,
        PersonalDetailsComponent,
        AccidentDetailsComponent,
        ThirdPartyDetailsComponent,
        InjuryDetailsComponent,
        RegisterComponent,
        PostcodeFormatDirective,
        MinsterButtonComponent,
        MinsterLinkComponent,
        MinsterValidationComponent,
        MinsterValidationMessageComponent,
        ForgottenPasswordComponent,
        ResetPasswordComponent,
        BadLinkComponent,
        MenuPanelComponent,
        NoCasesComponent,
        MinsterModalComponent,
        LoginFailComponent,
        ForbidRegisterPageComponent,
        RegisterExpiryPageComponent,
        GuidExpiredComponent,
        LossesComponent,
        FutureAppointmentsComponent,
        PastAppointmentsComponent,
        PasswordStrengthValidatorComponent,
        ClaimSettledComponent,
        ChangeEmailComponent,
        ChangeEmailSendComponent,
        MinsterAlertComponent,
        ClaimComponent,
        MedicalComponent,
        MinsterExpandablePanelComponent,
        LossComponent,
        AppointmentSummaryComponent,
        ActiveModalsComponent,
        NotificationsComponent,
        NotificationRowComponent,
        MinsterAlertMessageComponent,
        MinsterAlertMessagesComponent,
        OnboardingComponent,
        InterruptComponent,
        SettlementComponent,
        MedicalReportApprovalComponent,
        AddLossComponent,
        AddDocumentComponent,
        DocumentsComponent,
        ContactComponent,
        ShowHidePasswordButtonComponent,
        UnauthenticatedHeaderComponent,
        UnauthenticatedImageContainerComponent,
        PagerComponent,
        DisableTabOnModalComponent,
        MinsterFooterComponent,
        RouterLinkAriaActiveDirective,
        InterruptLoginComponent,
        LoginDisabledComponent,
        LossesLegacyComponent,
        LossLegacyComponent,
        AddLossLegacyComponent,
        ClaimProgressComponent,
        MedicalAgencyCardComponent,
        NegotiationsComponent,
        CookieModalComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TextMaskModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        ServiceWorkerModule.register('sw-master.js', { enabled: environment.production, registrationStrategy: 'registerImmediately'}),
        FontAwesomeModule,
        GoogleMapsModule,
        LoadingBarHttpClientModule,
        MatDatepickerModule,
        MatInputModule,
        MatSelectModule,
        MatNativeDateModule
    ],
    providers: [
        Title,
        UntypedFormBuilder,
        PendingChangesGuard,
        ReactiveFormsModule,
        ConfigurationService,
        CookieService,
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true
        },
        {
            provide: 'BASE_URL',
            useFactory: () => {
                return document.getElementsByTagName('base')[0].href;
            }
        },
        {
            provide: 'API_BASE_URL',
            useFactory: () => {
                return document.getElementsByTagName('base')[0].href + 'api/';
            }
        },
        {
            provide: 'PUSH_API_BASE_URL',
            useFactory: () => {
                return document.getElementsByTagName('base')[0].href + 'api/';
            }
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (appConfig: ConfigurationService) => {
                return () => {
                    return appConfig.loadConfig();
                };
            },
            multi: true,
            deps: [ConfigurationService]
        },
        {
            provide: 'BUILD',
            useFactory: () => {
                const build = require('../../package.json');
                return {
                    name: build.name,
                    version: build.version
                };
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
