<h3 class="c-heading c-heading-purple">Injury details</h3>
<dl class="c-details-table">
    <div class="c-details-table__item">
        <dt class="c-details-table__title">Description of injury</dt>
        <dd class="c-details-table__details data-hj-suppress" id="injury-details-description">{{case.injury.description ? case.injury.description : 'n/a'}}</dd>
    </div>
    <div class="c-details-table__item">
        <dt class="c-details-table__title">Pre-existing injury details</dt>
        <dd class="c-details-table__details data-hj-suppress" id="injury-details-pre-existing">{{case.injury.preExistingInjuryDetails ? case.injury.preExistingInjuryDetails : 'n/a'}}</dd>
    </div>
    <ng-container *ngFor="let item of case.medicals; index as i">
        <div class="c-details-table__item">
            <dt class="c-details-table__title">Treatment sought</dt>
            <dd [id]="'injury-details-treatment-' + i" class="c-details-table__details injury-details-treatment data-hj-suppress" [ngStyle]="{'text-transform': item.medicalAttention ? 'capitalize' : 'none'}">
                {{item.medicalAttention ? item.medicalAttention : 'n/a'}}
            </dd>
        </div>
        <div class="c-details-table__item">
            <dt class="c-details-table__title">Medication taken</dt>
            <dd class="c-details-table__details injury-details-medication data-hj-suppress" [id]="'injury-details-medication-' + i">{{item.medicationTaken ? item.medicationTaken : 'n/a'}}</dd>
        </div>
    </ng-container>
</dl>
