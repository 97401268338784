<h3 class="c-heading c-heading-purple">Incident details</h3>
<dl class="c-details-table">
    <div class="c-details-table__item">
        <dt class="c-details-table__title">
            Estimated time of accident
        </dt>
        <dd class="c-details-table__details" id="accident-time">{{case.accident.date && case.accident.date.toString() !== '0001-01-01T00:00:00Z' ? (case.accident.date | date:'h:mma':'-000') : 'n/a'}}</dd>
    </div>

    <div class="c-details-table__item">
        <dt class="c-details-table__title">
            Accident date
        </dt>
        <dd class="c-details-table__details" id="accident-date">{{case.accident.date && case.accident.date.toString() !== '0001-01-01T00:00:00Z' ? (case.accident.date | date:'d MMMM yyyy') : 'n/a'}}</dd>
    </div>

    <div class="c-details-table__item">
        <dt class="c-details-table__title">
            Is CCTV available?
        </dt>
        <dd class="c-details-table__details" id="accident-cctv">{{case.accident.cctvAvailable}}</dd>
    </div>

    <div class="c-details-table__item">

        <dt class="c-details-table__title">
            Is CCTV obtained?
        </dt>
        <dd class="c-details-table__details" id="accident-cctv-available">{{case.accident.cctvObtained}}</dd>
    </div>

    <div class="c-details-table__item">

        <dt class="c-details-table__title">
            Description of accident
        </dt>
        <dd class="c-details-table__details" id="accident-description">{{case.accident.description ? case.accident.description : 'n/a'}}</dd>

    </div>
</dl>
<h3 class="c-heading c-heading-purple">Police details</h3>
<dl class="c-details-table">
    <div class="c-details-table__item">

        <dt class="c-details-table__title">
            Was the incident reported to the police?
        </dt>
        <dd class="c-details-table__details" id="accident-reported-police">{{case.accident.reportedToPolice ? 'Yes' : 'No'}}</dd>
    </div>

    <div class="c-details-table__item">
        <dt class="c-details-table__title">
            Police reference number
        </dt>
        <dd class="c-details-table__details" id="accident-police-reference">{{case.accident.policeReferenceNumber ? case.accident.policeReferenceNumber : 'n/a'}}</dd>

    </div>

    <div class="c-details-table__item">

        <dt class="c-details-table__title">
            Police officer name
        </dt>
        <dd class="c-details-table__details" id="accident-police-officer-name">{{case.accident.policeOfficerName ? case.accident.policeOfficerName : 'n/a'}}</dd>

    </div>

    <div class="c-details-table__item">
        <dt class="c-details-table__title">
            Police station name and address
        </dt>
        <dd class="c-details-table__details" id="accident-police-address">
            {{policeStationNameAddress ? policeStationNameAddress : 'n/a'}}
        </dd>

    </div>
</dl>
<ng-container *ngIf="case.witnesses.length > 0">
    <h3 class="c-heading c-heading-purple">Witness details</h3>
    <dl class="c-details-table">
        <div *ngFor="let item of case.witnesses; index as i" [attr.id]="'witness-' + i">
            <div class="c-details-table__item">
                <dt class="c-details-table__title">
                    Name
                </dt>
                <dd class="c-details-table__details accident-witness-name" [id]="'accident-witness-name-' + i">{{item.forename || item.surname ? item.forename + ' ' + item.surname : 'n/a'}}</dd>
            </div>

            <div class="c-details-table__item">
                <dt class="c-details-table__title">
                    Telephone
                </dt>
                <dd class="c-details-table__details accident-witness-telephone" [id]="'accident-witness-telephone-' + i">{{item.contactNumber ? item.contactNumber : 'n/a'}}</dd>
            </div>

            <div class="c-details-table__item">
                <dt class="c-details-table__title">
                    Independent?
                </dt>
                <dd class="c-details-table__details accident-witness-independent" [id]="'accident-witness-independent-' + i">{{item.isIndependent ? 'Yes' : item.isIndependent == null ? 'Not Known': 'No'}}</dd>
            </div>
        </div>
    </dl>
</ng-container>
