import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MinsterModalService {

    private subject = new Subject<any>();

    modals = [];

    open(modal) {
        this.modals.push(modal);
        this.subject.next(modal);
    }


}
