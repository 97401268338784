import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { fromEvent, Observable, Subscriber } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { FaqResponseModel } from '../../models/faq.model';
import { AppInsightsService } from '../../services/appinsights.service';
import { FaqService } from '../../services/faq.service';
import { MinsterExpandablePanelComponent } from '../minster-expandable-panel/minster-expandable-panel.component';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, AfterViewInit, OnDestroy {

    form: UntypedFormGroup;
    searching: boolean;
    currentQuestionSubscription: Subscriber<Observable<FaqResponseModel>>;
    results: FaqResponseModel;
    submitted: boolean;
    @ViewChild('questioninput') questionInput: ElementRef;
    @ViewChildren('answers') answerPanels: QueryList<MinsterExpandablePanelComponent>;
    typing = false;
    subscriptions = [];
    isQuestionAnswered?: boolean;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private faqService: FaqService,
        private fb: UntypedFormBuilder,
        private appInsights: AppInsightsService) { }

    ngAfterViewInit(): void {

        this.subscriptions.push(fromEvent(this.questionInput.nativeElement, 'keyup')
            .pipe(
                filter(Boolean),
                distinctUntilChanged(),
                tap((text) => {
                    this.typing = true;
                })
            )
            .subscribe());

        this.subscriptions.push(fromEvent(this.questionInput.nativeElement, 'keyup')
            .pipe(
                filter(Boolean),
                debounceTime(300),
                distinctUntilChanged(),
                tap((text) => {
                    this.search();
                    this.typing = false;
                })
            )
            .subscribe());

        this.subscriptions.push(this.answerPanels.changes.pipe(
            filter(Boolean),
            debounceTime(50),
            tap(() => {
                if (this.answerPanels && this.answerPanels.length === 1) {
                    this.answerPanels.first.openClose();
                }
            }))
            .subscribe());
    }

    promptSelected(question: string) {
        this.form.setValue({
            question: question
        });
        this.search();
    }

    ngOnInit() {

        let question: string = null;

        if (this.route.snapshot.queryParams['q']) {
            question = this.route.snapshot.queryParams['q'];
        }

        this.form = this.fb.group({
            'question': [question, [Validators.required]]
        });

        if (question) {
            this.search();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    search() {
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }

        if (this.searching) {
            this.currentQuestionSubscription.unsubscribe();
        }

        this.searching = true;

        const queryParams: Params = { q: this.form.value.question };

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: queryParams,
                queryParamsHandling: 'merge',
            });

        this.appInsights.logComplexEvent('Faq searched', { question: this.form.value.question });

        this.currentQuestionSubscription = this.faqService.ask(this.form.value.question).subscribe(r => {
            this.searching = false;
            this.results = r;

            this.appInsights.logComplexEvent('Faqs returned', { question: this.form.value.question, answerFound: this.results.found ? 'TRUE' : 'FALSE' }, { answers: this.results.answers.length });

        }) as Subscriber<Observable<FaqResponseModel>>;
    }

    onQuestionAnswer(isQuestionAnswered: boolean) {
        this.isQuestionAnswered = isQuestionAnswered;
    }
}
