import { Component, Input } from '@angular/core';

@Component({
    selector: 'minster-validation-message',
    templateUrl: './minster-validation-message.component.html',
    styleUrls: ['./minster-validation-message.component.scss']
})
export class MinsterValidationMessageComponent {

    @Input() message: string;
    @Input() id: string;

    constructor() { }

}
