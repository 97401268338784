<ng-container *ngFor="let item of thirdParties; let i = index">
    <h3 class="c-heading c-heading-purple"> {{(i == 0) ? 'Primary Third Party': 'Third Party ' + (i + 1)}} </h3>
    <dl class="c-details-table">
        <div class="c-details-table__item">
            <dt class="c-details-table__title">Vehicle registration</dt>
            <dd class="c-details-table__details" id="third-party-registration">{{item.registrationNumber ? item.registrationNumber : 'n/a'}}</dd>
        </div>
        <div class="c-details-table__item">
            <dt class="c-details-table__title">Make</dt>
            <dd class="c-details-table__details" [ngStyle]="{'text-transform': item.make ? 'uppercase' : 'none'}" id="third-party-make">{{item.make ? item.make : 'n/a'}}</dd>
        </div>
        <div class="c-details-table__item">
            <dt class="c-details-table__title">Model</dt>
            <dd class="c-details-table__details" [ngStyle]="{'text-transform': item.model ? 'uppercase' : 'none'}" id="third-party-model">{{item.model ? item.model : 'n/a'}}</dd>
        </div>
        <div class="c-details-table__item">
            <dt class="c-details-table__title">Colour</dt>
            <dd class="c-details-table__details" id="third-party-colour">{{item.colour ? item.colour : 'n/a'}}</dd>
        </div>
        <div class="c-details-table__item">
            <dt class="c-details-table__title">Insurer name</dt>
            <dd class="c-details-table__details" id="third-party-insurer">{{item.insurerName ? item.insurerName : 'n/a'}}</dd>
        </div>
    </dl>
</ng-container>
