import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'minster-link',
    templateUrl: './minster-link.component.html',
    styleUrls: ['./minster-link.component.scss']
})
export class MinsterLinkComponent {

    @Input() text: string;
    @Input() href: string;
    @Input() mlRouterLink: string[] = [];
    @Input() disabled: boolean;
    @Input() svgId: string;
    @Input() id: string;
    @Input() underline: boolean;
    @Input() textLinkDark: boolean;
    @Output() clicked = new EventEmitter();

    constructor() { }

    wasClicked() {
        if (this.disabled) { return; }
        this.clicked.emit();
    }
}
