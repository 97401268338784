import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-none',
  templateUrl: './none.component.html',
  styleUrls: ['./none.component.scss']
})
export class NoneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
