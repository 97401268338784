import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CaseModel } from 'src/app/models/case.model';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import { MilestoneModel } from '../../models/milestone.model';
import { MilestoneService } from '../../services/milestone.service';
import { AppInsightsService } from '../../services/appinsights.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    case: CaseModel;
    isSettled: boolean;
    isAtNegotiationAndOic: boolean;
    milestones: MilestoneModel[] = [];
    previousStep: MilestoneModel = new MilestoneModel();
    currentStep: MilestoneModel = new MilestoneModel();
    nextStep: MilestoneModel = new MilestoneModel();
    isCompleted: boolean;
    isAheadEmpty: boolean;
    pushNotificationPermissionShown: boolean;

    constructor(
        private route: ActivatedRoute,
        private milestoneService: MilestoneService,
        private pushNotificationService: PushNotificationService,
        private appInsightsService: AppInsightsService
    ) { }

    ngOnInit(): void {
        this.case = this.route.snapshot.parent.data.case;
        this.milestones = this.route.snapshot.parent.data.milestones;
        this.isSettled = this.case.endOfClaim;
        this.isAtNegotiationAndOic = this.getIsAtNegotiationAndOic()

        // Milestones
        let index: number = 0;

        if (this.milestones.length > 0) {
            index = this.milestones.findIndex(x => {
                return x.completed == false;
            })

            this.isAheadEmpty = index == this.milestones.length - 1 ? true : false;
            this.isCompleted = index == -1 ? true : false;

            if (index > 0) {
                this.previousStep = this.milestones[index - 1];
            }
            if (index >= 0) this.currentStep = this.milestones[index];
            if (index >= 0 && index < this.milestones.length - 1) this.nextStep = this.milestones[index + 1];
            if (index == -1 || index == this.milestones.length - 1) {
                this.previousStep = this.milestones[this.milestones.length - 3];
                this.currentStep = this.milestones[this.milestones.length - 2];
                this.nextStep = this.milestones[this.milestones.length - 1];
            }

            this.registerForPushNotifications();
        }

        // TODO: Handle end of case.
        // TODO: Handle onboarding.
    }

    getIsAtNegotiationAndOic(): boolean {
        var negotiations = this.milestones.find(x => x.type == 6);

        //console.error(negotiations);

        if(!negotiations){
            return false;
        }

        return this.case.track == 1 && negotiations.completed;
    }

    registerForPushNotifications() {
        //Some browsers like Firefox or Safari force to subscribe to push notifications inside an user generated event like a click.
        //On page load the pop up to allow push notifications is not displayed for those browsers.
        //As the portal is displaying some other modals is used this code instead of a pop up with a button.
        window.onclick = (event) => {
            this.pushNotificationPermissionShown = JSON.parse(sessionStorage.getItem('pushNotificationPermissionShown'));

            // determine device
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !isMobile;
            const isSafariDesktop = isSafari && !isMobile;

            // web push
            if (this.pushNotificationPermissionShown) {
                window.onclick = undefined;
                return;
            }

            if (isSafari && isMobile) {
                console.log('web push not supported on ios');
                window.onclick = undefined;
                return;
            }

            if (isSafariDesktop) {
                // apple desktop notifications
                this.pushNotificationService.handleSafariDesktopNotification();
            } else {
                // web push notifications.
                this.pushNotificationService.handleWebPushNotification();
            }

            this.pushNotificationPermissionShown = true;
        }
    }

    get salutation(): string {
        const now = new Date().getHours();

        if (now < 12) {
            return 'Good morning';
        }

        if (now < 18) {
            return 'Good afternoon';
        }

        return 'Good evening';
    }

    onViewTimeline() {
        this.appInsightsService.logCustomEvent('Timeline opened', 'Succeeded', null)
        this.milestoneService.startViewTimeline(this.currentStep);
    }
}
