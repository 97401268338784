import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CaseModel, FileHandler } from 'src/app/models/case.model';
import { CaseService } from 'src/app/services/case.service';
import { MenuItemType, MenuService } from 'src/app/services/menu.service';
import { ContactService, ContactModel } from 'src/app/services/contact.service';
import { AppInsightsService } from 'src/app/services/appinsights.service';
import { NotificationService } from '../../services/notification.service';
import { NotificationModel } from '../../models/notification.model';
import { AppUtilities } from '../../app.utility';

@Component({
    selector: 'menu-panel',
    templateUrl: './menu-panel.component.html',
    styleUrls: ['./menu-panel.component.scss']
})
export class MenuPanelComponent implements OnDestroy {

    MenuItemType = MenuItemType;
    subscriptions: Subscription[] = [];
    cases: CaseModel[] = [];
    activeCaseRef: string;
    isOpen: boolean = false;
    previousMenuItem: MenuItemType | undefined;
    activeMenuItem: MenuItemType | undefined;
    fileHandler: FileHandler;
    contact: ContactModel;

    notifications: NotificationModel[] = [];

    constructor(
        private menuService: MenuService,
        private contactService: ContactService,
        private route: ActivatedRoute,
        private router: Router,
        private appInsightsService: AppInsightsService,
        private notificationService: NotificationService
    ) {
        // let notificationSubscription = this.notificationService.notificationSubject.subscribe(result => {
        //    this.notifications = NotificationService.notificationList;
        // });
        //
        // this.subscriptions.push(notificationSubscription);

        this.cases = [this.route.snapshot.data.case];

        this.contact = contactService.get();

        const setFileHandler = () => {
            const activeCase = this.cases.find(x => x.reference === this.activeCaseRef);
            if (activeCase) {
                this.fileHandler = activeCase.fileHandler;
            }
        };

        setFileHandler();

        this.subscriptions.push(
            this.route.params.subscribe(result => {
                this.activeCaseRef = result.reference;
                setFileHandler();
            })
        );

        this.subscriptions.push(
            this.menuService.toggleMenuItemSubject.subscribe(
                item => {
                    if (this.activeMenuItem !== MenuItemType.Claim &&
                        this.activeMenuItem !== MenuItemType.Contact &&
                        this.activeMenuItem !== MenuItemType.General &&
                        this.activeMenuItem !== MenuItemType.Notification) {
                        this.previousMenuItem = this.activeMenuItem;
                    }

                    this.isOpen = item === MenuItemType.Claim ||
                        item === MenuItemType.Contact ||
                        item === MenuItemType.General ||
                        item === MenuItemType.Notification;

                    this.activeMenuItem = item;
                }
            )
        );

        this.subscriptions.push(
            this.menuService.toggleSlidingPanelSubject.subscribe(
                result => {
                    if (!result) { this.isOpen = false; }
                }
            )
        );
    }

    addPageView(name: string) {
        this.appInsightsService.logPageView(name, this.route.snapshot['_routerState'].url);
    }

    // notification being clicked results in it being marked as read.
    // onNotificationClick(notification: NotificationModel) {
    //    this.notificationService.markAsRead(notification);
    //
    //    if (notification.notificationObject && notification.notificationObject.toUpperCase() === 'DOCUMENT') {
    //        let reference = AppUtilities.getRouteParam(this.route, 'reference');
    //        this.router.navigate(['/portal/' + reference + '/documents']);
    //    }
    //    this.menuService.toggleMenuSubject.next(false);
    //    this.isOpen = false;
    // }
    //
    // // dismiss results in the notification being deleted.
    // onDismissNotification(notification: NotificationModel) {
    //    this.notificationService.deleteNotification(notification);
    // }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    dismiss(type?: MenuItemType) {
        this.menuService.toggleMenuItemSubject.next(type ? type : this.previousMenuItem);
    }
}
