import {Inject, Injectable} from '@angular/core';
import {EnquiryModel} from '../models/enquiry.model';
import {HttpClient} from '@angular/common/http';
import {from, Subject} from 'rxjs';



@Injectable({
    providedIn: 'root'
})
export class EnquiryService {
    error = new Subject<string>();

    constructor(@Inject('API_BASE_URL') private baseUrl: string,
                private http: HttpClient) {}

    createEnquiry(enquiryModel: EnquiryModel) {

        const promise = new Promise<boolean>((resolve, reject) => {
            const url = this.baseUrl + 'enquiry';

            this.http.post(url, enquiryModel
            ).subscribe(() => {
                resolve(true);
            }, error => {
                reject(error);
            });
        });

        return from(promise);
    }
}
