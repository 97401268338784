<div class="u-mtop-xs">
    <h1 class="c-heading c-heading-uppercase">Notifications</h1>
    <p class="c-text-width">
        Notifications are here to help you keep track of important updates on your claim or if we need information from you.
    </p>
    <p class="c-text-width" id="no-notifications" *ngIf="!notifications || notifications.length === 0">You don't have any notifications right now.</p>
</div>

<div class="c-tab-buttons-wrapper u-mtop-sm" *ngIf="notifications.length > 0">
    <button role="tab" disableTabOnModal aria-label="Show all notifications" [attr.aria-selected]="showAll === true" class="c-tab-buttons c-tab-buttons--left" [ngClass]="{'c-tab-buttons--active' : showAll === true}" (click)="showAll = true">All</button>
    <button role="tab" disableTabOnModal aria-label="Show only unread notifications" [attr.aria-selected]="showAll === false" class="c-tab-buttons c-tab-buttons--right" [ngClass]="{'c-tab-buttons--active' : showAll === false}" (click)="showAll = false">Unread</button>
</div>

<div class="c-table-list c-text-white u-mtop-sm">
    <ul class="c-table-list__content" aria-label="Notifications" id="notifications-panel">
        <li class="c-table-list__item" notificationRow (open)="markAsRead($event)"
                  (openDocument)="openDocument($event)"
                  (delete)="deleteNotification($event)"
                  (approveDocument)="approveDocument($event)"
                  (rejectDocument)="rejectDocument($event)"
                  *ngFor="let notification of notificationsToShow"
                  [notification]="notification"
                  [isDownloading]="isDownloadingDocument(notification)"
                  [document]="documentForNotification(notification)"
                  [case]="case"
                  (openTrustPilot)="openTrustPilot($event)">

        </li>
    </ul>
</div>

<p class="c-text-width" id="no-unread-notifications" *ngIf="!showAll && notificationsToShow.length === 0">You don't have any unread notifications. <a disableTabOnModal (click)="showAll = true" class="c-text-link c-text-link--white">Show all notifications</a></p>

<minster-alert #confirmDeleteWindow
               id="delete-notification-confirmation"
               okButtonTitle="Yes - delete it"
               cancelButtonTitle="No - keep it"
               title="Delete this notification?"
               body="Are you sure you want to delete this notification? This can't be undone."
               (close)="confirmDeleteClosed($event)">
</minster-alert>
