import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppInsights} from 'applicationinsights-js';
import {Observable} from 'rxjs';
import {StorageService} from './storage.service';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AppInsightsService  {
    sessionKey: string;
    email: string;

    generateSessionKey() {
        this.sessionKey = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    setLoginEmail(email: string) {
        this.email = email;
        appInsights.setAuthenticatedUserContext(email);
    }

    constructor(
        private http: HttpClient,
        private storage: StorageService,
        private router: Router,
        @Inject('API_BASE_URL') private baseUrl: string
    ) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.startNavigationEvent(event.url);
            } else if (event instanceof NavigationEnd) {
                this.endNavigationEvent(event.url);
                AppInsights.trackPageView();
            }
        });

        var cookiesAreEnabled = this.storage.getItem('cookieConsent') == 'true';

        if (!AppInsights.config && cookiesAreEnabled) {
            this.get().subscribe(
                result => {
                    const config: Microsoft.ApplicationInsights.IConfig = {
                        instrumentationKey: result.instrumentationKey,
                        isCookieUseDisabled: true
                    };

                    AppInsights.downloadAndSetup(config);
                }
            );
        }
    }

    get(): Observable<any> {
        const url = this.baseUrl + 'configuration';
        return this.http.get(url) as Observable<any>;
    }

    logPageView(name: string, url?: string, properties?: any, measurements?: any, duration?: number) {
        AppInsights.trackPageView(name, url, properties, measurements, duration);
    }

    logEvent(name: string, properties?: any, measurements?: any) {
        AppInsights.trackEvent(name, properties, measurements);
    }

    logCustomEvent(messageName: string, result: string, email: string) {
        const theEmail = email || this.email || this.storage.getItem('username');
        AppInsights.trackEvent(messageName, { 'session': this.sessionKey, 'email': theEmail, result: result });
    }

    logComplexEvent(eventName: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
        properties['email'] = this.email || this.storage.getItem('username');
        properties['session'] = this.sessionKey;

        AppInsights.trackEvent(eventName, properties, measurements);
    }

    startNavigationEvent(url: string) {
        if (AppInsights.context) {
            AppInsights.context.operation.id =  (<any>Microsoft.ApplicationInsights).Util.newId();
            AppInsights.context.operation.name = url;
        }

        AppInsights.startTrackEvent(url);
    }
    endNavigationEvent(url: string) {
        AppInsights.stopTrackEvent(url, { type: 'PAGE LOAD TIME' });
    }
}
