import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { FaqComponent } from './components/faq/faq.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NoneComponent } from './components/none/none.component';
import { PortalComponent } from './components/portal/portal.component';
import { RegisterComponent } from './components/register/register.component';
import { AuthGuard } from './guards/auth.guard';
import { ForgottenPasswordComponent } from './components/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { BadLinkComponent } from './components/bad-link/bad-link.component';
import { UserGuard } from './guards/user.guard';
import { NoCasesComponent } from './components/no-cases/no-cases.component';
import { LoginFailComponent } from './components/login-fail/login-fail.component';
import { ForbidRegisterPageComponent } from './components/forbid-register-page/forbid-register-page.component';
import { CaseService } from './services/case.service';
import { GuidExpiredComponent } from './components/guid-expired/guid-expired.component';
import { RegisterExpiryPageComponent } from './components/register-expiry-page/register-expiry-page.component';
import { LossesComponent } from './components/losses/losses.component';
import {LossService} from './services/loss.service';
import { LossesLegacyComponent } from './components/losses-legacy/losses-legacy.component';
import { MedicalAppointmentService } from './services/medical-appointment.service';
import { ClaimSettledComponent } from './components/claim-settled/claim-settled.component';
import { CaseGuard } from './guards/case.guard';
import { ChangeEmailSendComponent } from './components/change-email-send/change-email-send.component';
import { MedicalComponent } from './components/medical/medical.component';
import { ClaimComponent } from './components/claim/claim.component';
import { DocumentService } from './services/document.service';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationService } from './services/notification.service';
import { SettlementComponent } from './components/settlement/settlement.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { ContactComponent } from './components/contact/contact.component';
import { LoginDisabledComponent } from './components/login-disabled/login-disabled.component';

import {NgxHotjarModule, NgxHotjarRouterModule} from 'ngx-hotjar';
import { MilestoneService } from './services/milestone.service';
import { NegotiationsComponent } from './components/negotiations/negotiations.component';



const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'portal'
    },
    {
        path: 'bad-link',
        component: BadLinkComponent,
        data: {
            title: 'Bad Link',
            bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
        }
    },
    {
        path: 'no-cases',
        component: NoCasesComponent,
        data: {
            title: 'No Cases',
            bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
        }
    },
    {
        path: 'welcome/:guid',
        component: RegisterComponent,
        data: {
            title: 'Welcome',
            bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
        }
    },
    {
        path: 'forbid_registration',
        component: ForbidRegisterPageComponent,
        data: {
            title: 'Registration Forbidden',
            bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
        }
    },
    {
        path: 'guid-expired',
        component: GuidExpiredComponent,
        data: {
            title: 'Email Link Expired',
            bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
        }
    },
    {
        path: 'registration-expired/:guid',
        component: RegisterExpiryPageComponent,
        data: {
            title: 'Registration Expired',
            bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
        }
    },
    {
        path: 'portal',
        pathMatch: 'full',
        component: NoneComponent,
        canActivate: [AuthGuard, UserGuard],
        data: { title: 'Home' }
    },
    {
        path: 'portal/:reference',
        component: PortalComponent,
        canActivate: [AuthGuard],
        resolve: {
            case: CaseService,
            medicalAppointment: MedicalAppointmentService,
            documents: DocumentService,
            milestones: MilestoneService/*,
            losses: LossService*/
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'case'
            },
            {
                path: 'case',
                component: HomeComponent,
                data: { title: 'Home' },
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'details'
                    },
                    {
                        path: 'details',
                        component: ClaimComponent,
                        data: { title: 'Claim' }
                    },
                    {
                        path: 'losses',
                        component: LossesLegacyComponent,
                        data: { title: 'Losses' }
                    },
                    {
                        path: 'medical_oic',
                        component: MedicalComponent,
                        data: {
                            title: 'Medical'
                        }
                    },
                    {
                        path: 'negotiations',
                        component: NegotiationsComponent,
                        data: {
                            title: 'Negotiations'
                        }
                    },
                    {
                        path: 'settlement',
                        component: SettlementComponent,
                        data: {
                            title: 'Settlement'
                        }
                    }
                ]
            },
            {
                path: 'faqs',
                component: FaqComponent,
                data: { title: 'FAQs' }
            },
            {
                path: 'notifications',
                component: NotificationsComponent,
                resolve: {
                    notifications: NotificationService
                },
                data: {
                    title: 'Notifications'
                }
            },
            {
                path: 'settled',
                component: ClaimSettledComponent,
                canActivate: [CaseGuard],
                data: { title: 'Claim settled' }
            },
            {
                path: 'documents',
                component: DocumentsComponent,
                data: { title: 'Case documents' }
            },
            {
                path: 'contact',
                component: ContactComponent,
                data: {
                    title: 'Contact'
                }
            },
            {
                path: 'change-email-send',
                component: ChangeEmailSendComponent
            },
        ]
    },
    {
        path: 'auth',
        component: AuthComponent,
        data: {
            title: 'Authentication',
            bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'login',
                data: {
                    title: 'Login',
                    bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
                }
            },
            {
                path: 'login',
                component: LoginComponent,
                data: {
                    title: 'Sign in to your Minster Portal',
                    bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
                }
            },
            {
                path: 'logout',
                component: LogoutComponent,
                data: {
                    title: 'Logging you out',
                    bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
                }
            },
            {
                path: 'forgotten-password',
                component: ForgottenPasswordComponent,
                data: {
                    title: 'Forgotten Password',
                    bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
                }
            },
            {
                path: 'reset-password/:guid',
                component: ResetPasswordComponent,
                data: {
                    title: 'Reset Password',
                    bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
                }
            },
            {
                path: 'login-fail',
                component: LoginFailComponent,
                data: {
                    title: 'Login Failed',
                    bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
                }
            },
            {
                path: 'login-disabled',
                component: LoginDisabledComponent,
                data: {
                    title: 'Login Failed',
                    bodyClass: 'c-login-page e-page-body-margin-none u-flex-col'
                }
            }
        ]
    },
    {
        path: '**', redirectTo: 'portal'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: false }),
        /*NgxHotjarModule.forRoot('1911560'),
        NgxHotjarRouterModule*/
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
