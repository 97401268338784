import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NotificationModel } from '../../models/notification.model';
import { DocumentModel } from '../../models/document.model';
import * as moment from 'moment';
import { CaseModel } from '../../models/case.model';
import { MedicalReportService } from '../../services/medical-report.service';
import { CalendarFormat } from '../../data/calendar-format';

export class NotificationDocumentEvent {
    notification: NotificationModel;
    document: DocumentModel;
}

@Component({
    selector: 'notification-row, [notificationRow]',
    templateUrl: './notification-row.component.html',
    styleUrls: ['./notification-row.component.scss']
})
export class NotificationRowComponent implements OnInit {

    @Input() notification: NotificationModel;
    @Input() document: DocumentModel;
    @Input() isDownloading: boolean;
    @Output() open = new EventEmitter<NotificationModel>();
    @Output() delete = new EventEmitter<NotificationModel>();
    @Output() approveDocument = new EventEmitter<NotificationDocumentEvent>();
    @Output() rejectDocument = new EventEmitter<NotificationDocumentEvent>();
    @Output() openDocument = new EventEmitter<NotificationDocumentEvent>();
    @Output() openTrustPilot = new EventEmitter<NotificationModel>();

    isHovered = false;
    isSelected = false;
    isReadInThisSession = false;

    constructor() { }

    select() {
        if (this.isSelected) {
            this.isSelected = false;
            return;
        }

        this.isSelected = true;
        this.isReadInThisSession = true;
        this.open.emit(this.notification);
    }

    get friendlyNotificationDate(): string {
        return moment(this.notification.lastUpdatedDate).calendar(null, CalendarFormat);
    }

    get isNonMedicalDocument(): boolean {
        return this.document && (this.document.group.toLowerCase() !== 'medical');
    }

    get isMedical(): boolean {
        return this.document && (this.document.group.toLowerCase() === 'medical');
    }

    get IsMedicalApprovedOrRejeceted() :Boolean{
        return this.document.status.toLowerCase() !== 'new'
    }

    get isMedicalRequiringApproval(): boolean {
        return this.document && (this.document.group.toLowerCase() === 'medical'
        && this.document.status.toLowerCase() === 'new'
        && this.document.requiresApproval);
    }

    get friendlyDocumentDate(): string {
        return moment(this.document.statusChangedDate).calendar(null, CalendarFormat);
    }

    openDocumentClicked(event: Event) {
        this.select();
        event.stopPropagation(); // Stop bubbling up.
        this.openDocument.emit({ notification: this.notification, document: this.document });
    }

    approveClicked(event: Event) {
        this.select();
        event.stopPropagation(); // Stop bubbling up.
        this.approveDocument.emit({ notification: this.notification, document: this.document });
    }

    rejectClicked(event: Event) {
        this.select();
        event.stopPropagation(); // Stop bubbling up.
        this.rejectDocument.emit({ notification: this.notification, document: this.document });
    }

    deleteClicked(event: Event) {
        this.select();
        event.stopPropagation(); // Stop bubbling up.
        this.delete.emit(this.notification);
    }

    openTrustPilotClicked(event: Event) {
        this.select();
        event.stopPropagation();
        this.openTrustPilot.emit(this.notification);
    }

    ngOnInit(): void {

    }

}
