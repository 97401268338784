<div class="agency-logo">
    <img class="c-agency-nav__logo" alt="{{agency.name}} logo" [src]="agency.imagePath">
</div>
<div class="c-details-table__item">
    <dt class="c-details-table__title">Name</dt>
    <dd class="c-details-table__details data-hj-suppress" id="medical-agency-name">{{agency.name}}</dd>
</div>
<div class="c-details-table__item">
    <dt class="c-details-table__title">Phone</dt>
    <dd class="c-details-table__details data-hj-suppress" id="agency-phone"><a href="tel:{{agency.phone}}">{{agency.phone}}</a></dd>
</div>
<div class="c-details-table__item">
    <dt class="c-details-table__title">Email</dt>
    <dd class="c-details-table__details data-hj-suppress" id="agency-email"><a href="mailto:{{agency.email}}">{{agency.email}}</a></dd>
</div>
<div class="c-details-table__item">
    <dt class="c-details-table__title">Website</dt>
    <dd class="c-details-table__details data-hj-suppress" id="agency-webite"><a target="_blank" [href]="agency.website">{{agency.website}}</a></dd>
</div>
