export class LossUploadModel {
    caseReference: string;
    lossIncurredDate?: Date;
    type: string;
    subType: string;
    amount: number;
    notes: string;
    evidence: EvidenceUploadModel[];
    createdDate: string;
    status: string;
    id: string;
}

export class EvidenceUploadModel {
    fileUrl: string;
    fileName: string;
    fileContents: string;
    mimeType: string;
    procliamId: string;
    state: string;
}
