import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { AppInsightsService } from '../../services/appinsights.service';

@Component({
    selector: 'minster-expandable-panel, [minster-expandable-panel]',
    templateUrl: './minster-expandable-panel.component.html',
    styleUrls: ['./minster-expandable-panel.component.scss']
})
export class MinsterExpandablePanelComponent implements OnInit {

    @HostBinding('class.c-panels__item') isPanel: boolean = true;
    @HostBinding('class.c-panels__style--simple') isSimplePanel: boolean;
    @Input('isSimple') isSimple: boolean;
    @Input('heading') heading: string;
    @Input('expanded') initiallyExpanded = false;
    @Input('icon') icon: string;
    @Input('id') buttonId: string;
    @Output('didExpand') didExpand = new EventEmitter<boolean>();

    expanded = false;

    constructor(
        private appInsights: AppInsightsService
    ) { }

    ngOnInit(): void {
        this.expanded = this.initiallyExpanded;
        this.isSimplePanel = this.expanded && this.isSimple;
    }

    openClose(): void {
        this.expanded = !this.expanded;
        this.isSimplePanel = this.expanded && this.isSimple;

        const eventName = this.expanded ? 'expandExpandablePanel' : 'collapseExpandablePanel';

        this.didExpand.emit(this.expanded);

        this.appInsights.logCustomEvent(eventName, this.heading, null);
    }

}
