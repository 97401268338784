export class EnquiryModel {
    caseReference: string;
    type: number;
    notes: string;
    email: string;
    phone: string;
    sms: string;
    contactDate: Date;
}

export class EnquiryTypeModel {
    key: number;
    value: string;
}
